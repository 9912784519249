import React, { Component } from "react";
import { connect } from "react-redux";
import history from "../history";

export class ErrorsContainer extends Component {

    showError() {
        if (this.props.errors[460] !== undefined) {
            const location = this.props.errors[460].location || this.props.errors[460].create_task;
            return this.props.user.locationRoles[location.location.id].slug !== 'su'
                && this.props.user.locationRoles[location.location.id].slug !== 'raks';
        }

        return true;
    }

    render() {
        if (this.props.errors[460] !== undefined && this.showError()) {
            const error = this.props.errors[460].location || this.props.errors[460].create_task;
            return <div style={{ position: 'fixed', zIndex: 10000, marginTop: '8rem', backgroundColor: 'rgba(0, 0, 0, 0.3)' }} className="w-100 h-100 d-flex align-items-center justify-content-center">
                <div className="Locations mt-3 col-12 col-md-4 mx-auto text-left py-5 bg-primary mb-auto">
                    <h2 className="text-white text-center" style={{ weight: 'bold', fontSize: '36px' }}>Tarvitsemme vielä muutamia lisätietoja</h2>
                    {error.invitation !== undefined ? <div className="d-flex mb-5">
                        <button className="btn btn-success mx-auto" onClick={() => history.push({ pathname: '/start/rs15/1/' + (error.invitation.key || '') })}>Täydennä tiedot tästä</button>
                    </div> : ''}
                </div>
            </div>;
        }
        return '';
    }
}

const mapStateToProps = ({ user, errors }) => ({ user, errors });

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(ErrorsContainer);
