import Comments from "../components/Comments";
import { getComments, createComment } from "../actions/comments";
import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

export class CommentsContainer extends Component {
    constructor(props) {
        super(props);

        this.props.getComments(this.props.router.location.pathname.match(/\/tasks\/(\d{1,})\//)[1]);
    }

    render() {
        return <Comments comments={this.props.comments[this.props.currentTask.id] || []} createComment={this.props.createComment} task_id={this.props.currentTask.id} isLoading={this.props.loading}/>;
    }
}

CommentsContainer.propTypes = {
    currentTask: PropTypes.object,
};

const mapStateToProps = ({ comments, currentTask, router, loading }) => ({ comments, currentTask, router, loading: loading.comments });

const mapDispatchToProps = (dispatch) => ({
    getComments: (id) => dispatch(getComments(id)),
    createComment: (id, data) => dispatch(createComment(id, data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CommentsContainer);
