import { fetchUserIfNeeded } from "../actions/user";
import HeaderContainer from "./HeaderContainer";
import Loading from "../components/Loading";
import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment";
import axios from "axios";

const url = process.env.REACT_APP_API_URL;

export class Terms extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            terms: {},
        };
    }

    componentDidMount() {
        this.props.fetchUserIfNeeded();
        axios.get(url + '/api/terms')
            .then(response => this.setState({ loading: false, terms: response.data.terms }));
    }

    render() {
        if (this.state.loading) {
            return <Loading isLoading={true}/>;
        }
        return <div>
            <HeaderContainer/>
            <div style={{ marginTop: '5rem' }} className="col-md-6 mx-auto">
                <h1 className="mb-3">Kiinteistökartturin Käyttöehdot</h1>
                <p>Voimassa {moment(this.state.terms.date).format('DD.MM.YYYY')} lähtien</p>
                {Object.entries(this.state.terms.content).map(entry => {
                    return <div key={entry[0]}>
                        <h2>{entry[0]}</h2>
                        {entry[1].map((row, i) => <p key={'p-' + i}>{row}</p>)}
                    </div>;
                })}
            </div>
        </div>;
    }
}

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => ({
    fetchUserIfNeeded: () => dispatch(fetchUserIfNeeded()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Terms);
