import { ChevronLeft, ChevronRight } from "../svg/icons";
import { DragDropContext } from "react-beautiful-dnd";
import { fetchYear } from "../actions/locations";
import { moveAndSave } from "../actions/sorting";
import { openTask } from "../actions/tasks";
import React, { Component } from "react";
import { flattenDeep } from "../utils";
import Year from "../components/Year";
import { connect } from "react-redux";
import history from "../history";
import _ from "lodash";

export class YearsContainer extends Component {

    constructor(props) {
        super(props);

        const date = new Date();

        this.state = {
            year: date.getFullYear(),
            showImages: false
        };

        this.onDragEnd = this.onDragEnd.bind(this);
    }

    getFilters(filters) {
        if (Array.isArray(filters)) {
            return filters;
        }
        return Object.values(filters).map(t => {
            return this.getFilters(t);
        });
    }

    mapTasksToYear(tasks) {
        const filters = flattenDeep(this.getFilters(this.props.filter.tags)).map(t => t.id);
        if (filters.length > 0) {
            if (this.props.filter.searchType === 'or') {
                const filtered = _.intersectionWith(Object.values(tasks), filters, (taskId, filter) => {
                    const tags = Object.values(this.props.tasks.byId[taskId].tags).map(t => Object.values(t));
                    return _.includes(flattenDeep(tags).map(t => t.id), filter);
                });
                return Object.values(filtered).map(t => this.props.tasks.byId[t]);
            }

            else {
                return Object.values(tasks).map(t => this.props.tasks.byId[t])
                    .filter(t => {
                        if (t.tags.length  !== undefined) {
                            return false;
                        }

                        return _.intersection(flattenDeep(this.getFilters(t.tags)).map(t => t.id), filters).length === filters.length;
                    });
            }
        }
        return Object.values(tasks).map(id => this.props.tasks.byId[id]);
    }

    onDragEnd(result) {
        const { source, destination } = result;

        if (!destination) {
            return;
        }

        const year = source.droppableId.split('-')[1];
        let toYear = null;
        if (source.droppableId !== destination.droppableId) {
            toYear = destination.droppableId.split('-')[1];
        }

        this.props.move(this.props.years, {
            year,
            startIndex: source.index,
            index: destination.index,
            toYear
        });

        return;
    }

    yearsToShow() {
        const years = {};
        const min = this.state.year;
        for (let i = min; i <= min + (this.props.filter.offset) - 1; i++) {
            years[i] = this.props.years[i] || {};
        }
        return Object.entries(years);
    }

    handleClick(value, e) {
        if (e) {
            e.target.blur();
        }
        const year = this.state.year + value;
        let yearToFetch = year;
        if (value > 0) {
            yearToFetch = year + this.props.filter.offset;
        } else {
            yearToFetch = year;
        }
        if (this.props.years[yearToFetch] !== undefined) {
            this.setState({ year });
        } else {
            this.props.fetchYear(yearToFetch, this.props.currentLocation.id).then(() => {
                this.setState({ year });
            }).catch(() => {});
        }
    }

    openTask(task) {
        if (this.props.tasks.byId[task.id]) {
            this.props.openTask(this.props.tasks.byId[task.id]);
            return;
        }
        history.push({ pathname: `${history.location.pathname}/tasks/${task.id}/${task.slug || ''}`, state: { modal: true, title: task.name } });
    }

    render() {
        return <DragDropContext onDragEnd={this.onDragEnd}>
            <button onClick={() => this.handleClick(-1)} id="years-minus-year-button" className="btn-outline-primary btn" style={{ border: 0, borderRadius: '0 20px 20px 0', zIndex: 3, alignSelf: 'start' }}>
                <ChevronLeft style={{ width: '3rem' }} className="icon-f" />
            </button>
            <div className="years d-flex">
                {this.yearsToShow().map((arr) => <Year key={arr[0]} year={arr[0]} tasks={this.mapTasksToYear(arr[1])} openTask={(id) => this.openTask(id)} router={this.props.router} showImages={this.state.showImages} />)}
            </div>
            <button onClick={(e) => this.handleClick(1, e)} id="years-plus-year-button" className="btn-outline-primary btn" style={{ border: 0, borderRadius: '20px 0 0 20px', zIndex: 3, alignSelf: 'start' }}>
                <ChevronRight style={{ width: '3rem' }} className="icon-f" />
            </button>
        </DragDropContext>;
    }
}

const mapStateToProps = ({ tasks, years, currentTask, router, currentLocation, filter, user }) => ({ tasks, years, currentTask, router, currentLocation, filter, user });

const mapDispatchToProps = (dispatch) => ({
    move: (state, params) => dispatch(moveAndSave(state, params)),
    fetchYear: (year, location_id) => dispatch(fetchYear(year, location_id)),
    dispatch,
});

const mergeProps = (propsFromState, propsFromDispatch, ownProps) => {
    return {
        ...ownProps, ...propsFromState, ...propsFromDispatch,
        ...{
            openTask: (task) => propsFromDispatch.dispatch(openTask(task, {
                pathname: `${propsFromState.router.location.pathname}/tasks/${task.id}/${task.slug}`,
                state: { modal: true, title: task.name }
            }))
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps, mergeProps) (YearsContainer);
