import "./polyfill";
import "react-app-polyfill/ie11";
import { faFile, faSpinner, faMinus, faTag, faPlus, faEnvelope, faUserPlus, faTrash, faCheck } from "@fortawesome/free-solid-svg-icons";
import StartStepsIndivitual from "./containers/StartStepsIndivitual";
import InvitationContainer from "./containers/InvitationContainer";
import { library } from "@fortawesome/fontawesome-svg-core";
import { Route, Switch, Redirect } from "react-router-dom";
import { ConnectedRouter } from "connected-react-router";
import * as serviceWorker from "./serviceWorker";
import { ToastContainer } from "react-toastify";
import StartStepsRS15 from "./containers/StartStepsRS15";
import * as Sentry from "@sentry/browser";
import PrivateRoute from "./PrivateRoute";
import { Provider } from "react-redux";
import store from "./store/index";
import ReactDOM from "react-dom";
import Landing from "./Landing";
import history from "./history";
import React from "react";
import App from "./App";
import "./index.scss";
import Terms from "./containers/Terms";
import Conditions from "./containers/Conditions";
import Start from "./components/Steps/Start";

if (process.env.REACT_APP_SENTRY_DNS !== undefined) {
    Sentry.init({ dsn: process.env.REACT_APP_SENTRY_DNS });
}

const clear_cookie_phrase = '9948-4b07-94c8';
if (localStorage.getItem('clear_cookie_phrase') !== clear_cookie_phrase) {
    if (document.cookie !== "") {
        document.cookie.split("; ").map(c => document.cookie = /^[^=]+/.exec(c)[0] + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT");
    }
    localStorage.clear();
    localStorage.setItem('clear_cookie_phrase', clear_cookie_phrase);
    window.location = process.env.REACT_APP_REDIRECT_URL;
}

library.add(faFile, faSpinner, faMinus, faTag, faPlus, faEnvelope, faUserPlus, faTrash, faCheck);

ReactDOM.render(
    <Provider store={store}>
        <div>
            <ConnectedRouter history={history}>
                <Switch>
                    <Route path="/invitation/:token?" component={props => <InvitationContainer {...props} />} />
                    <Route path="/start" exact component={props => <Start {...props} />} />
                    <Route path="/start/individual/:step?" component={props => <StartStepsIndivitual {...props} />} />
                    <Route path="/start/rs15/:step?/:token?" component={StartStepsRS15} />
                    <Route path="/login" component={props => <Landing {...props} />} />
                    <Route path="/terms" component={props => <Terms {...props} />} />
                    <Route path="/conditions" component={props => <Conditions {...props} />} />
                    <PrivateRoute path="/" component={App} />
                    <Redirect to="/login" />
                </Switch>
            </ConnectedRouter>
            <ToastContainer />
        </div>
    </Provider>,
    document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
