import React, { Component } from "react";
import { Link } from "react-router-dom";
import history from "../history";

export class Sidebar extends Component {

    link(name, url) {
        return <Link to={url} id={'sidebar-' + (url === '' ? 'frontpage' : url.split('/').pop())} className={url === history.location.pathname ? 'selected' : ''}>{name}</Link>;
    }

    hasLocation(url) {
        return this.props.location.id ? url : '/';
    }

    render() {
        return (
            <div className="d-flex flex-column sidebar text-left left w-25" style={{ minWidth: '19rem', minHeight: '13rem' }}>
                {this.props.location.id ? <div className="d-flex flex-column text-left">
                    {this.link('Taloyhtiön perustiedot', this.hasLocation(`/${this.props.location.id}/${this.props.location.slug}/edit`))}
                    {this.link('Taloyhtiön menot & tulot', this.hasLocation(`/${this.props.location.id}/${this.props.location.slug}/income`))}
                    {this.link('Henkilöt', this.hasLocation(`/${this.props.location.id}/${this.props.location.slug}/users`))}
                </div> : this.link('Etusivu', '')}
                <br/>
                {this.link('Omat tiedot', "/user")}
                <br/>
                {this.link('FAQ', "/faq")}
            </div>
        );
    }
}

export default Sidebar;
