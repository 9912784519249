import axios from "axios";
import * as api from "../apis/attachments";

export const REQUEST_CREATE_ATTACHMENT = 'REQUEST_CREATE_ATTACHMENT';
export const REQUEST_CREATE_ATTACHMENT_SUCCESS = 'REQUEST_CREATE_ATTACHMENT_SUCCESS';
export const REQUEST_CREATE_ATTACHMENT_ERROR = 'REQUEST_CREATE_ATTACHMENT_ERROR';

export const REQUEST_DELETE_ATTACHMENT = 'REQUEST_DELETE_ATTACHMENT';
export const REQUEST_DELETE_ATTACHMENT_SUCCESS = 'REQUEST_DELETE_ATTACHMENT_SUCCESS';
export const REQUEST_DELETE_ATTACHMENT_ERROR = 'REQUEST_DELETE_ATTACHMENT_ERROR';

export const upload = async (auth, id, files, destination, dispatch) => {
    dispatch({ type: REQUEST_CREATE_ATTACHMENT });
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + auth.token;
    axios.defaults.headers.common['Accept'] = 'application/json';
    const uploadS3 = files.map(async (file) => {
        const res = await api.signedStorageUrl(file, { bucket: process.env.REACT_APP_AWS_BUCKET });
        delete axios.defaults.headers.common['Authorization'];
        delete axios.defaults.headers.common['Accept'];
        delete res.data.headers['Host'];
        await api.s3Put(res.data.url, file, res.data.headers);

        res.data.origName = file.name;
        res.data.type = file.type;

        return res.data;
    });
    let values = await Promise.all(uploadS3);
    values = values.map(r => ({ key: r.key, origName: r.origName, type: r.type }));

    return dispatch(saveAttachment(id, values, destination));
};
export const saveAttachment = (id, files, destination) => {
    return {
        types: [REQUEST_CREATE_ATTACHMENT, REQUEST_CREATE_ATTACHMENT_SUCCESS, REQUEST_CREATE_ATTACHMENT_ERROR],

        shouldCallAPI: (state) => {
            return true;
        },

        callAPI: () => api.attach(id, files, destination),

        payload: { destination },
    };
};

export const deleteAttachment = (id, parent, destination) => {
    return {
        types: [REQUEST_DELETE_ATTACHMENT, REQUEST_DELETE_ATTACHMENT_SUCCESS, REQUEST_DELETE_ATTACHMENT_ERROR],

        shouldCallAPI: (state) => {
            return true;
        },

        callAPI: () => api.del(id, parent, destination),

        payload: { destination },
    };
};
