import { Dropdown, DropdownToggle, DropdownMenu } from "reactstrap";
import { ChevronDown, Plus } from "../svg/icons";
import React, { Component } from "react";
import PropTypes from "prop-types";
import Circle from "./Circle";
import _ from "lodash";

export class TagDropdown extends Component {
    constructor(props) {
        super(props);

        this.toggle = this.toggleDropdown.bind(this);
        this.state = {
            tags: this.props.tags,
            dropdown: false,
            new: {},
            selectedTags: this.props.selectedTags,
        };
    }

    componentDidUpdate(prevProps) {
        if (this.props.selectedTags !== prevProps.selectedTags) {
            this.setState({ selectedTags: this.props.selectedTags });
        }
    }

    toggleDropdown() {
        this.setState(prevState => ({ dropdown: !prevState.dropdown }));
    }

    isTagChecked(key, tag) {
        if (this.state.selectedTags === undefined) return false;

        if (key === 'custom') {
            return this.state.selectedTags.find(t => (t !== undefined && t.name === tag.name)) !== undefined;
        }

        if (this.state.selectedTags[key] === undefined || this.state.selectedTags[key][0] === undefined) return false;

        return this.state.selectedTags[key].find(t => (t !== undefined && t.name === tag.name)) !== undefined;
    }

    toggleTags(key, tag) {
        let state = this.state.selectedTags;
        if (state.length === undefined) {
            if (state[key] === undefined) state[key] = [];
            if (state[key].map(_tag => {
                return _tag.id === tag.id;
            }).reduce((v, a) => {
                return v ? v : a;
            }, 0)) {
                state[key] = state[key].filter(_tag => _tag.id !== tag.id);
            } else {
                state[key] = [...state[key], tag];
            }
        } else {
            const index = _.findIndex(state, _tag => _tag.id === tag.id);
            if (index !== -1) {
                state = state.filter(_tag => _tag.id !== tag.id);
            } else {
                state = [...state, tag];
            }
        }

        this.setState({ selectedTags: state });
        this.props.onChange(state);
    }

    addTag(target) {
        if (!this.refs[target + 'TagName'].value) {
            return;
        }
        this.props.newTag(target, { name: this.refs[target + 'TagName'].value });
        this.refs[target + 'TagName'].value = '';
    }

    deleteTag(tag, key) {
        this.props.deleteTag(tag);
    }

    renderTag(tag, key) {
        return <div className="form-group py-0 mb-0" style={{ marginTop: '0.25rem' }} key={'tag-' + tag.name.replace(' ', '-') + (tag.id || '')}>
            <div className="form-check">
                <input type="checkbox" id={tag.id} checked={this.isTagChecked(key, tag)} onChange={() => this.toggleTags(key, tag)} value="" style={{ marginTop: '0.0rem' }} className="form-check-input"/>
                <label htmlFor={tag.id} onClick={(e) => {
                    e.preventDefault();
                    this.toggleTags(key, tag);
                }} className="form-check-label">{tag.name}</label>
                {this.props.allowDelete !== undefined && this.props.allowDelete ? <span onClick={() => this.deleteTag(tag, key)} className="float-right c-pointer del-tag" title="Poista">x</span> : ''}
            </div>
        </div>;
    }

    render() {
        return (
            <Dropdown isOpen={this.state.dropdown} toggle={() => this.toggleDropdown()} className={this.props.className || ''} direction="down">
                <DropdownToggle disabled={this.props.disabled} color="link" className="w-100 px-0">
                    <div className="btn btn-outline-primary btn-sm w-100 text-left d-flex align-items-center justify-content-between" style={{ fontWeight: 600 }}>
                        <span>{this.props.title}</span>
                        <ChevronDown className="icon-f ml-1 border-left border-secondary pl-1"/>
                    </div>
                </DropdownToggle>
                <DropdownMenu style={{ fontSize: '0.8rem', lineHeight: '0.9rem', background: '#e6e6e6', maxHeight: '90vh', overflowY: 'auto' }} className="p-0 b-0" right={true}>
                    <div className="d-flex">
                        {this.props.tags !== undefined ? Object.keys(this.props.tags).sort().map(key => {
                            if (Number.isInteger(parseInt(key.replace(/\d\s/, '')))) {
                                return <div className="p-3 pr-5 tag-container" key={key}>{this.renderTag(this.props.tags[key], undefined)}</div>;
                            }
                            return <div key={key + '-wrapper'} className="p-3 tag-container">
                                {this.props.addNew ? <div className="pt-3 tag-container">
                                    <button className="btn btn-link d-flex align-items-center justify-content-start mb-2" id={"tag-dropdown-new-" + key.replace(/\d /g, '') + "-trigger"} onClick={() => this.setState({ new: { ...this.state.new, ...{ [key]: !this.state.new[key] } } })}>
                                        <Circle style={{ cursor: 'pointer' }} type="solid" size="medium">
                                            <Plus className="icon-f-inv" style={{ width: '1.2rem' }}/>
                                        </Circle>
                                        <span className="ml-2">Lisää uusi</span>
                                    </button>
                                    {this.state.new[key] ? <div className="">
                                        <input type="hidden" ref="tagTarget" value={key}/>

                                        <div className="input-group input-group-sm" style={{ width: '10rem' }}>
                                            <input type="text" className="form-control" ref={key + 'TagName'} placeholder="Nimi"/>
                                            <div className="input-group-append">
                                                <button className="btn btn-primary" onClick={() => this.addTag(key)}>Lisää</button>
                                            </div>
                                        </div>
                                    </div> : ''}
                                </div> : ''}
                                <div className="py-1" key={key + '-title'}><h6>{key === 'custom' ? 'Aihe' : key.replace(/\d\s/, '')}</h6></div>
                                {this.props.tags[key].map(tag => this.renderTag(tag, key))}
                            </div>;
                        }) : ''}
                    </div>
                </DropdownMenu>
            </Dropdown>
        );
    }
}

TagDropdown.propTypes = {
    title: PropTypes.string.isRequired,
    tags: PropTypes.oneOfType([
        PropTypes.array,
        PropTypes.object
    ]).isRequired,
    onChange: PropTypes.func.isRequired,
    addNew: PropTypes.bool,
    newOptions: PropTypes.object,
    newTag: PropTypes.func,
    disabled: PropTypes.bool,
};

export default TagDropdown;
