import { getLocation, getLocations, editLocation, searchZipcodes } from "../actions/locations";
import { getOrganizations } from "../actions/organizations";
import { upload } from "../actions/attachments";
import LocationPage from "../components/LocationPage";
import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Loading from "../components/Loading";

export class LocationPageContainer extends Component {
    constructor(props) {
        super(props);

        this.children = [];

        this.state = {
            selected_city: [],
            selected_zip: [],
            query_zip: '',
            zip_name_options: [],
            zip_options: [],
        };
    }

    async componentDidMount() {
        await this.props.getOrganizations();
        this.props.getLocation(this.props.match.params.id);
    }

    save(data) {
        const temp = {};
        const fillable = [...this.props.currentLocation.get_fillable, 'id'];
        fillable.map(key => {
            temp[key] = this.props.currentLocation[key];
            return key;
        });
        return this.props.editLocation({ ...temp, ...data });
    }

    changeState(newState) {
        this.setState(newState);
    }

    onSearch(query) {
        this.setState({ query_zip: query });
        return this.props.searchZipcodes({ name: '%', zip: this.state.query_zip || '%' })
            .then(response => {
                if(!this.state.query_zip){
                    this.setState({ zipcode_id: this.props.currentLocation.zipcode_id });
                    return Promise.resolve(Object.values(response.data.results));
                }
                this.setState({
                    zip_name_options: [...new Set(Object.values(response.data.results).map(zip => zip.name))],
                    zip_options: Object.values(response.data.results).map(zip => zip.zip),
                    zipcode_id: Object.keys(response.data.results)[0],
                });
                return Promise.resolve(Object.values(response.data.results));
            });
    }

    saveAttachment(attachments) {
        this.props.saveAttachment(this.props.currentLocation.id, attachments, this.props.auth);
    }

    render() {
        if(this.props.currentLocation.id === undefined){
            return <Loading isLoading={this.props.loading.currentLocation} />;
        }
        return <LocationPage
            saveAttachment={(attachments) => this.saveAttachment(attachments)}
            location={this.props.currentLocation}
            isLoading={this.props.loading.currentLocation}
            state={this.state}
            save={(data) => this.save(data)}
            onSearch={(query) => this.onSearch(query)}
            match={this.props.match}
            setState={(newState) => this.changeState(newState)}
        />;
    }
}

LocationPageContainer.propTypes = {
    currentLocation: PropTypes.object.isRequired,
};

const mapStateToProps = ({ currentLocation, loading, auth }) => ({ currentLocation, loading, auth });

const mapDispatchToProps = (dispatch) => ({
    getLocation: (id) => dispatch(getLocation(id)),
    getLocations: () => dispatch(getLocations()),
    editLocation: (location) => dispatch(editLocation(location)),
    getOrganizations: () => dispatch(getOrganizations()),
    searchZipcodes: (query) => dispatch(searchZipcodes(query)),
    saveAttachment: (id, files, auth) => upload(auth, id,files,'locations', dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps) (LocationPageContainer);
