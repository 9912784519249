import { TOGGLE_CONTACT_MODAL } from "../actions/contact";

export default (state = { show: false, header: '' }, action) => {
    switch (action.type) {
    case TOGGLE_CONTACT_MODAL:
        return { show: !state.show, header: action.header };
    default:
        return state;
    }
};
