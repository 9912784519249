import NewTaskContainer from "../containers/NewTaskContainer";
import { Modal, ModalBody } from "reactstrap";
import React, { Component } from "react";
import Circle from "./Circle";

export class TaskModal extends Component {
    goBack() {
        this.props.history.push({ pathname: `/${this.props.match.params.id}/${this.props.match.params.slug || ''}`, state: { modal: false } });
    }

    render() {
        return <Modal isOpen={true} toggle={() => this.goBack()} style={{ width: '95%', maxWidth: '95%' }}>
            <ModalBody>
                <div className="col-md-9 col-12" style={{ position: 'relative', maxWidth: '65rem', margin: 'auto' }}>
                    <Circle type="outline" size="small" className="mt-2 c-pointer bg-white" id="new-task-close-button" onClick={() => this.goBack()} style={{ position: 'absolute', zIndex: 2, right: '0', top: '-2rem', width: '1.5rem', height: '1.5rem', fontSize: '1rem' }}>X</Circle>
                </div>
                <NewTaskContainer year={this.props.history.location.state.year} match={this.props.match}/>
            </ModalBody>
        </Modal>;
    }
}

export default TaskModal;
