import { editLocation } from "../actions/locations";
import { createTask } from "../actions/tasks";
import NewTask from "../components/NewTask";
import React, { Component } from "react";
import { connect } from "react-redux";

export class NewTaskContainer extends Component {
    render() {
        return <NewTask
            save={this.props.createTask}
            year={this.props.year}
            match={this.props.match}
            currentLocation={this.props.currentLocation}
            editLocation={this.props.editLocation}
        />;
    }
}

const mapStateToProps = ({ currentLocation }) => ({ currentLocation });

const mapDispatchToProps = (dispatch) => ({
    createTask: (data) => dispatch(createTask(data)),
    editLocation: (location) => dispatch(editLocation(location)),
});

export default connect(mapStateToProps, mapDispatchToProps)(NewTaskContainer);
