import { validateInvitation } from "../actions/invitations";
import HeaderContainer from "./HeaderContainer";
import { joinLocation } from "../actions/user";
import LoginBox from "../components/LoginBox";
import { login } from "redux-implicit-oauth2";
import Loading from "../components/Loading";
import { Redirect } from "react-router-dom";
import React, { Component } from "react";
import { connect } from "react-redux";

const config = {
    url: process.env.REACT_APP_API_URL + "/oauth/azure-b2c/authorize",
    client: process.env.REACT_APP_OAUTH_CLIENT_ID,
    redirect: process.env.REACT_APP_REDIRECT_URL,
    scope: "",
};

export class InvitationContainer extends Component {
    constructor(props) {
        super(props);

        this.state = { joining: false };
    }

    componentDidMount() {
        if (this.props.match && this.props.match.params.token && this.props.invitation.valid === undefined) {
            this.props.validateInvitation(this.props.match.params.token);
        }
    }

    componentDidUpdate(nextProps, nextState) {
        if (this.props.auth.isLoggedIn && this.props.invitation.valid && !this.state.joining) {
            this.setState({ joining: true });
            this.props.joinLocation(this.props.invitation.key);
        }
    }

    static getDerivedStateFromProps(props, prevState) {
        if (props.auth.isLoggedIn && props.invitation.valid && !prevState.joining) {
            return { triggerComponentDidUpdate: true };
        }
        return null;
    }

    render() {
        return <div>
            <HeaderContainer/>
            {
                !this.props.auth.isLoggedIn ? (<div className="mt-5"><LoginBox login={this.props.login}/></div>)
                    : this.props.loading.joiningLocation ? <Loading isLoading={this.props.loading.joiningLocation}/>
                        : !this.props.joiningLocation && (this.props.invitation.valid === false || this.props.invitation.done) ? <Redirect to={{ pathname: (this.props.location !== undefined && this.props.location.state !== undefined && this.props.location.state.redirect !== undefined) ? this.props.location.state.redirect : '/' }}/> : ''
            }
        </div>;
    }
}

const mapStateToProps = ({ auth, invitation, loading, locations, user }) => ({ auth, invitation, loading, locations, user });

const mapDispatchToProps = (dispatch) => ({
    joinLocation: (key) => dispatch(joinLocation(key)),
    validateInvitation: (key) => dispatch(validateInvitation(key)),
    login: () => dispatch(login(config)),
});

export default connect(mapStateToProps, mapDispatchToProps)(InvitationContainer);
