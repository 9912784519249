import axios from "axios";

const url = process.env.REACT_APP_API_URL;

export const get = () => axios.get(url + '/api/profile');
export const put = (data) => axios.put(url + '/api/profile', data);
export const del = (location, user) => axios.delete(url + '/api/locations/' + location + '/user/' + user);
export const attach = (id, files) => axios.post(url + '/api/users/' + id + '/attachments', files);

export const invite = (data) => axios.post(url + '/api/invitations', data);
export const join = (key) => axios.put(url + '/api/invitations/' + key);
export const validate = (key) => axios.get(url + '/api/invitations/validate/' + key);
export const invitation = (key) => axios.get(url + '/api/invitations/' + key);
export const password = (data) => axios.post(url + '/api/user/password', data);
export const role = (role, location, user) => axios.put(url + '/api/users/' + user + '/roles', {
    role_id: role,
    user,
    location
});
export const roles = () => axios.get(url + '/api/roles');
export const notification = (id) => axios.put(url + `/api/notifications/${id}`, { seen: true });
export const faq = () => axios.get(url + `/api/faqs`);
