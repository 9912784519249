import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Component } from "react";
import PropTypes from "prop-types";
import moment from "moment";

export class Attachment extends Component {
    style() {
        return { display: 'none', position: 'absolute', right: 20, top: 5, backgroundColor: 'white' };
    }

    remove(e, id) {
        e.preventDefault();
        this.props.deleteAttachment(id);
    }

    name(name) {
        name = name.split('__');
        name.shift();
        return name.join('__');
    }

    render() {
        return <div className="d-flex flex-column justify-content-between align-items h-100">
            {this.props.file.type.indexOf('image') !== -1 ?
                <a className="attachment" href={this.props.file.protected_paths.big} target="_blank"
                    rel="noopener noreferrer">
                    {!this.props.disabled && <button className="btn btn-outline-primary" style={this.style()}
                        onClick={(e) => this.remove(e, this.props.file.id)}>poista</button>}
                    <img src={this.props.file.protected_paths[this.props.size]} alt=""/>
                </a>
                : <a className="attachment d-flex flex-column justify-content-center align-items-center h-100"
                    href={this.props.file.protected_paths.file} target="_blank" rel="noopener noreferrer">
                    {!this.props.disabled && <button className="btn btn-outline-primary" style={this.style()}
                        onClick={(e) => this.remove(e, this.props.file.id)}>poista</button>}
                    <FontAwesomeIcon icon="file" style={{ fontSize: '5rem', marginTop: '2rem' }}/>
                </a>}

            <p className="p-2 m-0" style={{ wordWrap: 'break-word' }}>
                <strong style={{ fontSize: '12px' }}>{
                    this.name(this.props.file.filename)
                }</strong><br/>
                <strong className="text-muted"
                    style={{ fontSize: '12px' }}>Lisätty {moment(this.props.file.created_at).format('D.M.YYYY')}</strong>
            </p>
        </div>;
    }
}

Attachment.propTypes = {
    file: PropTypes.object.isRequired,
    size: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
};

export default Attachment;
