import React, { Component } from "react";
import PropTypes from "prop-types";
import { Droppable as DroppableDnd } from "react-beautiful-dnd";

export default class Droppable extends Component {
    render() {
        return <DroppableDnd droppableId={this.props.idString}>
            {(provided, snapshot) => (
                <div
                    ref={provided.innerRef}
                    style={this.props.style}
                >
                    {this.props.children}
                    {provided.placeholder}
                </div>
            )}
        </DroppableDnd>;
    }
}

Droppable.propTypes = {
    idString: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};
