import { getLocation, getLocations } from "../actions/locations";
import { getOrganizations } from "../actions/organizations";
import Location from "../components/Location";
import React, { Component } from "react";
import { connect } from "react-redux";

export class LocationContainer extends Component {

    async componentDidMount() {
        await this.props.getOrganizations();
        this.props.getLocation(this.props.match.params.id);
        this.props.getLocations(this.props.locations.meta.current_page || null);
    }

    componentDidUpdate(props) {
        if (props.match.params.id !== this.props.match.params.id) {
            this.props.getLocation(this.props.match.params.id);
        }
    }

    render() {
        return <Location location={this.props.currentLocation} isLoading={this.props.loading.currentLocation}/>;
    }
}

const mapStateToProps = ({ currentLocation, loading, locations }) => ({ currentLocation, loading, locations });

const mapDispatchToProps = (dispatch) => ({
    getLocation: (id) => dispatch(getLocation(id)),
    getLocations: (page) => dispatch(getLocations(page)),
    getOrganizations: () => dispatch(getOrganizations()),
});

export default connect(mapStateToProps, mapDispatchToProps)(LocationContainer);
