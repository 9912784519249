import * as api from "../apis/organizations";

export const REQUEST_CREATE_ORGANIZATIONS = 'REQUEST_CREATE_ORGANIZATIONS';
export const REQUEST_CREATE_ORGANIZATIONS_SUCCESS = 'REQUEST_CREATE_ORGANIZATIONS_SUCCESS';
export const REQUEST_CREATE_ORGANIZATIONS_ERROR = 'REQUEST_CREATE_ORGANIZATIONS_ERROR';

export const REQUEST_ORGANIZATIONS = 'REQUEST_ORGANIZATIONS';
export const REQUEST_ORGANIZATIONS_SUCCESS = 'REQUEST_ORGANIZATIONS_SUCCESS';
export const REQUEST_ORGANIZATIONS_ERROR = 'REQUEST_ORGANIZATIONS_ERROR';

export const REQUEST_ORGANIZATION = 'REQUEST_ORGANIZATION';
export const REQUEST_ORGANIZATION_SUCCESS = 'REQUEST_ORGANIZATION_SUCCESS';
export const REQUEST_ORGANIZATION_ERROR = 'REQUEST_ORGANIZATION_ERROR';

export const createdOrganization = (data) => {
    return {
        types: [REQUEST_CREATE_ORGANIZATIONS, REQUEST_CREATE_ORGANIZATIONS_SUCCESS, REQUEST_CREATE_ORGANIZATIONS_ERROR],

        shouldCallAPI: (state) => {
            return true;
        },

        callAPI: () => api.post(data),

        payload: {},
    };
};

export const getOrganizations = () => {
    return {
        types: [REQUEST_ORGANIZATIONS, REQUEST_ORGANIZATIONS_SUCCESS, REQUEST_ORGANIZATIONS_ERROR],

        shouldCallAPI: (state) => {
            return state.organization.id === undefined;
        },

        callAPI: () => api.get(),

        payload: {},
    };
};

export const getOrganization = (id) => {
    return {
        types: [REQUEST_ORGANIZATION, REQUEST_ORGANIZATION_SUCCESS, REQUEST_ORGANIZATION_ERROR],

        shouldCallAPI: (state) => {
            return true;
        },

        callAPI: () => api.get(id),

        payload: {},
    };
};
