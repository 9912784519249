import * as api from "../apis/contact";

export const TOGGLE_CONTACT_MODAL = 'TOGGLE_CONTACT_MODAL';

export const REQUEST_SEND_EMAIL = "REQUEST_SEND_EMAIL";
export const REQUEST_SEND_EMAIL_SUCCESS = "REQUEST_SEND_EMAIL_SUCCESS";
export const REQUEST_SEND_EMAIL_ERROR = "REQUEST_SEND_EMAIL_ERROR";

export const toggleContactModal = (header) => ({
    type: TOGGLE_CONTACT_MODAL,
    header
});

export const sendContactEmail = (message) => {
    return {
        types: [REQUEST_SEND_EMAIL, REQUEST_SEND_EMAIL_SUCCESS, REQUEST_SEND_EMAIL_ERROR],

        shouldCallAPI: (state) => {
            return true;
        },

        callAPI: () => api.mail(message),

        payload: {},
    };
};
