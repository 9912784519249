import * as api from "../apis/comments";

export const REQUEST_COMMENTS_SUCCESS = 'REQUEST_COMMENTS_SUCCESS';
export const REQUEST_COMMENTS_ERROR = 'REQUEST_COMMENTS_ERROR';
export const REQUEST_COMMENTS = 'REQUEST_COMMENTS';

export const REQUEST_CREATE_COMMENT_SUCCESS = 'REQUEST_CREATE_COMMENT_SUCCESS';
export const REQUEST_CREATE_COMMENT_ERROR = 'REQUEST_CREATE_COMMENT_ERROR';
export const REQUEST_CREATE_COMMENT = 'REQUEST_CREATE_COMMENT';

export const WEBSOCKET_ADD_COMMENT = 'WEBSOCKET_ADD_COMMENT';

export const addComment = (comment) => ({
    type: WEBSOCKET_ADD_COMMENT,
    payload: { task_id: comment.subject_id },
    data: { comment }
});

export const getComments = (task_id) => {
    return {
        types: [REQUEST_COMMENTS, REQUEST_COMMENTS_SUCCESS, REQUEST_COMMENTS_ERROR],

        shouldCallAPI: (state) => {
            return task_id !== undefined && state.comments[task_id] === undefined;
        },

        callAPI: () => api.get(task_id),

        payload: { task_id },
    };
};

export const createComment = (task_id, text) => {
    return {
        types: [REQUEST_CREATE_COMMENT, 'VOID_SUCCESS', REQUEST_CREATE_COMMENT_ERROR],

        shouldCallAPI: (state) => {
            if (text === null || text === '') {
                return false;
            }

            return true;
        },

        callAPI: () => api.post(task_id, { text }),

        payload: { task_id },
    };
};
