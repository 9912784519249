import axios from "axios";

const url = process.env.REACT_APP_API_URL;

export const get = (id = '') => axios.get(url + '/api/locations' + (id !== '' ? '/' + id : ''));
export const add = (data) => axios.post(url + '/api/locations', data);
export const put = (data) => axios.put(url + '/api/locations/' + data.id, data);
export const zipcodesSearch = (data) => axios.post(url + '/api/zipcodes/search', data);
export const attach = (id, files) => axios.post(url + '/api/locations/' + id + '/attachments', files);
export const deleteTag = (id, tag) => axios.delete(url + '/api/locations/' + id + '/tags/' + tag);
export const year = (year, location) => axios.get(url + '/api/locations/' + location + '/years/' + year);
export const page = (_url) => {
    const page = typeof _url === 'number' ? _url : _url.match(/page=(\d*)/)[1];
    return axios.get(url + '/api/locations?page=' + page);
};

export const excel = (chart, headings) => axios({
    method: 'post',
    url: url + '/api/locations/budget/export',
    responseType: 'blob',
    data: { chart, headings }
}).then((response) => {
    const url = window.URL.createObjectURL(response.data);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'Budjettiarvio.xlsx');
    document.body.appendChild(link);
    link.click();
    return response;
});

export const longTermPlanExcel = (locations) => axios({
    method: 'post',
    url: url + '/api/locations/longtermplan/export',
    responseType: 'blob',
    data: { locations }
}).then((response) => {
    const url = window.URL.createObjectURL(response.data);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'PTS.xlsx');
    document.body.appendChild(link);
    link.click();
    return response;
});
