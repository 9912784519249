import React, { Component } from "react";
import PropTypes from "prop-types";
import { Draggable as DraggableDnd } from "react-beautiful-dnd";

export default class Draggable extends Component {
    getStyles(isDragging, draggableStyles) {
        return {
            ...draggableStyles
        };
    }

    render() {
        return <DraggableDnd
            key={this.props.idString}
            draggableId={'t'+this.props.idString}
            index={this.props.index}
            isDragDisabled={this.props.disabled || false}>
            {(provided, snapshot) => (
                <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    style={this.getStyles(snapshot.isDragging, provided.draggableProps.style)}>
                    {this.props.children}
                </div>
            )}
        </DraggableDnd>;
    }
}

Draggable.propTypes = {
    idString: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    index: PropTypes.number.isRequired,
};
