import React, { Component } from "react";
import { toast } from "react-toastify";
import history from "../history";

export class Invite extends Component {
    componentDidUpdate(prevProps, nextProps) {
        this.refs.email.focus();
    }

    invite() {
        if (this.refs.email.value.match(/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/)) {
            this.props.invite(this.refs.email.value, this.refs.role.value);
            history.goBack();
        } else {
            toast.error('Sähköposti ei hyväksytty');
        }
    }

    render() {
        return (
            <div className="h-100 d-flex flex-column">
                <div className="row">
                    <div className="Invite" style={{ background: 'white', flex: 1, position: 'relative' }}>
                        <header className="bg-light p-3 rounded-top">
                            <h1>Kutsu käyttäjä</h1>
                        </header>
                        <div className="d-block p-4">
                            <div className="d-flex flex-column align-items-center mx-auto w-50">
                                <label htmlFor="email" className="mr-auto">Sähköposti</label>
                                <div className="d-flex align-items-center justify-content-start">
                                    <input style={{ flex: 1 }} ref="email" type="email" name="email" placeholder="esimerkki@email.fi"/>
                                    <select ref="role" className="form-control ml-3" defaultValue="" style={{ width: '9rem' }}>
                                        <option>Rooli</option>
                                        {this.props.roles.map(role => <option value={role.id} key={`roles-${role.id}`}>{role.name}</option>)}
                                    </select>
                                </div>
                                <button className="btn m-2 btn-primary ml-auto" style={{ width: '10rem' }} onClick={() => this.invite()} id="invite-user-button">Kutsu</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Invite;
