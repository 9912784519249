import YearsContainer from "../containers/YearsContainer";
import React, { Component } from "react";
import PropTypes from "prop-types";
import Loading from "./Loading";

class Location extends Component {
    render() {
        return (
            <div className="Location h-100 d-flex flex-row" style={{ position: 'relative' }}>
                {this.props.isLoading ? <Loading isLoading={this.props.isLoading}/> : <YearsContainer/>}
            </div>
        );
    }
}

Location.propTypes = {
    location: PropTypes.object.isRequired,
    isLoading: PropTypes.bool.isRequired,
};

export default Location;
