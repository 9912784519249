import asyncDispatchMiddleware from "../middlewares/AsyncDispatchMiddleware";
import MaintenanceMiddleware from "../middlewares/MaintenanceMiddleware";
import { routerMiddleware } from "connected-react-router";
import WebsocketMiddleware from "../middlewares/WebsocketMiddleware";
import analyticsMiddleware from "../middlewares/AnalyticsMiddleware";
import CallApiMiddleware from "../middlewares/CallApiMiddleware";
import { createStore, applyMiddleware, compose } from "redux";
import { authMiddleware } from "redux-implicit-oauth2";
import thunkMiddleware from "redux-thunk";
import TagManager from "react-gtm-module";
import reducers from "../reducers/index";
import history from "../history";

if (process.env.REACT_APP_GTM) {
    const tagManagerArgs = { gtmId: process.env.REACT_APP_GTM };
    TagManager.initialize(tagManagerArgs);
}

const middleware = [
    routerMiddleware(history),
    authMiddleware,
    MaintenanceMiddleware({ instance: null, loops: 0 }),
    CallApiMiddleware,
    thunkMiddleware,
    asyncDispatchMiddleware,
    WebsocketMiddleware,
    analyticsMiddleware
];

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
    reducers(history),
    composeEnhancers(applyMiddleware(...middleware))
);


export default store;
