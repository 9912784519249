import { REQUEST_LOCATION_SUCCESS, SET_LOCATION, REQUEST_FETCH_YEAR_SUCCESS } from "../actions/locations";
import { REQUEST_SORT_TASKS_ERROR, REVERT_MOVE, MOVE, move } from "../actions/sorting";
import { WEBSOCKET_CREATE_TASK, REQUEST_CREATE_TASK_SUCCESS, REQUEST_DELETE_TASK_SUCCESS, REQUEST_TASK_SUCCESS, SET_YEARS } from "../actions/tasks";
import moment from "moment";
import _ from "lodash";

export default (state = {}, action) => {
    switch (action.type) {
    case REQUEST_SORT_TASKS_ERROR:
        action.asyncDispatch(move(REVERT_MOVE, state, action.payload.revert));
        return { ...state };
    case SET_YEARS:
        const data = {};
        Object.keys(action.data).forEach(key => {
            data[key] = action.data[key].map(task => task.id);
            return;
        });
        return { ...state, ...data };
    case REQUEST_LOCATION_SUCCESS:
    case SET_LOCATION:
        const all = { ...action.data.location.tasks };
        const start = moment().year();
        const end = moment().add(10, 'years').year();
        for (let i = start; i <= end; i++) {
            if (all[i] === undefined) {
                all[i] = [];
            }
        }
        return {
            ..._.mapValues(
                all, group =>
                    _.mapValues(
                        _.orderBy(group, ['order_number'], ['asc']),
                        t => t.id
                    )
            )
        };
    case REQUEST_TASK_SUCCESS:
    case WEBSOCKET_CREATE_TASK:
    case REQUEST_CREATE_TASK_SUCCESS:
        if (!state[action.data.task.year]) {
            return { ...state };
        }
        return {
            ...state,
            ...{
                ...{ [action.data.task.year]: [...Object.values(state[action.data.task.year]), action.data.task.id] }
            }
        };
    case REQUEST_DELETE_TASK_SUCCESS:
        const task = action.data.id ? action.data : action.payload;
        return {
            ...state,
            ...{
                ...{ [task.year]: [...Object.values(state[task.year]).filter(id => id !== task.id)] }
            }
        };
    case REQUEST_FETCH_YEAR_SUCCESS:
        if (action.data.tasks.length === undefined) {
            return { ...state, [action.data.year]: { ...Object.values(action.data.tasks[action.data.year]).map(task => task.id) } };
        } else {
            return { ...state, [action.data.year]: {} };
        }
    case MOVE:
    case REVERT_MOVE:
        return { ...state, ...{ ...action.data } };
    case 'LOGOUT':
    case 'CLEAR_CURRENT_LOCATION':
    case 'MAINTENANCE_MODE':
        if (action.type === 'MAINTENANCE_MODE' && !action.data.mode) {
            return { ...state };
        }
        return {};
    default:
        return { ...state };
    }
};
