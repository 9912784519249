import _ from "lodash";
import * as api from "../apis/tasks";
import { replace, push } from "connected-react-router";

export const REQUEST_UPDATE_TASK_SUCCESS = 'REQUEST_UPDATE_TASK_SUCCESS';
export const REQUEST_UPDATE_TASK_ERROR = 'REQUEST_UPDATE_TASK_ERROR';
export const REQUEST_UPDATE_TASK = 'REQUEST_UPDATE_TASK';
export const SET_CURRENT_TASK = 'SET_CURRENT_TASK';

export const REQUEST_TASK = 'REQUEST_TASK';
export const REQUEST_TASK_SUCCESS = 'REQUEST_TASK_SUCCESS';
export const REQUEST_TASK_ERROR = 'REQUEST_TASK_ERROR';

export const REQUEST_CREATE_TASK = 'REQUEST_CREATE_TASK';
export const REQUEST_CREATE_TASK_SUCCESS = 'REQUEST_CREATE_TASK_SUCCESS';
export const REQUEST_CREATE_TASK_ERROR = 'REQUEST_CREATE_TASK_ERROR';

export const REQUEST_DELETE_TASK = 'REQUEST_DELETE_TASK';
export const REQUEST_DELETE_TASK_SUCCESS = 'REQUEST_DELETE_TASK_SUCCESS';
export const REQUEST_DELETE_TASK_ERROR = 'REQUEST_DELETE_TASK_ERROR';

export const REQUEST_SPLIT_TASK = 'REQUEST_SPLIT_TASK';
export const REQUEST_SPLIT_TASK_SUCCESS = 'REQUEST_SPLIT_TASK_SUCCESS';
export const REQUEST_SPLIT_TASK_ERROR = 'REQUEST_SPLIT_TASK_ERROR';

export const REQUEST_DOWNLOAD_TASK_EXCEL = 'REQUEST_DOWNLOAD_TASK_EXCEL';
export const REQUEST_DOWNLOAD_TASK_EXCEL_SUCCESS = 'REQUEST_DOWNLOAD_TASK_EXCEL_SUCCESS';
export const REQUEST_DOWNLOAD_TASK_EXCEL_ERROR = 'REQUEST_DOWNLOAD_TASK_EXCEL_ERROR';

export const REQUEST_DOWNLOAD_PTS_TASK_EXCEL = 'REQUEST_DOWNLOAD_PTS_TASK_EXCEL';
export const REQUEST_DOWNLOAD_PTS_TASK_EXCEL_SUCCESS = 'REQUEST_DOWNLOAD_PTS_TASK_EXCEL_SUCCESS';
export const REQUEST_DOWNLOAD_PTS_TASK_EXCEL_ERROR = 'REQUEST_DOWNLOAD_PTS_TASK_EXCEL_ERROR';

export const WEBSOCKET_CREATE_TASK = 'WEBSOCKET_CREATE_TASK';
export const WEBSOCKET_CREATE_TASK_ATTACHMENT = 'WEBSOCKET_CREATE_TASK_ATTACHMENT';
export const WEBSOCKET_DELETE_TASK_ATTACHMENT = 'WEBSOCKET_DELETE_TASK_ATTACHMENT';

export const SET_YEARS = 'SET_YEARS';

export const setCurrentTask = (task = {}) => ({
    type: SET_CURRENT_TASK,
    data: { task }
});

export const setUpdateTask = (data) => ({
    type: REQUEST_UPDATE_TASK_SUCCESS,
    id: data.id,
    data
});

export const addTask = (task) => ({
    type: WEBSOCKET_CREATE_TASK,
    data: { task }
});

export const redirect = () => {
    return (dispatch, getState) => {
        const state = getState();
        return dispatch(replace(`/${state.currentLocation.id}/${state.currentLocation.slug}`));
    };
};

export const deleteTask = (task) => {
    return {
        types: [REQUEST_DELETE_TASK, REQUEST_DELETE_TASK_SUCCESS, REQUEST_DELETE_TASK_ERROR],

        shouldCallAPI: (state) => {
            return (state.tasks.byId[task.id].id === task.id);
        },

        callAPI: () => api.del(task),

        payload: { id: task.id, year: task.year },
    };
};

export const getTask = (id) => {
    return {
        types: [REQUEST_TASK, REQUEST_TASK_SUCCESS, REQUEST_TASK_ERROR],

        shouldCallAPI: (state) => {
            return parseInt(state.currentTask.id) !== parseInt(id, 10);
        },

        callAPI: () => api.get(id),

        payload: {},
    };
};

export const createTask = (data) => {
    return {
        types: [REQUEST_CREATE_TASK, REQUEST_CREATE_TASK_SUCCESS, REQUEST_CREATE_TASK_ERROR],

        shouldCallAPI: (state) => {
            return true;
        },

        callAPI: () => api.add(data),

        payload: {},
    };
};

export const updateTask = (id, data) => {
    return {
        types: [REQUEST_UPDATE_TASK, REQUEST_UPDATE_TASK_SUCCESS, REQUEST_UPDATE_TASK_ERROR],

        shouldCallAPI: (state) => {
            return true;
        },

        callAPI: () => api.put(id, data),

        payload: { ...data },
    };
};

export const openTask = (task, to) => {
    return (dispatch, getState) => {
        dispatch(setCurrentTask(task));

        return dispatch(push(to));
    };
};

export const addTaskAttachment = (task) => ({
    type: WEBSOCKET_CREATE_TASK_ATTACHMENT,
    task
});

export const deleteTaskAttachment = (data) => ({
    type: WEBSOCKET_DELETE_TASK_ATTACHMENT,
    data
});

export const split = (id, years) => {
    return {
        types: [REQUEST_SPLIT_TASK, REQUEST_SPLIT_TASK_SUCCESS, REQUEST_SPLIT_TASK_ERROR],

        shouldCallAPI: (state) => {
            return true;
        },

        callAPI: () => api.split(id, years),

        payload: {},
    };
};

export const downloadExcel = tasks => {
    return {
        types: [REQUEST_DOWNLOAD_TASK_EXCEL, REQUEST_DOWNLOAD_TASK_EXCEL_SUCCESS, REQUEST_DOWNLOAD_TASK_EXCEL_ERROR],

        shouldCallAPI: (state) => {
            return true;
        },

        callAPI: () => api.excel(tasks),

        payload: {},
    };
};

export const splitTask = (data) => {
    return (dispatch, getState) => {
        _.flatMap(data.tasks, x => x).forEach(task => {
            dispatch(addTask(task));
        });
        data.deleted.forEach(id => {
            const task = getState().tasks.byId[id];
            if (task !== undefined) {
                dispatch(requestDeleteSuccess({ id, year: task.year }));
            }
        });
        return dispatch(requestUpdateSuccess({ task: data.parent }));
    };
};

export const requestGetSuccess = task => ({
    type: REQUEST_TASK_SUCCESS,
    data: { task }
});

export const requestDeleteSuccess = data => ({
    type: REQUEST_DELETE_TASK_SUCCESS,
    data
});

export const requestUpdateSuccess = data => ({
    type: REQUEST_UPDATE_TASK_SUCCESS,
    data
});
