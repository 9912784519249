import { REQUEST_USER_SUCCESS, REQUEST_UPDATE_USER_SUCCESS } from "../actions/user";
import { REQUEST_CREATE_ATTACHMENT_SUCCESS } from "../actions/attachments";
import { REQUEST_JOIN_LOCATION_SUCCESS } from "../actions/user";
import { refreshUser } from "../actions/user";

export default (state = {}, action) => {
    switch (action.type) {
    case REQUEST_CREATE_ATTACHMENT_SUCCESS:
        return action.payload.destination === 'users' ? { ...action.data.model } : { ...state };
    case REQUEST_USER_SUCCESS:
    case REQUEST_UPDATE_USER_SUCCESS:
        return { ...state, ...action.data.user };
    case REQUEST_JOIN_LOCATION_SUCCESS:
        action.asyncDispatch(refreshUser());
        return state;
    case 'LOGOUT':
        return {};
    default:
        return { ...state };
    }
};
