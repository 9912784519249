import { REQUEST_SERVICES_SUCCESS } from "../actions/services";

export default (state = [], action) => {
    switch (action.type) {
    case REQUEST_SERVICES_SUCCESS:
        return [...action.data];
    default:
        return state;
    }
};
