import * as api from "../apis/users";

export const TOGGLE_NOTIFICATION_MODAL = 'TOGGLE_NOTIFICATION_MODAL';

export const REQUEST_NOTIFICATION_SEEN = 'REQUEST_NOTIFICATION_SEEN';
export const REQUEST_NOTIFICATION_SEEN_SUCCESS = 'REQUEST_NOTIFICATION_SEEN_SUCCESS';
export const REQUEST_NOTIFICATION_SEEN_ERROR = 'REQUEST_NOTIFICATION_SEEN_ERROR';

export const toggleNotificationsModal = () => ({
    type: TOGGLE_NOTIFICATION_MODAL
});

export const notificationSeen = (id) => {
    return {
        types: [REQUEST_NOTIFICATION_SEEN, REQUEST_NOTIFICATION_SEEN_SUCCESS, REQUEST_NOTIFICATION_SEEN_ERROR],

        shouldCallAPI: (state) => {
            return true;
        },

        callAPI: () => api.notification(id),

        payload: {},
    };
};
