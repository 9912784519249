import { REQUEST_LOCATION_SUCCESS, SET_LOCATION, REQUEST_FETCH_YEAR_SUCCESS, REQUEST_DELETE_TAG_SUCCESS, TAG_CREATED, TAG_DELETED } from "../actions/locations";
import { openTask, splitTask, REQUEST_UPDATE_TASK_SUCCESS, WEBSOCKET_CREATE_TASK, REQUEST_CREATE_TASK_SUCCESS,
    REQUEST_DELETE_TASK_SUCCESS, WEBSOCKET_CREATE_TASK_ATTACHMENT, WEBSOCKET_DELETE_TASK_ATTACHMENT, REQUEST_TASK_SUCCESS, REQUEST_SPLIT_TASK_SUCCESS,
    SET_YEARS } from "../actions/tasks";
import { REQUEST_CREATE_ATTACHMENT_SUCCESS, REQUEST_DELETE_ATTACHMENT_SUCCESS } from "../actions/attachments";
import _ from "lodash";

export default (state = { byId: {} }, action) => {
    switch (action.type) {
    case REQUEST_LOCATION_SUCCESS:
    case REQUEST_DELETE_TAG_SUCCESS:
    case SET_LOCATION:
        return {
            ...{ byId: _.keyBy(_.flatMap(action.data.location.tasks, x => x), task => task.id) }
        };
    case SET_YEARS:
        return { ...state, ...{ byId: { ...state.byId, ..._.keyBy(_.flatMap(action.data, x => x).map(t => ({ ...state.byId[t.id], ...t })), task => task.id) } } };
    case WEBSOCKET_DELETE_TASK_ATTACHMENT:
        return {
            ...state,
            ...{
                byId: {
                    ...state.byId,
                    ...{ [action.data.task_id]: {
                        ...state.byId[action.data.task_id],
                        ...{
                            attachments: [...state.byId[action.data.task_id].attachments.filter(attachment => attachment.id !== action.data.attachment_id)],
                            thumbnail: action.data.thumbnail
                        } }
                    }
                }
            }
        };
    case REQUEST_CREATE_ATTACHMENT_SUCCESS:
    case REQUEST_DELETE_ATTACHMENT_SUCCESS:
        return action.payload.destination === 'tasks' ? {
            ...state,
            ...{
                byId: {
                    ...state.byId,
                    ...{ [action.data.model.id]: { ...state.byId[action.data.model.id], ...action.data.model } }
                }
            }
        } : { ...state };
    case REQUEST_UPDATE_TASK_SUCCESS:
        const id = action.id || action.data.task.id;
        const data = action.data.task || action.data;
        return {
            ...state,
            ...{
                byId: {
                    ...state.byId,
                    ...{ [id]: { ...state.byId[id], ...data } }
                }
            }
        };
    case WEBSOCKET_CREATE_TASK_ATTACHMENT:
        return {
            ...state,
            ...{
                byId: {
                    ...state.byId,
                    ...{ [action.task.id]: { ...state.byId[action.task.id], attachments: [...state.byId[action.task.id].attachments, ...action.task.attachments] } }
                }
            }
        };
    case REQUEST_TASK_SUCCESS:
    case REQUEST_CREATE_TASK_SUCCESS:
        action.asyncDispatch(openTask(action.data.task, {
            pathname: window.location.pathname.replace('new', action.data.task.id +'/'+action.data.task.slug),
            state: { modal: true, title: action.data.task.name }
        }));
        return {
            ...state,
            ...{
                byId: {
                    ...state.byId,
                    ...{ [action.data.task.id]: { ...action.data.task } }
                }
            }
        };
    case WEBSOCKET_CREATE_TASK:
        return {
            ...state,
            ...{
                byId: {
                    ...state.byId,
                    ...{ [action.data.task.id]: { ...action.data.task } }
                }
            }
        };
    case REQUEST_DELETE_TASK_SUCCESS:
        const task = action.data.id ? action.data : action.payload;
        const tasks = { ...state.byId };
        delete tasks[task.id];
        return {
            ...state,
            ...{
                byId: {
                    ...tasks
                }
            }
        };
    case REQUEST_FETCH_YEAR_SUCCESS:
        return { ...state,
            ...{
                byId: {
                    ...state.byId,
                    ..._.keyBy(_.flatMap(action.data.tasks, x => x), task => task.id)
                }
            }
        };
    case TAG_DELETED:
        if (action.data.model === 'Task' && action.data.modelId === state.byId[action.data.modelId].id) {
            const tags = { ...state.byId[action.data.modelId].tags };
            const parts = action.data.tag.group.split('.');
            if (parts[1] === undefined) {
                tags[parts[0]] = tags[parts[0]].filter(t => t.id !== action.data.tag.id);
            }
            else {
                tags[parts[0]][parts[1]] = tags[parts[0]][parts[1]].filter(t => t.id !== action.data.tag.id);
            }

            return {
                ...state,
                byId: {
                    ...state.byId,
                    ...{ [action.data.modelId]: { ...state.byId[action.data.modelId], tags } },
                }
            };
        }
        return { ...state };
    case TAG_CREATED:
        if (action.data.model === 'Task' && state.byId[action.data.modelId] !== undefined) {
            const tags = { ...state.byId[action.data.modelId].tags };
            const parts = action.data.tag.group.split('.');
            if (parts[1] === undefined) {
                const rest = tags[parts[0]] || [];
                tags[parts[0]] = [ ...rest, action.data.tag ];
            }
            else {
                const rest = tags[parts[0]][parts[1]] || [];
                tags[parts[0]][parts[1]] = [ ...rest, action.data.tag ];
            }

            return {
                ...state,
                byId: {
                    ...state.byId,
                    ...{ [action.data.modelId]: { ...state.byId[action.data.modelId], tags } }
                }
            };
        }
        return { ...state };
    case 'LOGOUT':
    case 'CLEAR_CURRENT_LOCATION':
    case 'MAINTENANCE_MODE':
        if (action.type === 'MAINTENANCE_MODE' && !action.data.mode) {
            return { ...state };
        }
        return { byId: {} };
    case REQUEST_SPLIT_TASK_SUCCESS:
        action.asyncDispatch(splitTask(action.data));
        return { ...state };
    default:
        return { ...state };
    }
};
