import React, { Component } from "react";

export class Notification extends Component {
    constructor(props) {
        super(props);

        this.state = {
            type: this.props.type || 'success',
        };
    }

    render() {
        return <div className="custom-notification position-relative d-flex align-items-end">
            <div className={`message d-flex align-items-center w-100 ${this.state.type}`}>
                {this.props.msg}
            </div>
        </div>;
    }
}

export default Notification;
