import { getInvitation } from "../../actions/invitations";
import Notification from "../../components/Notification";
import React, { Component } from "react";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import PropTypes from "prop-types";

const trans = {
    token: 'Aloituskoodi',
};

export class Invite extends Component {

    constructor(props) {
        super(props);

        this.state = {
            errors: {},
            done: false,
        };
    }

    validateInvite() {
        if (this.props.invitation.key !== undefined) {
            if (this.props.invitation.target !== 2) {
                this.props.history.push({ pathname: `/start/rs15`, state: { modal: false } });
                toast.error(<Notification type="error" msg="Aloituskoodi ei ole kuntoarvio aloituskoodi"/>);
                return false;
            }
            if (this.props.invitation.amount >= this.props.invitation.max) {
                this.props.history.push({ pathname: `/start/rs15`, state: { modal: false } });
                toast.error(<Notification type="error" msg="Antamasi koodi on jo käytetty. Pyydä tilin pääkäyttäjää kutsumaan sinut Kiinteistökartturiin tai ole yhteydessä Susteran asiakaspalveluun"/>);
                return false;
            }

            return true;
        }
    }

    async componentDidMount() {
        if (this.props.token !== undefined) {
            this.done(this.props.token);
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (!prevProps.auth.isLoggedIn && this.props.auth.isLoggedIn && this.prop.token !== undefined && !this.state.done) {
            this.redirectOrDone();
        }
    }

    async done(_token = undefined) {
        const token = _token || this.refs.token.value;
        if (token === undefined || token === '') {
            toast.error(<Notification type="error" msg="Aloituskoodi puuttuu"/>);
            return;
        }
        await this.props.getInvitation(token);
        if (this.props.user.locationRoles[this.props.invitation.location_id] !== undefined) {
            this.props.done();
        } else {
            if (this.validateInvite()) {
                this.redirectOrDone();
            }
        }
    }

    redirectOrDone() {
        if (this.props.user.locationRoles[this.props.invitation.location_id] === undefined) {
            this.props.history.push({ pathname: `/invitation/${this.props.invitation.key}`, state: { redirect: '/start/rs15/1/' + this.props.invitation.key } });
        } else {
            this.props.done();
        }
    }

    render() {
        return (
            <div className="">
                <h1>Kertakäyttöinen aloituskoodi</h1>

                <div className="d-flex flex-column mt-2">
                    <input className={'form-control rounded-0 border-primary mt-2' + (this.state.errors.token !== undefined ? ' error' : '')} type="text" ref="token" placeholder={trans.token}/>
                </div>


                <div className="d-flex mt-5 align-items-start">
                    <div className="col-6 text-xs pl-0">
                        {
                            this.props.history.location && this.props.history.location.state !== undefined && this.props.history.location.state.origin !== undefined &&
                            <button className="col-12 btn btn-primary mb-3" id={"steps-" + window.location.pathname.split('/')[2] + "-invite-back-button"} onClick={() => this.props.history.goBack()}>Takaisin</button>
                        }
                        <p>Tämä on tarkoitettu Kuntoarvio aloituskoodille.</p>
                    </div>
                    <div className="col-6 text-xs pl-0">
                        <button className="col-12 btn btn-primary ml-2 mb-3" id={"steps-" + window.location.pathname.split('/')[2] + "-invite-next-button"} onClick={() => this.done()}>Seuraava</button>
                        <h6>Ongelmia aloituskoodin kanssa?</h6>
                        <small>Ota yhteyttä tuki@kiinteistokartturi.fi tai Susteran asiakaspalveluun. Kirjoita viestiin ainakin kohteesi osoite mitä yrität ottaa käyttöön.</small>
                    </div>
                </div>
            </div>
        );
    }
}

Invite.propTypes = {
    token: PropTypes.string,
    history: PropTypes.object.isRequired,
};

const mapStateToProps = ({ invitation, user }) => ({ invitation, user });

const mapDispatchToProps = (dispatch) => ({
    getInvitation: (token) => dispatch(getInvitation(token)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Invite);
