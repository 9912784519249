import DropzoneComponent from "../components/DropzoneComponent";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";
import ContentEditable from "react-contenteditable";
import Loading from "../components/Loading";
import Sidebar from "../components/Sidebar";
import React, { Component } from "react";
import { catchEnter } from "../utils";
import PropTypes from "prop-types";

export class LocationPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            fields: {}
        };
    }

    componentDidMount() {
        this.props.setState({
            selected_city: [this.props.location.zipcode.name],
            selected_zip: [this.props.location.zipcode.zip],
            query_zip: this.props.location.zipcode.zip
        });
    }

    editLocation(e, field) {
        e.persist();
        let value;
        if (field === 'area') {
            value = e.target.innerText.replace(',','.');
        } else {
            value = e.target.innerText;
        }
        if ("" + value !== "" + (this.props.location[field] || "")) {
            this.setState({ fields: { ...this.state.fields, [field]: { value, status: 'pending' } } });
            this.props.save({ id: this.props.match.params.id, [field]: value });
        } else {
            const fields = { ...this.state.fields };
            delete fields[field];
            this.setState({ fields });
        }
    }
    hasImage() {
        if (this.props.location.attachments.length > 0) {
            return <div className="card-img" style={{ height: '100%', backgroundImage: `url(${(this.props.location.attachments ? this.props.location.attachments[0].protected_paths.medium : this.props.location.thumbnail)})` }} />;
        } else {
            return null;
        }
    }
    inputField(name, field, placeholder = null) {
        return <div className={"d-flex border-bottom px-2 py-1 my-0 mb-2 field-input"}>
            <p className="text-left mb-0" style={{ marginBottom: '-1rem' }}>{name}:</p>
            <ContentEditable tagName="p" html={this.state.fields[field] ? this.state.fields[field].value : "" + (this.props.location[field] || "")} placeholder={placeholder || name} onKeyDown={(e) => catchEnter(e)} onBlur={(e) => this.editLocation(e, field)} className="ml-auto text-right contenteditable pl-4 mr-3 mb-0" disabled={false}/>
        </div>;
    }
    blurZip(e, field) {
        if (!this.props.state.zip_options[0])
            return;
        this.props.setState({ selected_zip: [this.props.state.zip_options[0]], query_zip: this.props.state.zip_options[0] });
        const value = e.target.value;
        if (value !== this.props.location.zipcode.zip) {
            this.props.save({ zipcode_id: this.props.state.zipcode_id });
        }
    }
    render() {
        return (
            <div className="Page w-100 h-100 d-flex flex-md-row flex-column align-self-center">
                <Loading isLoading={this.props.isLoading} />
                <Sidebar location={this.props.location} />
                <div className="d-flex flex-column mt-5 align-items-start right col-md-9 col-12">
                    <h2>{this.props.location.name}</h2>
                    <div className="d-flex flex-lg-row flex-column">
                        <div className="d-flex flex-column order-lg-1 order-2" style={{ maxHeight: 'fit-content' }}>
                            {this.inputField('Kohteen nimi', 'name')}
                            {this.inputField('Osoite', 'address')}
                            <div className="d-flex flex-column px-2 py-1 my-0 mb-2 border-bottom">
                                <div className="d-flex align-items-baseline">
                                    <p className="flex-grow-1 text-left mb-0">Postitoimipaikka:</p>
                                    <p className="ml-auto text-right contenteditable pl-4 mr-3 mb-0 text-secondary">{this.props.state.zip_name_options[0] || this.props.state.selected_city}</p>
                                    <AsyncTypeahead
                                        id="zipCode-typehead"
                                        className="text-right"
                                        isLoading={false}
                                        onChange={selected_zip => this.props.setState({ selected_zip, query_zip: selected_zip[0] })}
                                        selected={this.props.state.selected_zip || null}
                                        onSearch={(query) => { this.props.setState({ query_zip: query }); this.props.onSearch(query); }}
                                        options={this.props.state.zip_options}
                                        useCache={false}
                                        promptText="Etsi..."
                                        onKeyDown={(e) => catchEnter(e)}
                                        onBlur={(e) => this.blurZip(e, 'zip')}
                                    />
                                </div>
                            </div>
                            {this.inputField('Y-tunnus', 'vat_number')}
                            {this.inputField('Kiinteistötunnus', 'property_id')}
                            {this.inputField('Rakennustyyppi', 'type')}
                            {this.inputField('Rakennuksia', 'building_count', '0')}
                            {this.inputField('Huoneistoala', 'area', '0')}
                            {this.inputField('Asuinhuoneistoja', 'apt_count', '0')}
                            {this.inputField('Liikehuoneistoja', 'com_count', '0')}
                        </div>
                        <div className="col-md-6 col-12 order-lg-2 order-1 mb-2" style={{ maxHeight: 'fit-content' }}>
                            <DropzoneComponent
                                onDropAccepted={this.props.saveAttachment}
                                accept=".jpeg,.png,.jpg"
                                className="w-100"
                                inner={this.hasImage()}
                                outerStyle={{ width: '50vw', right: '-15px' }}
                                style={{ width: '50%', maxWidth: '300px' }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

LocationPage.propTypes = {
    location: PropTypes.object.isRequired,
    setState: PropTypes.func.isRequired,
    onSearch: PropTypes.func.isRequired,
    match: PropTypes.object.isRequired,
    save: PropTypes.func.isRequired,
};

export default LocationPage;
