import * as api from "../apis/locations";

export const REQUEST_CREATE_LOCATIONS = 'REQUEST_CREATE_LOCATIONS';
export const REQUEST_CREATE_LOCATIONS_SUCCESS = 'REQUEST_CREATE_LOCATIONS_SUCCESS';
export const REQUEST_CREATE_LOCATIONS_ERROR = 'REQUEST_CREATE_LOCATIONS_ERROR';

export const REQUEST_LOCATIONS = 'REQUEST_LOCATIONS';
export const REQUEST_LOCATIONS_SUCCESS = 'REQUEST_LOCATIONS_SUCCESS';
export const REQUEST_LOCATIONS_ERROR = 'REQUEST_LOCATIONS_ERROR';

export const REQUEST_LOCATION = 'REQUEST_LOCATION';
export const REQUEST_LOCATION_SUCCESS = 'REQUEST_LOCATION_SUCCESS';
export const REQUEST_LOCATION_ERROR = 'REQUEST_LOCATION_ERROR';

export const REQUEST_UPDATE_LOCATION = 'REQUEST_UPDATE_LOCATION';
export const REQUEST_UPDATE_LOCATION_SUCCESS = 'REQUEST_UPDATE_LOCATION_SUCCESS';
export const REQUEST_UPDATE_LOCATION_ERROR = 'REQUEST_UPDATE_LOCATION_ERROR';

export const REQUEST_FETCH_YEAR = 'REQUEST_FETCH_YEAR';
export const REQUEST_FETCH_YEAR_SUCCESS = 'REQUEST_FETCH_YEAR_SUCCESS';
export const REQUEST_FETCH_YEAR_ERROR = 'REQUEST_FETCH_YEAR_ERROR';

export const REQUEST_FETCH_PLANS = 'REQUEST_FETCH_PLANS';
export const REQUEST_FETCH_PLANS_SUCCESS = 'REQUEST_FETCH_PLANS_SUCCESS';
export const REQUEST_FETCH_PLANS_ERROR = 'REQUEST_FETCH_PLANS_ERROR';

export const REQUEST_CREATE_ORDER = 'REQUEST_CREATE_ORDER';
export const REQUEST_CREATE_ORDER_SUCCESS = 'REQUEST_CREATE_ORDER_SUCCESS';
export const REQUEST_CREATE_ORDER_ERROR = 'REQUEST_CREATE_ORDER_ERROR';

export const REQUEST_EXPORT_BUDGET = 'REQUEST_EXPORT_BUDGET';
export const REQUEST_EXPORT_BUDGET_SUCCESS = 'REQUEST_EXPORT_BUDGET_SUCCESS';
export const REQUEST_EXPORT_BUDGET_ERROR = 'REQUEST_EXPORT_BUDGET_ERROR';

export const REQUEST_EXPORT_LONG_TERM_PLAN_EXCEL = 'REQUEST_EXPORT_LONG_TERM_PLAN_EXCEL';
export const REQUEST_EXPORT_LONG_TERM_PLAN_EXCEL_SUCCESS = 'REQUEST_EXPORT_LONG_TERM_PLAN_EXCEL_SUCCESS';
export const REQUEST_EXPORT_LONG_TERM_PLAN_EXCEL_ERROR = 'REQUEST_EXPORT_LONG_TERM_PLAN_EXCEL_ERROR';

export const WEBSOCKET_UPDATE_LOCATION = 'WEBSOCKET_UPDATE_LOCATION';
export const WEBSOCKET_CREATE_LOCATION_ATTACHMENT = 'WEBSOCKET_CREATE_LOCATION_ATTACHMENT';

export const REQUEST_DELETE_TAG = 'REQUEST_DELETE_TAG';
export const REQUEST_DELETE_TAG_SUCCESS = 'REQUEST_DELETE_TAG_SUCCESS';
export const REQUEST_DELETE_TAG_ERROR = 'REQUEST_DELETE_TAG_ERROR';

export const TAG_DELETED = 'TAG_DELETED';
export const TAG_CREATED = 'TAG_CREATED';

export const SET_LOCATION = 'SET_LOCATION';

export const REMOVE_USER = 'REMOVE_USER';
export const JOINED_USER = 'JOINED_USER';
export const UPDATE_USER = 'UPDATE_USER';

export const removeUser = (data) => ({
    type: REMOVE_USER,
    data: data,
});

export const joinedUser = (data) => ({
    type: JOINED_USER,
    data: data,
});

export const updateUser = (data) => ({
    type: UPDATE_USER,
    data: data,
});

export const setLocation = (location) => ({
    type: SET_LOCATION,
    data: { location },
});

export const createLocation = (data) => {
    return {
        types: [REQUEST_CREATE_LOCATIONS, REQUEST_CREATE_LOCATIONS_SUCCESS, REQUEST_CREATE_LOCATIONS_ERROR],

        shouldCallAPI: (state) => {
            return !state.loading.locations;
        },

        callAPI: (state) => api.add(data),

        payload: {},
    };
};

export const getLocations = (page = null) => {
    return {
        types: [REQUEST_LOCATIONS, REQUEST_LOCATIONS_SUCCESS, REQUEST_LOCATIONS_ERROR],

        shouldCallAPI: (state) => {
            return !state.loading.locations;
        },

        callAPI: (state) => (page === null ? api.get() : api.page(page)),

        payload: {},
    };
};

export const getLocation = (id) => {
    return {
        types: [REQUEST_LOCATION, REQUEST_LOCATION_SUCCESS, REQUEST_LOCATION_ERROR],

        shouldCallAPI: (state) => {
            return (!state.currentLocation.tasks || parseInt(state.currentLocation.id) !== parseInt(id, 10));
        },

        callAPI: (state) => api.get(id),

        payload: {},
    };
};

export const refreshLocation = (id) => {
    return {
        types: [REQUEST_LOCATION, REQUEST_LOCATION_SUCCESS, REQUEST_LOCATION_ERROR],

        shouldCallAPI: (state) => {
            return true;
        },

        callAPI: (state) => api.get(id),

        payload: {},
    };
};

export const updateLocation = (location) => ({
    type: WEBSOCKET_UPDATE_LOCATION,
    data: { location },
});

export const editLocation = (location) => {
    return {
        types: [REQUEST_UPDATE_LOCATION, REQUEST_UPDATE_LOCATION_SUCCESS, REQUEST_UPDATE_LOCATION_ERROR],

        shouldCallAPI: (state) => {
            return true;
        },

        callAPI: (state) => api.put(location),

        payload: {},
    };
};

export const searchZipcodes = (data) => {
    return dispatch => {
        return api.zipcodesSearch(data);
    };
};

export const addLocationAttachment = (location) => ({
    type: WEBSOCKET_CREATE_LOCATION_ATTACHMENT,
    data: { location },
});

export const fetchYear = (year, location_id) => {
    return {
        types: [REQUEST_FETCH_YEAR, REQUEST_FETCH_YEAR_SUCCESS, REQUEST_FETCH_YEAR_ERROR],

        shouldCallAPI: (state) => {
            return state.years[year] === undefined;
        },

        callAPI: (state) => api.year(year, location_id),

        payload: {},
    };
};

export const exportBudget = (chart, headings) => {
    return {
        types: [REQUEST_EXPORT_BUDGET, REQUEST_EXPORT_BUDGET_SUCCESS, REQUEST_EXPORT_BUDGET_ERROR],

        shouldCallAPI: (state) => {
            return chart.length === 6;
        },

        callAPI: (state) => api.excel(chart, headings),

        payload: {},
    };
};

export const exportLongTermPlanExcel = (locations) => {
    return {
        types: [REQUEST_EXPORT_LONG_TERM_PLAN_EXCEL, REQUEST_EXPORT_LONG_TERM_PLAN_EXCEL_SUCCESS, REQUEST_EXPORT_LONG_TERM_PLAN_EXCEL_ERROR],

        shouldCallAPI: (state) => {
            return true;
        },

        callAPI: () => api.longTermPlanExcel(locations),

        payload: {},
    };
};

export const deleteTag = (location, tag) => {
    return {
        types: [REQUEST_DELETE_TAG, REQUEST_DELETE_TAG_SUCCESS, REQUEST_DELETE_TAG_ERROR],

        shouldCallAPI: (state) => {
            return true;
        },

        callAPI: (state) => api.deleteTag(location.id, tag.id),

        payload: {},
    };
};
