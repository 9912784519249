import { REQUEST_FAQ_SUCCESS } from "../actions/user";

export default (state = [], action) => {
    switch (action.type) {
    case REQUEST_FAQ_SUCCESS:
        return [...action.data.faqs];
    case 'LOGOUT':
        return [];
    default:
        return state;
    }
};
