import DropzoneComponent from "../components/DropzoneComponent";
import ContentEditable from "react-contenteditable";
import Sidebar from "../components/Sidebar";
import Circle from "../components/Circle";
import Notification from "./Notification";
import React, { Component } from "react";
import { toast } from "react-toastify";
import { catchEnter } from "../utils";
import { Plus } from "../svg/icons";
import PropTypes from "prop-types";

export class UserPage extends Component {
    hasImage() {
        if (this.props.user.attachments.length > 0) {
            return <Circle type="outline" size="large" className="d-flex" style={{ overflow: 'hidden' }}>
                <img alt="/kuva" src={this.props.user.avatar.small} />
            </Circle>;
        } else {
            return  <Circle type="outline" size="large" className="d-flex">
                <Plus className="icon-f ml-auto mb-4 mr-2" />
                <span className="w-50 text-left">Lisää kuva</span>
            </Circle>;
        }
    }
    editUser(e, key) {
        e.persist();
        if (!e.target.innerText) {
            e.target.innerText = this.props.state[key];
            return toast.error(<Notification type="error" msg={e.target.attributes.placeholder.nodeValue + ' ei voi olla tyhjä'} />);
        }
        if (e.target.innerText !== this.props.state[key]) {
            this.props.save({ [key]: e.target.innerText });
        }
    }

    render() {
        if (this.props.user.id === undefined) {
            return null;
        }
        return (
            <div className="Page w-100 h-100 d-flex flex-md-row flex-column align-self-center">
                <Sidebar location={this.props.currentLocation} />
                <div className="d-flex flex-column mt-5 align-items-start right col-md-6 col-12">
                    <h2>Omat tiedot</h2>
                    <div className="w-100 mb-4 d-flex flex-row justify-content-between">
                        <div className="mt-4">
                            <DropzoneComponent
                                onDropAccepted={this.props.saveAttachment}
                                className="user-image"
                                accept=".jpeg,.png,.jpg"
                                outerStyle={{ width: '105px', height: '105px' }}
                                style={{ border: '0', borderRadius: '105px', width: '100%', height: '100%' }}
                                inner={this.hasImage()}
                            />
                        </div>
                    </div>
                    <div className="w-100">
                        <div className="d-flex" style={{ marginBottom: '-1em' }}>
                            <p className="flex-grow-1 text-left">Nimi:</p>
                            <div className="d-flex pr-3">
                                <ContentEditable tagName="p" html={this.props.state.firstname} placeholder="Etunimi" className="contenteditable pr-1" onKeyDown={(e) => catchEnter(e)} onBlur={(e) => this.editUser(e, 'firstname')} disabled={false}/>
                                <ContentEditable tagName="p" html={this.props.state.lastname} placeholder="Sukunimi" className="contenteditable" onKeyDown={(e) => catchEnter(e)} onBlur={(e) => this.editUser(e, 'lastname')} disabled={false}/>
                            </div>
                        </div>
                        <hr/>
                        <div className="d-flex" style={{ marginBottom: '-1em' }}>
                            <p className="flex-grow-1 text-left">Sähköposti:</p>
                            <ContentEditable tagName="p" html={this.props.state.email} placeholder="Sähköposti" className="contenteditable pr-3" onKeyDown={(e) => catchEnter(e)} onBlur={(e) => this.editUser(e, 'email')} disabled={false}/>
                        </div>
                        <hr/>
                    </div>
                </div>
            </div>
        );
    }
}

UserPage.propTypes = {
    state: PropTypes.object.isRequired,
    currentLocation: PropTypes.object,
    save: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired,
};

export default UserPage;
