import React, { Component } from "react";
import UserAvatar from "./UserAvatar";
import { Check } from "../svg/icons";
import PropTypes from "prop-types";
import Circle from "./Circle";
import moment from "moment";


class Task extends Component {
    render() {
        if (this.props.task.parent_id) {
            return this.renderChild();
        }
        return <div className="card text-white d-flex rounded-0 border-0 mt-3" onClick={() => this.props.open(this.props.task)}>
            {this.props.showImages && <div className="card-img rounded-0 align-self-center" style={{ backgroundSize: (this.props.task.thumbnail.match('default_task.svg') ? 'contain' : 'cover'), backgroundImage: `url(${(this.props.task.thumbnail || '')})` }}/>}
            {!this.props.showImages && <div className="card-img rounded-0" style={{ height: '79px', backgroundColor: 'grey' }} />}
            <div className="card-img-overlay d-flex flex-column justify-content-between p-1 text-left mb-5">
                {this.props.task.completed ? <div className="completed position-absolute d-flex align-items-center justify-content-center"><span className="d-flex align-items-center justify-content-center"><Check className="icon-f-inv"/></span></div> : ''}
                <h6 style={{ color: 'white', overflow: 'hidden' }} className="font-weight-bold">{this.props.task.name}</h6>
                <div className="d-flex justify-content-between">
                    <small className="card-text">{moment(this.props.task.created_at).format('D.M.YYYY')}</small>
                    <small className="card-text">{this.props.task.children_count ? `1/${this.props.task.children_count + 1}` : ''}</small>
                </div>
            </div>
            <div className="card-footer p-1 border-bottom d-flex font-weight-bold align-items-center">
                <span style={{ color: '#929292' }} className="d-flex align-items-center">
                    <Circle type="outline" size="small" style={{ fontSize: '1.75rem', overflow: 'hidden' }}>
                        {this.props.task.user.avatar ?
                            <img src={this.props.task.user.avatar.tiny} alt=""/>
                            :
                            <UserAvatar user={this.props.task.user}/>
                        }
                    </Circle>
                    {(this.props.task.comment_count) > 0 ? <span className="ml-2">+ {this.props.task.comment_count} kommenttia</span> : ''}
                </span>
                <span className="ml-auto text-primary">{this.props.task.price_text}</span>
            </div>
        </div>;
    }

    renderChild() {
        return <div className="card task-child text-white d-flex rounded-0 border-0 mt-3" onClick={() => this.props.open({ id: this.props.task.parent_id, slug: this.props.task.slug, name: this.props.task.name })}>
            <div className="card-footer p-1 pl-3 border-bottom d-flex flex-column font-weight-bold align-items-start">
                <div className="child-header d-flex justify-content-between px-2 w-100">
                    <p>jyvitys</p>
                    <p>{this.props.task.nth_child}</p>
                </div>
                <div className="w-100 px-2 pt-1 text-left">
                    <h6 className="text-primary font-weight-bold">{this.props.task.name}</h6>
                    <span className="ml-auto text-primary">{this.props.task.price}€ / vuosi</span>
                </div>
            </div>
        </div>;
    }
}

Task.propTypes = {
    task: PropTypes.object.isRequired,
    open: PropTypes.func.isRequired,
};

export default Task;
