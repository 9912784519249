import { setUpdateTask, addTask, requestDeleteSuccess, addTaskAttachment, deleteTaskAttachment, SET_YEARS } from "../actions/tasks";
import { updateLocation, addLocationAttachment, removeUser, updateUser, refreshLocation } from "../actions/locations";
import { currentUsers, joiningUser, leavingUser } from "../actions/users";
import Notification from "../components/Notification";
import { addComment } from "../actions/comments";
import { move } from "../actions/sorting";
import { MOVE } from "../actions/sorting";
import { toast } from "react-toastify";
import Echo from "laravel-echo";
import axios from "axios";
import React from "react";

window.pusher = require('pusher-js');
window.io = {};
let location = {};
let locations = [];

const WebsocketMiddleware = store => next => action => {
    next(action);

    // eslint-disable-next-line
    if (typeof window.pusher !== undefined && store.getState().auth.token !== null && window.Echo === undefined) {
        window.Echo = new Echo({
            broadcaster: 'pusher',
            key: process.env.REACT_APP_SOCKET_KEY,
            cluster: 'eu',
            forceTLS: true,
            authEndpoint: process.env.REACT_APP_API_URL + '/api/broadcasting/auth',
            auth: { headers: { Authorization: "Bearer " + store.getState().auth.token } }
        });
    }

    if (window.Echo !== undefined) {
        axios.defaults.headers.common['X-Socket-ID'] = window.Echo.socketId();

        if (store.getState().currentLocation.id === undefined && location.id !== undefined) {
            window.Echo.leave('location.'+location.id);
            window.Echo.leave('location.'+location.id+'.users');
            location = {};
        }

        // Listen to current
        if (store.getState().currentLocation.id !== location.id && store.getState().currentLocation.id !== undefined ) {
            if (location !== undefined) {
                window.Echo.leave('location.'+location.id);
                window.Echo.leave('location.'+location.id+'.users');
            }
            location = store.getState().currentLocation;


            window.Echo.join('location.'+location.id+'.users')
                .here((users) => {
                    store.dispatch(currentUsers(users));
                })
                .joining((user) => {
                    store.dispatch(joiningUser(user));
                })
                .leaving((user) => {
                    store.dispatch(leavingUser(user));
                });

            // Listen to private
            window.Echo.private('location.'+location.id)
                .listen('.Task.order', (data) => { // 206kb => 1kb OK?
                    store.dispatch({ type: SET_YEARS, data: data.data });
                })
                .listen('.Task.Tag.created', (data) => store.dispatch({ type: 'TAG_CREATED', data }))
                .listen('.Location.Tag.created', (data) => store.dispatch({ type: 'TAG_CREATED', data }))
                .listen('.Location.Tag.deleted', (data) => store.dispatch({ type: 'TAG_DELETED', data }))
                .listen('.Task.Tag.deleted', (data) => store.dispatch({ type: 'TAG_DELETED', data }))
                .listen('.Task.updated', (data) => { // 124 bytes
                    if (data.data.year) {
                        const task = store.getState().tasks.byId[data.data.id];
                        const params = {
                            year: task.year,
                            startIndex: task.order_number - 1,
                            index: (data.data.order_number || task.order_number) - 1,
                            toYear: data.data.year
                        };
                        store.dispatch(move(MOVE, store.getState().years, params));
                    } else {
                        store.dispatch(setUpdateTask(data.data));
                    }
                })
                .listen('.User.joined', (data) => {
                    store.dispatch(refreshLocation(data.data.location));
                })
                .listen('.User.updated', (data) => {
                    store.dispatch(updateUser(data.data));
                })
                .listen('.Task.created', (data) => { // 65.6kb OK
                    store.dispatch(addTask(data.task));
                })
                .listen('.Task.deleted', (data) => { // 644bytes OK
                    if (store.getState().years[data.task.year]) {
                        store.dispatch(requestDeleteSuccess(data.task));
                    }
                })
                .listen('.Task.attachment', (data) => { // 2kb OK
                    store.dispatch(addTaskAttachment(data.data));
                })
                .listen('.Task.attachment.deleted', (data) => { // 163bytes OK
                    store.dispatch(deleteTaskAttachment(data.data));
                })
                .listen('.Comment.created', (data) => { // 2kb OK
                    if (store.getState().comments[data.comment.subject_id] !== undefined) {
                        store.dispatch(addComment(data.comment));
                    }
                });

        }

        if (store.getState().locations.all !== locations && store.getState().locations.all.length !== locations.length ) {
            if (locations.length <= 0) {
                locations.forEach(id => window.Echo.leave('locations.'+id));
                locations = store.getState().locations.all;
            }

            locations.forEach((id) => {
                window.Echo.private('locations.'+id)
                    .listen('.Location.updated', (data) => {
                        store.dispatch(updateLocation(data.location));
                    })
                    .listen('.Location.attachment', (data) => {
                        store.dispatch(addLocationAttachment(data.location));
                    })
                    .listen('.User.removed', (data) => {
                        if (data.data.user === store.getState().user.id) {
                            toast.error(<Notification type="error" msg={'Sinut on poistettu kohteesta ' + store.getState().locations.byId[data.data.location].name} />);
                            data.data.removeSelf = true;
                        }
                        store.dispatch(removeUser(data.data));
                    });
            });
        }
    }
};

export default WebsocketMiddleware;
