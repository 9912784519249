import { SET_INVITATION, REMOVE_INVITATION, REQUEST_VALIDATE_INVITATION_SUCCESS, REQUEST_VALIDATE_INVITATION_ERROR, REQUEST_INVITATION_SUCCESS } from "../actions/invitations";
import { REQUEST_JOIN_LOCATION_SUCCESS, REQUEST_JOIN_LOCATION_ERROR } from "../actions/user";

export default (state = {}, action) => {
    switch (action.type) {
    case REQUEST_VALIDATE_INVITATION_SUCCESS:
        return { ...state, ...{ key: action.data.invitation.key, valid: true, target: action.data.target } };
    case REQUEST_VALIDATE_INVITATION_ERROR:
        return { ...state, ...{ valid: false } };
    case SET_INVITATION:
        return { ...action.invitation };
    case REQUEST_INVITATION_SUCCESS:
        return { ...action.data.invitation, target: action.data.target };
    case REQUEST_JOIN_LOCATION_SUCCESS:
        return { done: true };
    case REQUEST_JOIN_LOCATION_ERROR:
    case REMOVE_INVITATION:
    case 'LOGOUT':
        return {};
    default:
        return { ...state };
    }
};
