import { getLocation, fetchYear, exportBudget } from "../actions/locations";
import { Cog, ChevronLeft, ChevronRight, Download } from "../svg/icons";
import HighchartsReact from "highcharts-react-official";
import Loading from "../components/Loading";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import Highcharts from "highcharts";

/* eslint no-extend-native: [0, { "exceptions": ["Object"] }]*/
Number.prototype.format = function(n, x) {
    const re = '\\d(?=(\\d{' + (x || 3) + '})+' + (n > 0 ? '\\.' : '$') + ')';
    return this.toFixed(Math.max(0, ~~n)).replace(new RegExp(re, 'g'), '$& ');
};

let area;

export class ChartContainer extends Component {

    constructor(props) {
        super(props);

        const date = new Date();
        this.max = 10;
        this.year = date.getFullYear();
        this.default = false;
        this.state = {
            year: date.getFullYear(),
            key: 0
        };

    }

    componentWillUnmount(){
        window.removeEventListener('resize', this.handleResize);
    }

    handleResize = () => {
        this.setState({ key: Math.floor(Math.random()*100) });
    };

    componentDidMount() {
        window.addEventListener('resize', this.handleResize);
        this.props.getLocation(this.props.match.params.id);
    }

    export() {
        this.props.exportBudget(this.getOptions().series.map(col => ({
            name: col.name,
            data: col.data.map(v => (col.name !== 'Vastike' ? (v / (this.props.currentLocation.area / 1000) / 12).format(2) : v))
        })), this.formCategories());
    }

    formCategories() {
        return Array.from({ length: this.props.filter.offset }, (v, i) => this.state.year + i);
    }

    formValues() {
        const columns = Array.from(this.props.currentLocation.attributes || [], (attribute) => {
            return {
                type: 'column',
                name: attribute.header,
                data: Array.from({ length: this.props.filter.offset },
                    (v,i) => attribute.fields.reduce((a,b) => {
                        let value = b.value;
                        const toYear = (this.state.year + i) - (b.year || this.year);
                        for (let year = 0; year < toYear; year++) {
                            value = value * (1 + (b.increment / 100));
                        }
                        if (b.default) {
                            this.default = true;
                        }
                        return a + Math.round(value);
                    }, 0) / 1000),
                stack: attribute.isIncome ? 'income' : 'expenses',
            };
        });
        columns.push({
            type: 'column',
            name: 'Toimenpiteet',
            color: '#6f98ab',
            data: Array.from(this.formCategories(), (v) => Object.values(this.props.years[v] || []).map(id => this.props.tasks.byId[id].price / 1000 || 0).reduce((a,b) => a + b, 0)),
            stack: 'expenses'
        });
        this.max = Math.max(columns[0].data.map((v, i) => {
            let total = 0;
            for (let x = 0; x < this.props.filter.offset; x++) {
                if (columns[x]) {
                    total = total + columns[x].data[i];
                }
            }
            return parseInt(total);
        }).reduce((a, b) => a = a < b ? b : a, 0), this.max);
        return columns;
    }

    handleClick(value) {
        const year = this.state.year + value;
        let yearToFetch;
        if (value > 0) {
            yearToFetch = year + this.props.filter.offset;
        }else{
            yearToFetch = year;
        }
        if (this.props.years[yearToFetch] !== undefined) {
            this.setState({ year });
        } else {
            this.props.fetchYear(yearToFetch, this.props.currentLocation.id).then(() => {
                this.setState({ year });
            }).catch(() => {});
        }
    }

    render() {
        if (this.props.loading.currentLocation) {
            return <Loading isLoading={this.props.loading.currentLocation}/>;
        }
        if (this.props.currentLocation.id) {
            area = this.props.currentLocation.area;
            return <div style={{ minWidth: '600px', overflow: 'auto' }}>
                <button onClick={() => this.handleClick(-1)} id="chart-minus-year-button" className="btn-primary btn d-flex align-items-center" style={{ position: 'absolute', left: '1em', border: 0, width: '48px', height: '48px', borderRadius: '50%', zIndex: 3, fontWeight: 'bold', fontSize: '26px' }}>
                    <ChevronLeft style={{ width: '3rem' }} className="icon-f-inv" />
                </button>
                <button onClick={() => this.handleClick(1)} id="chart-plus-year-button" className="btn-primary btn d-flex align-items-center" style={{ position: 'absolute', right: '2em', border: 0, width: '48px', height: '48px', borderRadius: '50%', zIndex: 3, fontWeight: 'bold', fontSize: '26px' }}>
                    <ChevronRight style={{ width: '3rem' }} className="ml-1 icon-f-inv" />
                </button>
                <HighchartsReact
                    key={this.state.key}
                    highcharts={Highcharts}
                    options={this.getOptions()}
                />
                <div className="d-flex justify-content-between" style={{ position: 'absolute', right: 0, marginRight: '4em' }}>
                    <button className="btn btn-outline-primary mr-3" id="chart-export-button" onClick={() => this.export()}><Download className="icon-f icon-cls mr-2" /> Vie exceliin</button>
                    <button className="btn btn-outline-primary" id="chart-edit-income-button" onClick={() => this.props.history.push(`/${this.props.match.params.id}/${this.props.match.params.slug}/income`)}><Cog className="icon-s icon-cls mr-2" /> Muokkaa menoja ja tuloja</button>
                </div>
                {!this.props.currentLocation.area ?
                    <div className="budget-defaults">
                        <p>Budjetin perustana olevaa huoneistoalaa ei ole määritelty. Ole hyvä, ja täydennä tieto <Link id="chart-set-location-area-link" to={`/${this.props.match.params.id}/${this.props.match.params.slug}/edit`}>taloyhtiön tietoihin</Link>.</p>
                    </div> : ''}
                {this.default ?
                    <div className="budget-defaults d-flex text-left">
                        <p className="my-auto">Energia-, kiinteistövero- tai hallintokustannukset ovat arvioita.
                            <Link id="chart-change-default-income-link" to={`/${this.props.match.params.id}/${this.props.match.params.slug}/income`}>Lisää taloyhtiön todelliset tiedot</Link>
                        </p>
                    </div> : ''}
            </div>;
        }
        return null;
    }

    getHeight() {
        return (window.innerHeight - 200 < 400 ? 400 : window.innerHeight - 200);
    }

    getOptions() {
        const vastike = Array.from({ length: this.props.filter.offset }, (tmp, i) => {
            const value = this.formValues()
                .map(c => (c.stack === 'income' ? -c.data[i] : c.data[i]))
                .reduce((total, num) => total+num) / (this.props.currentLocation.area / 1000) / 12;
            return parseFloat(value.format(2));
        });

        return {
            chart: {
                height: this.getHeight() + 'px',
            },
            subtitle: {
                text: this.props.currentLocation.area ? '<div class="d-flex flex-column"><p>Vuosittaiset kustannukset x 1000€</p><br><p>Keskimäärin '+ parseFloat(vastike.reduce((a, v) => {return a + v;}, 0) / vastike.length).toFixed(2) +'€/m<a style="font-size:8px;baseline-shift:super">2</a></p><br><p>Huoneistoala '+ this.props.currentLocation.area +'m<a style="font-size:8px;baseline-shift:super">2</a></p></div>' : '',
                floating: true,
                align: 'left',
                x: 50,
                verticalAlign: 'bottom',
                y: 20
            },
            colors: ['#002b17', '#80958b', '#c36d5c', '#d9d9f2'],
            credits: {
                enabled: false
            },
            title: {
                text: ''
            },
            xAxis: {
                lineWidth: 0,
                tickPosition: 'outside',
                offset: 0,
                padding: 0,
                startOnTick: true,
                gridLineWidth: 1,
                tickmarkPlacement: 'on',
                tickLength: 20,
                opposite: true,
                labels: {
                    formatter: function () {
                        return '<div style="margin:auto;text-align: center;display:flex;align-items:center"><h4 class="w-100" style="color:#002b17;font-size: 20px; margin-bottom: -0.6rem">' + this.value + '</h4></div><div style="white-space: nowrap;color: #929292;margin:auto;padding:1em;font-size:12px;font-weight:600;text-align: center;">' + ((this.chart.yAxis[0].stacking.stacks['column,expenses,,'][this.pos] ? this.chart.yAxis[0].stacking.stacks['column,expenses,,'][this.pos].total : 0)  * 1000).format() +' €</div><div style="margin:auto;background-color: #e6e6e6; width: 8px; height: 8px; border-radius: 50%"></div>';
                    },
                    overflow: "allow",
                    style: {
                        height: "75px"
                    },
                    useHTML: true,
                },
                categories: this.formCategories()
            },
            yAxis: [{ // Primary yAxis
                max: this.max,
                labels: {
                    format: '{value}',
                    style: {
                        color: 'black'
                    }
                },
                title: {
                    text: 'Kustannukset',
                    style: {
                        color: 'black'
                    }
                }
            }, { // Secondary yAxis
                title: {
                    text: 'Vastike',
                    style: {
                        color: 'black'
                    }
                },
                labels: {
                    format: '{value} € / m<a style="font-size:8px;baseline-shift:super">2</a>',
                    style: {
                        color: 'black'
                    }
                },
                html: true,
                opposite: true,
                maxPadding: 0.5
            }],
            plotOptions: {
                column: {
                    stacking: 'normal',
                    dataLabels: {
                        enabled: false,
                        color: 'white'
                    }
                }
            },
            tooltip: {
                formatter: function () {
                    if (this.series.name === 'Vastike') {
                        return `<b>${this.x}</b><br><b style="color:${this.series.color}; font-size: 16px">●</b>${this.series.name}: ${this.y.format(2)}€/m<a style="font-size:8px;baseline-shift:super">2</a>/kk`;
                    }
                    if (this.series.name === 'Tulot') {
                        return `<b>${this.x}</b><br><b style="color:${this.series.color}; font-size: 16px">●</b>${this.series.name}: ${(((this.y * 1000) / area) / 12).format(2)}€/m<a style="font-size:8px;baseline-shift:super">2</a>/kk`;
                    }
                    return '<div><b>'+this.x+'</b><br>' +
                    this.series.xAxis.series.filter(point => point.name !== 'Tulot' && point.name !== 'Vastike').map(point => {
                        return `<b style="color:${point.data[this.point.x].color}; font-size: 16px">●</b>${point.name}: ${(((point.data[this.point.x].y * 1000) / area) / 12).format(2)}€/m<a style="font-size:8px;baseline-shift:super">2</a>/kk<br>`;
                    }).join('') + '</div>';
                },
            },
            series: [...this.formValues(), {
                type: 'line',
                name: 'Vastike',
                data: vastike,
                yAxis: 1,
                color: 'black',
                marker: {
                    lineWidth: 2,
                    lineColor: 'black',
                    fillColor: 'black'
                }
            }]
        };
    }
}

const mapStateToProps = ({ tasks, years, currentLocation, loading, filter }) => ({ tasks, years, currentLocation, loading, filter });

const mapDispatchToProps = (dispatch) => ({
    getLocation: (id) => dispatch(getLocation(id)),
    exportBudget: (chart, headings) => dispatch(exportBudget(chart, headings)),
    fetchYear: (year, location_id) => dispatch(fetchYear(year, location_id)),
});
export default connect(mapStateToProps, mapDispatchToProps) (ChartContainer);
