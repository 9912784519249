import axios from "axios";

const url = process.env.REACT_APP_API_URL;

export const attach = (id, files, destination) => axios.post(url + '/api/' + destination + '/' + id + '/attachments', { files });
export const del = (id, parent, destination) => axios.delete(url + '/api/' + destination + '/' + parent + '/attachments/' + id);
export const signedStorageUrl = async (file, options = null) => axios.post(url + '/api/signed-storage-url', {
    bucket: options.bucket || '',
    content_type: options.contentType || file.type,
    expires: options.expires || ''
});
export const s3Put = async (url, file, headers, options) => axios.put(url, file, {
    headers: headers,
});
