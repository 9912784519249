export default (state = {}, action) => {
    switch (action.type) {
    case 'REQUEST_ERROR':
        const type = action.failureType.replace(/REQUEST_|_ERROR/gi, '').toLowerCase();

        return { ...state, ...{ [action.status]: { ...state[action.status], ...{ [type]: action.data } } } };
    case '@@router/LOCATION_CHANGE':
        return {};
    case 'LOGOUT':
    case 'CLEAR_CURRENT_LOCATION':
        return {};
    default:
        return { ...state };
    }
};
