import { toggleContactModal, sendContactEmail } from "../actions/contact";
import ContactModal from "../components/ContactModal";
import { Modal, ModalBody } from "reactstrap";
import Circle from "../components/Circle";
import React, { Component } from "react";
import { connect } from "react-redux";
import history from "../history";

export class ContactModalContainer extends Component {
    sendMessage(message) {
        let location = '';
        if (this.props.currentLocation.id) {
            location = `<div>Kohteen tiedot: <div><p>Nimi: ${this.props.currentLocation.name}</p><p>Osoite: ${this.props.currentLocation.address}</p><p>Paikkakunta: ${this.props.currentLocation.zipcode.zip} ${this.props.currentLocation.zipcode.name}</p></div></div>`;
        }
        message = `<div><div>${message}</div><p>Sijainti: ${history.location.pathname}</p><p>Selain: ${navigator.userAgent}</p>${location}</div>`;
        this.props.sendContactEmail(message).then(r => {
            r()
                .then(() => {
                    this.props.toggleContactModal();
                })
                .catch(() => {
                });
        });
    }

    render() {
        return <Modal isOpen={true} toggle={() => this.props.toggleContactModal('')} style={{ width: '95vw', maxWidth: '95%' }}>
            <ModalBody style={{ width: 'auto', margin: 'auto' }}>
                <div className="col-md-9 col-12" style={{ position: 'relative', margin: 'auto' }}>
                    <Circle type="outline" size="small" id="contact-close-button" className="mt-2 c-pointer bg-white" onClick={() => this.props.toggleContactModal('')} style={{ position: 'absolute', zIndex: 2, right: '-1.5rem', top: '-2rem', width: '1.5rem', height: '1.5rem', fontSize: '1rem' }}>X</Circle>
                </div>
                <ContactModal header={this.props.modalHeader} user={this.props.user} sendMessage={(message) => this.sendMessage(message)}/>
            </ModalBody>
        </Modal>;
    }
}

const mapStateToProps = ({ contactModal, user, currentLocation }) => ({ modalHeader: contactModal.header, user, currentLocation });

const mapDispatchToProps = (dispatch) => ({
    toggleContactModal: () => dispatch(toggleContactModal()),
    sendContactEmail: (message) => dispatch(sendContactEmail(message)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ContactModalContainer);
