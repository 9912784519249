import { REQUEST_COMMENTS_SUCCESS, REQUEST_CREATE_COMMENT_SUCCESS, WEBSOCKET_ADD_COMMENT } from "../actions/comments";

export default (state = {}, action) => {
    switch (action.type) {
    case WEBSOCKET_ADD_COMMENT:
    case REQUEST_CREATE_COMMENT_SUCCESS:
        return {
            ...state,
            ...{
                [action.payload.task_id]: state[action.payload.task_id] !== undefined
                    ? [action.data.comment, ...state[action.payload.task_id]]
                    : [action.data.comment]
            }
        };
    case REQUEST_COMMENTS_SUCCESS:
        return { ...state, ...{ [action.payload.task_id]: [...action.data.comments] } };
    case 'LOGOUT':
    case 'MAINTENANCE_MODE':
        if (action.type === 'MAINTENANCE_MODE' && !action.data.mode) {
            return { ...state };
        }
        return {};
    default:
        return { ...state };
    }
};
