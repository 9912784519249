import { exportLongTermPlanExcel, getLocations, setLocation } from "../actions/locations";
import { getOrganizations } from "../actions/organizations";
import { push } from "connected-react-router";
import Loading from "../components/Loading";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import image from "../images/toimenpiteet_2024.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export class LocationsContainer extends Component {

    constructor(props) {
        super(props);

        this.state = { longTermPlanSelect: false, selectedLocations: { } };
    }

    async componentDidMount() {
        await this.props.getOrganizations();
        this.props.clearCurrentLocation();
        this.props.getLocations(this.props.locations.meta.current_page || null);

    }

    get(id) {
        return this.props.locations.byId[id];
    }

    set(location) {
        this.props.setPage(`/${location.id}/${location.slug}`);
    }

    changePage(e, page) {
        e.preventDefault();
        this.props.getLocations(page);
    }

    toggleLongTermPlanSelect() {
        this.setState({ longTermPlanSelect: !this.state.longTermPlanSelect });
    }

    selectLocationToLongTermPlanExport(location) {
        if (this.state.selectedLocations[location.id]) {
            const selected = { ...this.state.selectedLocations };
            delete selected[location.id];
            this.setState({ selectedLocations: selected });
        } else {
            this.setState({ selectedLocations: { ...this.state.selectedLocations, [location.id] : { id: location.id, name: location.name } } });
        }
    }

    selectAll() {
        const selected = { ...this.state.selectedLocations };
        this.props.locations.all.forEach((location, index) => {
            location = this.get(location);
            if (selected[location.id]) {
                delete selected[location.id];
            } else {
                selected[location.id] = { id: location.id, name: location.name };
            }
        });
        this.setState({ selectedLocations: selected });
    }

    render() {
        if (this.props.loading.organization || this.props.loading.locations) {
            return <Loading isLoading={true}/>;
        }

        /*
        if (this.props.organization !== undefined && this.props.organization.id !== undefined && ! this.props.organization.active) {
            return <div className="Locations mt-5 col-4 mx-auto text-left pt-5 bg-primary">
                <h2 className="text-white" style={{weight: 'bold', fontSize: '36px'}}>Sopimus on päättynyt</h2>

                <p className="text-white"><small>Uudista sopimus. Ota yhteyttä tuki@kiinteistokartturi.fi</small></p>
            </div>
        }*/
        if (!this.props.locations.all.length && !this.props.loading.locations && !this.props.loading.organization) {
            return <div className="Locations d-md-flex justify-content-center mt-5 col-md-7 mx-auto text-left pt-5 bg-primary">
                <div className="col-7">
                    <img src={image} alt="" className="mw-100 mh-100 pb-3"/>
                </div>
                <div className="col-12 col-md-5">
                    <h2 className="text-white" style={{ weight: 'bold', fontSize: '36px' }}>Sinulla ei ole vielä kohteita</h2>

                    <p className="text-white"><small>Ei hätää, voit <Link id="no-locations-start-link" className="text-white" style={{ textDecoration: 'underline' }} to={'/start/individual'}>luoda uuden kohteen.</Link></small></p>
                    <p className="text-white"><small>Tai mikäli sinulla on Kuntoarviosta aloituskoodi voit liittyä kohteeseen koodilla <Link id="no-locations-start-rs15-link" className="text-white" style={{ textDecoration: 'underline' }} to={'/start/rs15'}>tästä</Link></small></p>
                </div>
            </div>;
        }
        return <div className="Locations">
            <button type="button" onClick={() => this.toggleLongTermPlanSelect()} className="longtermplan-select btn btn-primary btn-sm p-2 mt-3 mb-1">{ this.state.longTermPlanSelect ? 'Sulje valinta' : 'PTS-vienti' }</button>
            {this.state.longTermPlanSelect && <div className="selected-to-export p-2 bg-bg-light rounded-0 flex-wrap mb-1 text-left">
                <p className="d-inline-flex">Valitse PTS-vientiin kohteet: </p>
                {Object.values(this.state.selectedLocations).length > 0 ?
                    Object.values(this.state.selectedLocations).map((location, index) => <span key={'l'+location.id}>
                        { index !== 0 ? ', ' : '' }
                        {location.name}
                    </span>
                    ) : "0 kohdetta valittu"
                }
                {Object.values(this.state.selectedLocations).length > 0 &&
                    <button type="button" onClick={() => this.props.exportLongTermPlanExcel(Object.keys(this.state.selectedLocations))} className="btn btn-primary ml-2 float-right">Vie Exceliin</button>}
            </div>}
            {this.state.longTermPlanSelect && <button type="button" onClick={() => this.selectAll()} className="btn btn-info btn-sm mb-1">Valitse kaikki sivulta</button>}

            {this.props.locations.meta !== undefined && this.props.locations.meta.total > this.props.locations.meta.per_page ?
                <nav>
                    <ul className="pagination justify-content-center">
                        <li className={"page-item " + (this.props.locations.links.prev === null ? 'disabled' : '')}><a href={this.props.locations.links.prev} id="locations-prev-page-link" onClick={(e) => this.changePage(e, this.props.locations.links.prev)} className="page-link">Edellinen</a></li>
                        <li className="page-item d-flex align-items-center justify-content-center"><span className="page-link" style={{ cursor: 'auto' }}>Sivu: {this.props.locations.meta.current_page || 0} / {this.props.locations.meta.last_page || 0}</span></li>
                        <li className={"page-item " + (this.props.locations.links.next === null ? 'disabled' : '')}><a href={this.props.locations.links.next} id="locations-next-page-link" onClick={(e) => this.changePage(e, this.props.locations.links.next)} className="page-link">Seuraava</a></li>
                    </ul>
                </nav> : ''}

            <div className="Locations d-md-flex flex-wrap justify-content-center mb-3">
                {
                    this.props.locations.all.map((location, index) =>
                        <div className="card w-md-25 my-2 mx-1 text-left border-0 rounded-0" key={'location-card-' + index}>
                            <img onClick={() => this.set(this.get(location))} className={'card-img-top rounded-0 c-pointer' + (this.get(location).thumbnail === '/svg/building.svg' ? ' border-left border-top border-right' : '')} src={(this.get(location).thumbnail || '')} alt=""/>
                            <div className="bg-bg-light p-0 px-3 pt-2">
                                <button type="button" onClick={() => this.set(this.get(location))} className="btn btn-link btn-lg p-0">{this.get(location).name}</button>
                            </div>

                            <address className="card-text bg-bg-light border-bottom px-3 pb-3">
                                {this.get(location).address}, {this.get(location).zipcode.zip} {this.get(location).zipcode.name}
                            </address>
                            {this.state.longTermPlanSelect && this.state.selectedLocations[this.get(location).id] && <button type="button" className={"select-to-export btn p-2 btn-sm btn-primary"} onClick={() => this.selectLocationToLongTermPlanExport(this.get(location))}><FontAwesomeIcon icon="check" color="#ffffff" size="sm" className="mr-2" /> Valittu</button>}
                            {this.state.longTermPlanSelect && !this.state.selectedLocations[this.get(location).id] && <button type="button" className={"select-to-export btn p-2 btn-sm btn-info"} onClick={() => this.selectLocationToLongTermPlanExport(this.get(location))}> Valitse</button>}
                        </div>
                    )
                }
            </div>

            {this.props.locations.meta !== undefined && this.props.locations.meta.total > this.props.locations.meta.per_page ?
                <nav>
                    <ul className="pagination justify-content-center">
                        <li className={"page-item " + (this.props.locations.links.prev === null ? 'disabled' : '')}><a href={this.props.locations.links.prev} onClick={(e) => this.changePage(e, this.props.locations.links.prev)} className="page-link">Edellinen</a></li>
                        <li className="page-item d-flex align-items-center justify-content-center"><span className="page-link" style={{ cursor: 'auto' }}>Sivu: {this.props.locations.meta.current_page || 0} / {this.props.locations.meta.last_page || 0}</span></li>
                        <li className={"page-item " + (this.props.locations.links.next === null ? 'disabled' : '')}><a href={this.props.locations.links.next} onClick={(e) => this.changePage(e, this.props.locations.links.next)} className="page-link">Seuraava</a></li>
                    </ul>
                </nav> : ''}
        </div>;
    }
}

const mapStateToProps = ({ locations, loading, organization }) => ({ locations, loading, organization });

const mapDispatchToProps = (dispatch) => ({
    getLocations: (page = null) => dispatch(getLocations(page)),
    setLocation: (location) => dispatch(setLocation(location)),
    setPage: (page) => dispatch(push(page)),
    clearCurrentLocation: () => dispatch({ type: 'CLEAR_CURRENT_LOCATION' }),
    getOrganizations: () => dispatch(getOrganizations()),
    exportLongTermPlanExcel: (locations) => dispatch(exportLongTermPlanExcel(locations))
});

export default connect(mapStateToProps, mapDispatchToProps)(LocationsContainer);
