import GoogleTagManager from "@redux-beacon/google-tag-manager";
import { createMiddleware } from "redux-beacon";

const eventsMap = {
    '@@router/LOCATION_CHANGE': action => {
        const path = action.payload.location.pathname;
        const events = [{ hitType: 'pageview', page: path }];
        if (path.match(/^\/\d+\/.[^/]*$/)) {
            events.push({ hitType: 'event', value: 'Toimenpiteet', action: ' ' });
        }
        if (path.match(/^\/\d+\/.*?\/tasks\/\d+\/.*?$/)) {
            events.push({ hitType: 'event', value: 'Toimenpidekortti avattu', action: ' ' });
        }
        if (path.match(/\/chart$/)) {
            events.push({ hitType: 'event', value: 'Budjetti', action: ' ' });
        }
        if (path.match(/\/report$/)) {
            events.push({ hitType: 'event', value: 'Kunnossapitotarveselvitys', action: ' ' });
        }
        if (path.match(/\/edit$/)) {
            events.push({ hitType: 'event', value: 'Taloyhtiön tiedot', action: ' ' });
        }
        if (path.match(/\/income$/)) {
            events.push({ hitType: 'event', value: 'Taloyhtiön menot ja tulot', action: ' ' });
        }
        if (path.match(/^\/help$/)) {
            events.push({ hitType: 'event', value: 'Ohjeet', action: ' ' });
        }
        if (path.match(/^\/faq$/)) {
            events.push({ hitType: 'event', value: 'Faq', action: ' ' });
        }
        return events;
    },
    REQUEST_CREATE_ATTACHMENT_SUCCESS: action => ({ hitType: 'event', value: 'New attachment', action: ' ' }),
    REQUEST_DELETE_ATTACHMENT_SUCCESS: action => ({ hitType: 'event', value: 'Attachment deleted', action: ' ' }),
    REQUEST_UPDATE_TASK_SUCCESS: (action, prevState, nextState) => ({ hitType: 'event', value: 'Task updated', action: ' ' }),
    REQUEST_CREATE_TASK_SUCCESS: action => ({ hitType: 'event', value: 'Task Created', action: ' ' }),
    REQUEST_UPDATE_LOCATION_SUCCESS: action => ({ hitType: 'event', value: 'Location updated', action: ' ' }),
    REQUEST_SORT_TASKS_SUCCESS: action => ({ hitType: 'event', value: 'Task moved', action: ' ' }),
    REQUEST_DOWNLOAD_TASK_EXCEL: action => ({ hitType: 'event', value: 'Report downloaded', action: ' ' }),
    REQUEST_INVITE_USER_SUCCESS: (action, prevState) => ({ hitType: 'event', value: 'Invitation created', action: ' ' }),
    VOID_SUCCESS: action => ({ hitType: 'event', value: 'Comment created', action: ' ' }),
    REQUEST_SPLIT_TASK_SUCCESS: action => ({ hitType: 'event', value: 'Task splitted', action: action.data.message || '' }),
};

const analyticsMiddleware = process.env.REACT_APP_GTM ? createMiddleware(eventsMap, GoogleTagManager()) : store => next => action => next(action);

export default analyticsMiddleware;
