import { Modal, ModalBody } from "reactstrap";
import Circle from "../components/Circle";
import React, { Component } from "react";
import PropTypes from "prop-types";

export class ActionsModal extends Component {

    componentDidUpdate(prevProps, nextProps) {
        if (this.refs.year) {
            this.refs.year.focus();
        }
    }

    action() {
        let value;
        if (this.refs.year) {
            value = this.refs.year.value;
        }
        this.props.action(value).then(() => {
            if (this.props.header === "Poista") return;
            this.props.close();
        });
    }

    render() {
        return <Modal isOpen={true} toggle={() => this.props.close()} style={{ width: '30%', top: '20%' }}>
            <ModalBody style={{ maxWidth: '1400px', minWidth: '35rem', width: '100%' }} >
                <div className="col-md-12" style={{ position: 'relative' }}>
                    <Circle type="outline" size="small" className="mt-2 c-pointer bg-white" onClick={() => this.props.close()} style={{ position: 'absolute', zIndex: 2, right: '-1rem', top: '-2rem', width: '1.5rem', height: '1.5rem', fontSize: '1rem' }}>X</Circle>
                </div>
                <div className="p-4 rounded d-flex flex-column" style={{ position: 'relative', background: 'white' }}>
                    {this.props.header === 'Siirrä' ?
                        <div className="d-flex">
                            <h2 className="mx-auto text-center mt-5">Siirrä kortti<br/> vuodelle</h2>
                        </div>
                        :
                        <div className="d-flex">
                            <h2 className="mx-auto text-center mt-5">Haluatko varmasti<br/>poistaa kortin?</h2>
                        </div>
                    }
                    {this.props.header === 'Siirrä' ? <input ref="year" type="number" name="year" defaultValue={this.props.task.year} className="w-50 mx-auto"/> : <p></p>}
                    <div className="d-flex mt-5 justify-content-around">
                        <button className="btn btn-outline-primary col-5" style={{ minWidth: '5rem' }} onClick={() => this.props.close()}>Peruuta</button>
                        <button className="btn btn-primary col-5" style={{ minWidth: '5rem' }} onClick={() => this.action()}>Kyllä</button>
                    </div>
                </div>
            </ModalBody>
        </Modal>;
    }
}

ActionsModal.propTypes = {
    close: PropTypes.func.isRequired,
    action: PropTypes.func.isRequired
};

export default ActionsModal;
