import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Question, Signout, Bars, Info } from "../svg/icons";
import { toggleContactModal } from "../actions/contact";
import { login, logout } from "redux-implicit-oauth2";
import { fetchUserIfNeeded } from "../actions/user";
import UserAvatar from "../components/UserAvatar";
import Circle from "../components/Circle";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import history from "../history";
import React from "react";

const config = {
    url: process.env.REACT_APP_API_URL + "/oauth/azure-b2c/authorize",
    client: process.env.REACT_APP_OAUTH_CLIENT_ID,
    redirect: process.env.REACT_APP_REDIRECT_URL,
    scope: "",
    width: window.innerWidth,
    height: window.innerHeight,
};

export class Login extends React.Component {
    constructor(props) {
        super(props);
        this.toggle = this.toggle.bind(this);
        this.state = { dropdownOpen: false };
        this.fetchOnce = true;
    }

    toggle() {
        this.setState(prevState => ({ dropdownOpen: !prevState.dropdownOpen }));
    }

    componentDidUpdate() {
        if (this.fetchOnce && this.props.isLoggedIn) {
            this.props.fetchUserIfNeeded();
            this.fetchOnce = false;
        }
    }

    render() {
        if (this.props.isLoggedIn && this.props.user.id && !this.props.maintenance.mode) {
            return <Dropdown isOpen={this.state.dropdownOpen} toggle={this.toggle} inNavbar={true}>
                <DropdownToggle color="link" id="user-dropdown-trigger">
                    <Circle type="solid" style={{ fontSize: '1.75rem', overflow: 'hidden' }}>
                        <Bars className="icon-f-inv"/>
                    </Circle>
                </DropdownToggle>
                <DropdownMenu right>
                    <DropdownItem tag="div" className="d-flex">
                        <Circle key={'dropdown-user-avatar'} type="outline" size="small" style={{ fontSize: '1.75rem', overflow: 'hidden' }}>
                            {this.props.user.avatar ? <img alt="/" src={this.props.user.avatar.tiny}/> : <UserAvatar user={this.props.user}/>}
                        </Circle>
                        <div className="ml-2">
                            <h6 className="mb-0" style={{ fontSize: '0.875rem' }}>{this.props.user.firstname} {this.props.user.lastname}</h6>
                            <small style={{ fontWeight: 600 }}>{this.props.currentLocation.id !== undefined && this.props.user.locationRoles !== undefined ? this.props.user.locationRoles[this.props.currentLocation.id].name : ''}</small>
                            <p>
                                <button onClick={() => history.push('/user')} className="btn btn-link btn-sm" id="user-dropdown-edit-user-button"><small>Muokkaa</small></button>
                            </p>
                        </div>
                    </DropdownItem>
                    {this.props.currentLocation.id ? <DropdownItem tag="a" className="c-pointer pl-1" onClick={() => history.push(`/${this.props.currentLocation.id}/${this.props.currentLocation.slug}/edit`)} id="user-dropdown-location-information-link"><Info className="icon-f icon-cls nohover"/> Taloyhti&ouml;n tiedot</DropdownItem> : ''}
                    <DropdownItem tag="a" className="c-pointer pl-1" onClick={() => this.props.toggleContactModal()} id="user-dropdown-feedback-link"><Question className="icon-f nohover"/> Ota yhteyttä</DropdownItem>
                    {this.props.organization ? <DropdownItem tag="a" className="c-pointer pl-1" onClick={() => history.push({ pathname: `/start/rs15`, state: { origin: 'nav' } })} id="user-dropdown-rs15-start-link"><FontAwesomeIcon icon="user-plus" style={{ fontSize: '1rem', marginLeft: '0.2rem' }}/> Liity aloituskoodilla</DropdownItem> : ''}
                    <DropdownItem tag="a" className="c-pointer pl-1" onClick={this.props.logout} id="user-dropdown-logout-link"><Signout className="icon-f icon-cls nohover"/> Kirjaudu ulos</DropdownItem>
                </DropdownMenu>
            </Dropdown>;
        }

        if (!this.props.maintenance.mode) {
            return <button className="btn btn-text text-bold" type="button" onClick={this.props.login} id="user-dropdown-login-link">Kirjaudu</button>;
        }
        return '';
    }
}

Login.propTypes = {
    isLoggedIn: PropTypes.bool.isRequired,
    login: PropTypes.func.isRequired,
    logout: PropTypes.func.isRequired
};

const mapStateToProps = ({ auth, user, currentLocation, organization, maintenance }) => ({
    isLoggedIn: auth.isLoggedIn,
    currentLocation,
    organization,
    maintenance,
    user
});

const mapDispatchToProps = (dispatch) => ({
    login: () => dispatch(login(config)),
    fetchUserIfNeeded: () => dispatch(fetchUserIfNeeded()),
    toggleContactModal: (header) => dispatch(toggleContactModal(header)),
    logout: () => {
        dispatch(logout());
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
