import React, { Component } from "react";
import PropTypes from "prop-types";

export class LoginBox extends Component {
    render() {
        return <div className="d-flex flex-column bg-primary col-md-6 mx-auto p-5">
            <h2 className="text-white">Tervetuloa Kiinteistökartturiin!</h2>

            <p className="text-white">Jos sinulla on jo Sustera-tunnukset, voit kirjautua sisään. Muussa tapauksessa pyydämme rekisteröitymään ja luomaan uudet tunnukset, joilla pääset sisään palveluun.</p>
            <div className="d-flex justify-content-between">
                {/* this.props.instructions && <a href={this.props.instructions} target="_blank" rel="noopener noreferrer" className="btn btn-info w-25">{this.props.instructions_txt}</a> */}
                <button onClick={() => this.props.login()} className="btn btn-light w-25" id="login-box-continue">
                    Jatka
                </button>
            </div>
        </div>;
    }
}

Comment.propTypes = {
    login: PropTypes.func.isRequired,
};

export default LoginBox;
