import axios from "axios";

const url = process.env.REACT_APP_API_URL;

export const del = (data) => axios.delete(url + '/api/tasks/' + data.id);
export const add = (data) => axios.post(url + '/api/tasks', data);
export const get = (id = '') => axios.get(url + '/api/tasks' + (id !== '' ? '/' + id : ''));
export const put = (id, data) => axios.put(url + '/api/tasks/' + id, data);
export const sort = (id, data) => axios.put(url + '/api/tasks/' + id + '/sort', data);
export const split = (id, years) => axios.post(url + '/api/tasks/' + id + '/split', { years });
export const attach = (id, files) => axios.post(url + '/api/tasks/' + id + '/attachments', files);


export const excel = tasks => axios({
    method: 'post',
    url: url + '/api/tasks/export',
    responseType: 'blob',
    data: { tasks }
}).then((response) => {
    const url = window.URL.createObjectURL(response.data);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'Kunnossapitotarveselvitys.xlsx');
    document.body.appendChild(link);
    link.click();
    return response;
});
