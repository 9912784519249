import ContentEditable from "react-contenteditable";
import React, { Component } from "react";
import PropTypes from "prop-types";

export class ContactModal extends Component {

    constructor(props) {
        super(props);

        this.state = {
            message: "",
            error: false
        };
    }

    render() {
        return (
            <div className="p-3 rounded d-flex col-md-9 col-12 mx-auto" style={{ position: 'relative', background: 'white' }}>
                <div className="w-100">
                    <h1>{this.props.header || 'Ota yhteyttä'}</h1>
                    {this.props.header === 'Palaute ja ideat' ? <p>Otamme mielellämme vastaan palautetta ja ideoita Kiinteistökartturin kehittämiseen. Pyrimme käsittelemään palautteet mahdollisimman pian.</p> : ''}
                    <div className="w-100 my-4 border">
                        <ContentEditable style={{ minHeight: '5rem' }} html={this.state.message} className="p-2" onChange={(e) => {
                            this.setState({ message: e.target.value });
                        }} placeholder="viestisi" tag="p"/>
                    </div>
                    <p>Lähettäjä: {this.props.user.email}</p>
                    <div className="d-flex flex-md-row flex-column">
                        <p className="text-secondary">
                            Muissa asioissa sinua palvelee Susteran asiakaspalvelu<br/>
                            p. 030 670 5500* ,<br/>
                            asiakaspalvelu@sustera.com
                        </p>

                        <button className="d-block btn btn-primary ml-auto" onClick={() => this.props.sendMessage(this.state.message)} id={(this.props.header === 'Palaute ja ideat' ? 'feedback' : 'contact') + "-send"} style={{ width: '14rem', height: '3rem', fontWeight: 500 }}>Lähetä</button>
                    </div>
                    <p className="text-seconday" style={{ fontSize: '8px', lineHeight: '1rem' }}>
                        * Puhelut 030-alkuisiin numeroihin 8,35 snt/puhelu + 8,83 snt/min (alv. 24 %).
                    </p>
                </div>
            </div>
        );
    }
}

ContactModal.propTypes = {
    sendMessage: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired
};

export default ContactModal;
