import React, { Component } from "react";
import PropTypes from "prop-types";

export class UserAvatar extends Component {
    constructor(props) {
        super(props);

        this.state = {
            radius: 0
        };
    }

    componentDidMount() {
        const radius = ((this.refs.userAvatar.parentNode.offsetWidth < this.refs.userAvatar.parentNode.offsetHeight ? this.refs.userAvatar.parentNode.offsetWidth : this.refs.userAvatar.parentNode.offsetHeight) / 2);
        this.setState({ radius });
    }

    render() {
        return (
            <svg ref="userAvatar" height={this.state.radius * 2} width={this.state.radius * 2}>
                <rect x={0} y={0} width={this.state.radius * 2} height={this.state.radius * 2} stroke="none" strokeWidth="0" fill="#002b17"/>
                <text x={this.state.radius * 0.95} y={(this.state.radius * 2) * 0.525} fontSize={(this.state.radius * 2) * 0.46666667} fill="white" dominantBaseline="middle" textAnchor="middle">{(this.props.user.firstname[0] + this.props.user.lastname[0]).toUpperCase()}</text>
            </svg>
        );
    }
}

UserAvatar.propTypes = {
    user: PropTypes.object.isRequired,
};

export default UserAvatar;
