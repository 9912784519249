import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CommentsContainer from "../containers/CommentsContainer";
import { Plus, Coins, Question } from "../svg/icons";
import ContentEditable from "react-contenteditable";
import DropzoneComponent from "./DropzoneComponent";
import ActionsModal from "./ActionsModal";
import React, { Component, createRef } from "react";
import TagDropdown from "./TagDropdown";
import TextEditor from "./TextEditor";
import Attachment from "./Attachment";
import { catchEnter } from "../utils";
import PropTypes from "prop-types";
import Loading from "./Loading";
import Circle from "./Circle";

import _ from "lodash";

export class CurrentTask extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: this.props.task.name,
            description: this.props.task.description,
            price: this.props.task.children_count ? this.props.task.total_price : this.props.task.price,
            type: '', status: null,
            show: false,
            actions: false,
            popup: { move: false, archive: false },
            completed: this.props.task.completed,
        };
        this.splitRef = createRef();
    }
    addTag(parent, target, tag) {
        const tags = this.props.currentLocation.tags;
        if (tags[parent] === undefined) tags[parent] = parent === 'custom' ? [] : {};

        if (parent === 'custom') {
            tags[parent].push(tag);
        }
        else {
            if (tags[parent][target] === undefined) {
                tags[parent][target] = [];
            }
            tags[parent][target].push(tag);
        }
        const temp = {};
        const fillable = [...this.props.currentLocation.get_fillable, 'id'];
        fillable.map(key => {
            temp[key] = this.props.currentLocation[key];
            return key;
        });
        const data = { ...temp, tags };
        this.props.editLocation(data);
    }
    saveTags(parent, newTags) {
        const tags = this.props.task.tags;
        tags[parent] = newTags;

        this.props.updateTask(this.props.task.id, { name: this.state.name, tags: { ...tags } });
    }
    change(e, key) {
        e.persist();
        let value = e.target.value || e.target.innerText;
        if (key === 'price') {
            value = value.split('').filter(char => !isNaN(Number.parseInt(char))).join('');
            this.setState({ [key]: value });
        }
        if (this.props.task[key] !== value && this.state[key] !== value) {
            const data = { [key]: value || "" };
            this.setState(data);
            if (data['name'] === undefined) {data.name = this.props.task.name;}
            this.props.updateTask(this.props.task.id, data);
        }
    }

    toggleCompleted() {
        const completed = !this.state.completed;
        this.setState({ completed });
        this.props.updateTask(this.props.task.id, { completed, name: this.state.name });
    }

    split() {
        if (this.splitRef.current?.value && this.splitRef.current.value !== "" + this.props.task.children_count) {
            this.props.split(this.props.task.id, parseInt(this.splitRef.current.value) + 1).then(() => this.setState({ show: false }));
        }
        this.setState({ show: false });
    }

    toggleShow() {
        this.setState({ show: !this.state.show });
    }

    componentDidUpdate(prevProps) {
        if (this.props.task !== prevProps.task) {
            const task = { ...this.props.task };
            task.price = this.props.task.children_count ? this.props.task.total_price : this.props.task.price;
            this.setState({ ...this.state, ...task });
        }
    }

    render() {
        return (
            <div className="h-100 d-flex flex-column">
                <div className="row">
                    <div className="CurrentTask rounded" style={{ flex:1, position: 'relative' }}>
                        <Loading isLoading={this.props.isLoading} />
                        <div className="row">
                            <div className="col-md-8">
                                <header className="pt-4 pb-3 rounded-top">
                                    <ContentEditable tagName="h2" placeholder="Nimi" html={this.state.name || this.props.task.name || ''} onBlur={(e) => this.change(e, 'name')} className="contenteditable text-primary px-3 px-md-4 w-100" disabled={this.props.task.completed} id="current-task-title"/>
                                    <h4 className="contenteditable px-3 px-md-4 text-secondary" onClick={() => {
                                        if (!this.props.task.completed)
                                            this.setState({ popup: { move: true, archive: false } });
                                    }
                                    } id="current-task-year">{this.props.task.year}</h4>

                                    <div className="d-md-flex justify-content-between px-3 px-md-4 mt-3">

                                        <div className="d-flex align-items-center w-100">
                                            <div className="d-flex w-auto flex-column">
                                                <div className="d-flex">
                                                    <ContentEditable tagName="h3" placeholder={"0"} html={(this.state.price || '')+''} onKeyDown={(e) => catchEnter(e)} onBlur={(e) => this.change(e, 'price')} className="contenteditable pl-2 mb-0 text-primary" disabled={this.props.task.completed} id="current-task-price"/>
                                                    <h3 className="mb-0 text-primary">&euro;</h3>
                                                </div>
                                                {this.props.task.children_count ? <small className="text-right">({this.props.task.price}€)</small> : ''}
                                            </div>
                                            <select className="custom-select ml-3 ml-md-4 py-1 rounded-0" disabled={this.props.task.completed} style={{ width: 'auto', height: 'calc(2.25rem - 1px)' }} value={this.state.status ||this.props.task.status} onChange={(e) => {this.change(e, 'status');}} id="current-task-status">
                                                <option value="estimate">Arvio</option>
                                                <option value="offered">Tarjottu</option>
                                                <option value="final">Lopullinen</option>
                                            </select>
                                            <div>
                                                <button onClick={() => this.toggleShow()} disabled={this.props.task.completed} className="ml-2 btn btn-outline-primary text-bold rounded-0" style={{ fontSize: '0.875rem' }} id="current-task-split-dropdown-trigger">
                                                    <Coins className="icon-f icon-cls mr-2" />
                                                    {this.props.task.children_count ? `Jyvitetty ${this.props.task.children_count + 1} v.` : 'Jyvitä vuosille'}
                                                </button>
                                                {this.state.show ? <div className="split-container p-2 mt-2" style={{ zIndex: 2 }}>
                                                    <select ref={this.splitRef} className="form-control my-1" defaultValue={this.props.task.children_count}>
                                                        {Array.from({ length: 50 }).map((v, i) => {
                                                            if (i === 0) {
                                                                return <option key={i} value={0}>Ei jyvitetty</option>;
                                                            }
                                                            return <option key={i} value={i}>{i + 1} vuotta</option>;
                                                        })}
                                                    </select>
                                                    <button className="btn btn-primary w-100" onClick={() => {this.split();}}>Tallenna</button>
                                                </div>
                                                    : ''}
                                            </div>
                                        </div>
                                    </div>

                                </header>
                                <div className="d-block p-2 pl-md-4 pt-md-4 pb-md-4">
                                    <div className="pl-1 w-100">
                                        <TextEditor
                                            defaultValue={this.state.description || ""}
                                            onBlur={(editor) => this.change({ target: { value: editor }, persist: () => {} }, 'description')}
                                        />
                                    </div>

                                    <div className="text-muted mb-3 pl-1">
                                        <FontAwesomeIcon icon="tag" color="#002b17" size="sm" className="mr-2" />
                                        {Object.values(this.props.task.tags || {}).length ? [..._.flatMap(Object.values(this.props.task.tags.rakenne)), ..._.flatMap(Object.values(this.props.task.tags.extra)), ...this.props.task.tags.custom].map(tag => tag.name).join(', ') : ''}
                                    </div>

                                    <div className="row no-gutters">
                                        <h5 className="col-md-12 text-primary pl-4">Kuvat ja tiedostot</h5>
                                        {!this.props.task.completed && <div className="col-md-6 mb-3">
                                            <DropzoneComponent
                                                onDropAccepted={this.props.saveAttachment}
                                                className="d-flex bg-light flex-column justify-content-center align-items-center h-100 c-pointer"
                                                id="current-task-upload-file"
                                                inner={
                                                    <span>
                                                        <Circle type="solid" size="Large">
                                                            <Plus style={{ width: '1.2rem' }} className="icon-f-inv" />
                                                        </Circle>
                                                        <br/>
                                                        <b className="text-primary" style={{ fontWeight: '600' }}>Lisää kuva tai tiedosto</b>
                                                    </span>
                                                }
                                                outerStyle={{ width: 'auto', height: '100%' }}
                                                style={{ width: '100%', minHeight: '13em', border: 0 }}
                                            />
                                        </div>}
                                        {this.props.task.attachments !== undefined ? this.props.task.attachments.map(file => <div className="col-md-6 mb-3" key={'file-'+file.id}>
                                            <Attachment disabled={this.props.task.completed} file={file} deleteAttachment={this.props.deleteAttachment} size="small" />
                                        </div>) : ''}
                                        {this.props.loadingAttachment ? <div className="col-md-4 mb-3">
                                            <Loading isLoading={this.props.loadingAttachment} />
                                        </div> : ''}
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 d-flex flex-column align-items-center" style={{ paddingRight: '2.5rem' }}>
                                <div className="p-4 border-left mr-1" style={{ marginTop: '6rem' }}>

                                    <div className="mb-5">
                                        <h6 style={{ fontWeight: 600 }}>Apua asiantuntijalta</h6>
                                        <button type="button" className="btn btn-primary text-bold btn-sm" onClick={() => this.props.toggleContactModal()} id="current-task-contact-button">
                                            <Question className="icon-f-inv mr-2" />
                                            Ota yhteyttä
                                        </button>
                                    </div>

                                    <div className="mb-5">
                                        <h6 style={{ fontWeight: 600 }}>Luokittelu</h6>
                                        <select disabled={this.props.task.completed} className="custom-select rounded-0" style={{ fontWeight: 600, maxHeight: '31px', marginBottom: '0.375rem' }} value={this.state.type ||this.props.task.type} onChange={(e) => {this.change(e, 'type');}}>
                                            <option value="plan">Suunnittelu</option>
                                            <option value="research">Tutkimus</option>
                                            <option value="repair">Korjaus</option>
                                            <option value="service">Huolto</option>
                                        </select>

                                        <TagDropdown
                                            disabled={this.props.task.completed}
                                            title="Rakenne"
                                            tags={this.props.currentLocation !== undefined && this.props.currentLocation.tags !== undefined ? this.props.currentLocation.tags.rakenne : []}
                                            selectedTags={this.props.task.tags.rakenne !== undefined ? this.props.task.tags.rakenne : {}}
                                            onChange={(tags) => this.saveTags('rakenne', tags)}
                                            className="w-100"
                                        />

                                        <TagDropdown
                                            disabled={this.props.task.completed}
                                            title="Rakennus, rappu ja asunto"
                                            tags={this.props.currentLocation !== undefined && this.props.currentLocation.tags !== undefined ? this.props.currentLocation.tags.extra : []}
                                            selectedTags={this.props.task.tags.extra !== undefined ? this.props.task.tags.extra : {}}
                                            onChange={(tags) => this.saveTags('extra', tags)}
                                            addNew={true}
                                            newOptions={{ rakennus: 'Rakennus', rappu: 'Rappu', asunto: 'Asunto' }}
                                            newTag={((target, tag) => this.addTag('extra', target, tag))}
                                            className="w-100"
                                            allowDelete={true}
                                            deleteTag={(tag) => this.props.deleteTag(this.props.currentLocation, tag)}
                                        />

                                        <TagDropdown
                                            disabled={this.props.task.completed}
                                            title="Aihe"
                                            tags={this.props.currentLocation !== undefined && this.props.currentLocation.tags !== undefined ? { custom: this.props.currentLocation.tags.custom } : []}
                                            selectedTags={this.props.task.tags.custom !== undefined ? this.props.task.tags.custom : []}
                                            onChange={(tags) => this.saveTags('custom', tags)}
                                            addNew={true}
                                            newTag={((target, tag) => this.addTag('custom', target, tag))}
                                            className="w-100"
                                            allowDelete={true}
                                            deleteTag={(tag) => this.props.deleteTag(this.props.currentLocation, tag)}
                                        />
                                    </div>

                                    <div className="mb-5">
                                        <h6 style={{ fontWeight: 600 }}>Toiminnot</h6>
                                        <button type="button" onClick={(e) => this.toggleCompleted()}  className="my-1 btn btn-primary btn-sm w-100" id="current-task-completed-button">Merkitse tehdyksi</button>
                                        <div><button disabled={this.props.task.completed} className="my-1 btn btn-outline-primary w-100 btn-sm" onClick={() => {this.setState({ popup: { move: true, archive: false } });}} id="current-task-move-button">Siirrä vuodelle</button></div>
                                        <div><button className="my-1 btn btn-outline-primary w-100 btn-sm" onClick={() => {this.setState({ popup: { move: false, archive: true } });}} id="current-task-delete-button">Poista</button></div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3 p-0 ml-md-3 mb-auto" style={{ position: 'relative' }}>
                        <CommentsContainer />
                    </div>
                </div>
                {this.state.popup.move ? <ActionsModal task={this.props.task} close={() => this.setState({ popup: { move: false, archive: false } })} header="Siirrä" action={(year) => this.props.move({ year: this.props.task.year + "", startIndex: this.props.task.order_number - 1, index: 0, toYear: year })}/> : ''}
                {this.state.popup.archive ? <ActionsModal task={this.props.task} close={() => this.setState({ popup: { move: false, archive: false } })} header="Poista" action={() => this.props.delete(this.props.task)}/> : ''}
            </div>
        );
    }
}

CurrentTask.propTypes = {
    move: PropTypes.func.isRequired,
    delete: PropTypes.func.isRequired,
    task: PropTypes.object.isRequired,
    isLoading: PropTypes.bool.isRequired,
    loadingAttachment: PropTypes.bool.isRequired,
    updateTask: PropTypes.func.isRequired,
    currentLocation: PropTypes.object,
    toggleContactModal: PropTypes.func.isRequired,
    deleteTag: PropTypes.func.isRequired,
};

export default CurrentTask;
