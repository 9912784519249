import Sidebar from "../components/Sidebar";
import Loading from "../components/Loading";
import React, { Component } from "react";
import { getFAQ } from "../actions/user";
import { connect } from "react-redux";

export class FaqContainer extends Component {

    componentDidMount() {
        this.props.getFAQ();
    }

    render() {
        return <div className="Page w-100 h-100 d-flex flex-md-row flex-column align-self-center">
            <Loading isLoading={this.props.loading}/>
            <Sidebar location={this.props.currentLocation}/>
            <div className="d-flex flex-column mt-5 align-items-start right col-md-9 col-12">
                <h2>Usein kysytyt kysymykset</h2>
                <div className="d-flex flex-column mt-4">
                    {this.props.faqs.map((faq, i) => <span key={i} className="faq mt-2 pl-3 text-left" style={{ borderLeftColor: '#002b17', borderLeftWidth: '2px', borderLeftStyle: 'solid' }}>
                        <h5>{faq.question}</h5>
                        <p className="text-secondary" dangerouslySetInnerHTML={{ __html: faq.answer }}></p>
                    </span>)}
                </div>
            </div>
        </div>;
    }
}

const mapStateToProps = ({ currentLocation, faqs, loading }) => ({ currentLocation, faqs, loading: loading.faqs });

const mapDispatchToProps = (dispatch) => ({
    getFAQ: () => dispatch(getFAQ())
});

export default connect(mapStateToProps, mapDispatchToProps)(FaqContainer);
