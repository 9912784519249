import { editUser, fetchUserIfNeeded } from "../../actions/user";
import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

export class ContactInformation extends Component {

    constructor(props) {
        super(props);

        this.state = {
            errors: {},
            translations: {
                firstname: 'etunimi',
                lastname: 'sukunimi',
                email: 'sähköposti',
            }
        };

        this.validate = this.validate.bind(this);
    }

    componentDidMount() {
        if (this.props.user !== undefined && this.props.user.email !== undefined) {
            if (this.refs.email.value === '') this.refs.email.value = this.props.user.email;
            if (this.refs.firstname.value === '') this.refs.firstname.value = this.props.user.firstname;
            if (this.refs.lastname.value === '') this.refs.lastname.value = this.props.user.lastname;
        }
    }

    componentDidUpdate() {
        if (this.props.user !== undefined && this.props.user.email !== undefined) {
            if (this.refs.email.value === '') this.refs.email.value = this.props.user.email;
            if (this.refs.firstname.value === '') this.refs.firstname.value = this.props.user.firstname;
            if (this.refs.lastname.value === '') this.refs.lastname.value = this.props.user.lastname;
        }
    }

    validate(values) {
        let valid = true;
        const errors = { ...this.state.errors };
        const keys = Object.keys(values);
        for (let i = keys.length - 1; i >= 0; i--) {
            if (keys[i] === 'email' && !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(values[keys[i]])) {
                errors[keys[i]] = 'Ole hyvä ja tarkista sähköpostiosoite';
                valid = false;
            } else if (values[keys[i]] === '') {
                errors[keys[i]] = 'Ole hyvä ja tarkista ' + this.state.translations[keys[i]];
                valid = false;
            }
        }

        if (!valid) {
            this.setState({ errors });
            return false;
        }

        for (let l = keys.length - 1; l >= 0; l--) {
            delete errors[keys[l]];
        }
        this.setState({ errors });

        return true;
    }

    async done() {
        const values = {
            firstname: this.refs.firstname.value,
            lastname: this.refs.lastname.value,
            email: this.refs.email.value,
        };
        if (this.validate(values)) {
            if (
                this.props.user.email !== values.email ||
                this.props.user.firstname !== values.firstname ||
                this.props.user.lastname !== values.lastname
            ) {
                await this.props.editUser(values);
                this.props.done(values);
            } else {
                this.props.done();
            }
        }
    }

    render() {
        return (
            <div className="">
                <h1>Omat tiedot</h1>
                <p>Tarkista omat tietosi.</p>

                <div className="d-flex">
                    <input className={'form-control col-6 rounded-0 border-primary' + (this.state.errors.firstname !== undefined ? ' error' : '')} type="text" onBlur={(e) => this.validate({ firstname: e.target.value })} ref="firstname" placeholder="Etunimi"/>
                    <input className={'form-control col-6 rounded-0 border-primary ml-2' + (this.state.errors.lastname !== undefined ? ' error' : '')} type="text" onBlur={(e) => this.validate({ lastname: e.target.value })} ref="lastname" placeholder="Sukunimi"/>
                </div>
                <div className="d-flex mt-2">
                    <input className={'form-control col-6 rounded-0 border-primary' + (this.state.errors.email !== undefined ? ' error' : '')} onBlur={(e) => this.validate({ email: e.target.value })} type="email" ref="email" placeholder="Sähköposti"/>
                </div>

                <div className="d-flex mt-5">
                    <div className="col-6"></div>
                    <button className="col-6 btn btn-primary ml-2" id={"steps-" + window.location.pathname.split('/')[2] + "-contact-information-next-button"} onClick={() => this.done()}>Seuraava</button>
                </div>
            </div>
        );
    }
}

ContactInformation.propTypes = {
    done: PropTypes.func.isRequired,
};

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => ({
    editUser: (data) => dispatch(editUser(data)),
    fetchUser: () => dispatch(fetchUserIfNeeded()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ContactInformation);
