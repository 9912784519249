import * as api from "../apis/services";

export const REQUEST_SERVICES = 'REQUEST_SERVICES';
export const REQUEST_SERVICES_SUCCESS = 'REQUEST_SERVICES_SUCCESS';
export const REQUEST_SERVICES_ERROR = 'REQUEST_SERVICES_ERROR';


export const getServices = () => {
    return {
        types: [REQUEST_SERVICES, REQUEST_SERVICES_SUCCESS, REQUEST_SERVICES_ERROR],

        shouldCallAPI: (state) => {
            return true;
        },

        callAPI: () => api.get(),

        payload: {},
    };
};
