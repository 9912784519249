import { fetchYear } from "./locations";

export const SET_OFFSET = 'SET_OFFSET';
export const SET_FILTERS = 'SET_FILTERS';

export const setYearOffset = (offset) => {
    return (dispatch, getState) => {
        const date = new Date();
        const promise = dispatch(fetchYear(date.getFullYear() + parseInt(offset), getState().currentLocation.id));
        if (promise === undefined) {
            return dispatch({
                type: SET_OFFSET,
                offset
            });
        }
        promise.then((response) => {
            dispatch({
                type: SET_OFFSET,
                offset
            });
        });
    };
};

export const setFilters = (tags, searchType) => ({
    type: SET_FILTERS,
    tags,
    searchType
});
