import { REQUEST_LOCATION_SUCCESS, SET_LOCATION, REQUEST_UPDATE_LOCATION_SUCCESS,
    REQUEST_LOCATION_ERROR, WEBSOCKET_UPDATE_LOCATION, WEBSOCKET_CREATE_LOCATION_ATTACHMENT,
    REMOVE_USER , UPDATE_USER, REQUEST_DELETE_TAG_SUCCESS, TAG_CREATED, TAG_DELETED } from "../actions/locations";
import { REQUEST_CREATE_ATTACHMENT_SUCCESS } from "../actions/attachments";
import { push } from "connected-react-router";

export default (state = {}, action) => {
    switch (action.type) {
    case REMOVE_USER:
        if (action.data.location === state.id) {
            if (action.data.removeSelf) {
                action.asyncDispatch(push("/"));
                return {};
            }
            return {
                ...state,
                ...{ users: state.users.filter(user => user.id !== action.data.user) }
            };
        }
        return { ...state };
    case UPDATE_USER:
        return action.data.location === state.id ? {
            ...state,
            ...{ users: [...state.users.map(user => {
                return user.id === action.data.user ? { ...user, ...{ role: parseInt(action.data.role) } } : { ...user };
            })] }
        } : { ...state };
    case REQUEST_CREATE_ATTACHMENT_SUCCESS:
        return action.payload.destination === 'locations' ? state.id === action.data.model.id ? { ...state, ...action.data.model } : { ...state } : { ...state };
    case REQUEST_LOCATION_SUCCESS:
    case REQUEST_DELETE_TAG_SUCCESS:
        return { ...action.data.location };
    case TAG_DELETED:
        if (action.data.model === 'Location' && action.data.modelId === state.id) {
            const location = { ...state };
            const tags = { ...location.tags };
            const parts = action.data.tag.group.split('.');

            if (parts[1] === undefined) {
                tags[parts[0]] = tags[parts[0]].filter(t => t.id !== action.data.tag.id);
            }
            else {
                tags[parts[0]][parts[1]] = tags[parts[0]][parts[1]].filter(t => t.id !== action.data.tag.id);
            }

            return { ...state, ...{ tags } };
        }
        return { ...state };
    case TAG_CREATED:
        if (action.data.model === 'Location' && action.data.modelId === state.id) {
            const location = { ...state };
            const tags = { ...location.tags };
            const parts = action.data.tag.group.split('.');
            if (parts[1] === undefined) {
                tags[parts[0]] = [ ...tags[parts[0]], action.data.tag ];
            }
            else {
                tags[parts[0]][parts[1]] = [ ...tags[parts[0]][parts[1]], action.data.tag ];
            }

            return { ...state, ...{ tags } };
        }
        return { ...state };
    case REQUEST_LOCATION_ERROR:
        action.asyncDispatch(push("/"));
        return {};
    case 'LOGOUT':
    case 'CLEAR_CURRENT_LOCATION':
    case 'MAINTENANCE_MODE':
        if (action.type === 'MAINTENANCE_MODE' && !action.data.mode) {
            return { ...state };
        }
        return {};
    case SET_LOCATION:
        return { ...action.data.location };
    case WEBSOCKET_UPDATE_LOCATION:
    case REQUEST_UPDATE_LOCATION_SUCCESS:
    case WEBSOCKET_CREATE_LOCATION_ATTACHMENT:
        return state.id === action.data.location.id ? { ...state, ...action.data.location } : { ...state };
    default:
        return { ...state };
    }
};
