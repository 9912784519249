import { fetchUserIfNeeded } from "../actions/user";
import HeaderContainer from "./HeaderContainer";
import Loading from "../components/Loading";
import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment";
import axios from "axios";

const url = process.env.REACT_APP_API_URL;

export class Conditions extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            conditions: {},
        };
    }

    componentDidMount() {
        this.props.fetchUserIfNeeded();
        axios.get(url + '/api/conditions')
            .then(response => this.setState({ loading: false, conditions: response.data.conditions }));
    }

    render() {
        if (this.state.loading) {
            return <Loading isLoading={true}/>;
        }
        return <div>
            <HeaderContainer/>
            <div style={{ marginTop: '5rem' }} className="col-md-6 mx-auto">
                <h1 className="mb-3">Sopimusehdot</h1>
                <p>Voimassa {moment(this.state.conditions.date).format('DD.MM.YYYY')} lähtien</p>
                {this.state.conditions.content.map((row, i) => <p key={'p-' + i}>{row}</p>)}
            </div>
        </div>;
    }
}

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => ({
    fetchUserIfNeeded: () => dispatch(fetchUserIfNeeded()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Conditions);
