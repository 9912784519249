import React, { Component } from "react";
import PropTypes from "prop-types";


export class StackedLeft extends Component {

    button() {
        if (this.props.currentPage === this.props.pages - 1)
            return <button className="btn btn-primary mt-5 ml-2 order-2 order-md-1" id="notification-done" style={{ height: '3rem', width: '8rem', zIndex: '100' }} onClick={() => this.props.close()}>Valmis</button>;
        return <button className="btn btn-primary mt-5 ml-2 order-2 order-md-1" id="notification-next" style={{ height: '3rem', width: '8rem', zIndex: '100' }} onClick={() => this.props.changePage(1)}>Seuraava</button>;
    }

    render() {
        return (
            <div className="stacked-left d-flex text-left h-100">
                <div className="col-5 d-none d-md-block" style={{ height: '40rem' }}>
                </div>
                <div className="d-flex flex-column w-100" style={{ marginTop: '8rem', maxHeight: '100%' }}>
                    <h1 style={{ fontSize: '32px', wordBreak: 'break-word' }}>{this.props.page.header}</h1>
                    <p className="mb-0 mb-sm-5" style={{ overflow: 'auto', wordBreak: 'break-word' }}>
                        {this.props.page.content}
                    </p>
                    <div className="d-flex justify-content-between mb-1">
                        <div className="order-1 order-md-2"/>
                        {this.button()}
                    </div>
                </div>
            </div>
        );
    }
}

StackedLeft.propTypes = {
    page: PropTypes.object.isRequired
};

export default StackedLeft;
