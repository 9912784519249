import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Component } from "react";
import PropTypes from "prop-types";

export class Loading extends Component {
    render() {
        return this.props.isLoading ? <div style={{ fontSize: '3rem', position: 'absolute', zIndex: 5, background: 'rgba(255, 255, 255, 0.7)', top: 0, right: 0, bottom: 0, left: 0 }} className="d-flex justify-content-center align-items-center h-100">
            <FontAwesomeIcon icon="spinner" spin/>
        </div> : '';
    }
}

Loading.propTypes = {
    isLoading: PropTypes.bool.isRequired,
};

export default Loading;
