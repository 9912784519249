import { getLocation, fetchYear } from "../actions/locations";
import { downloadExcel } from "../actions/tasks";
import { exportLongTermPlanExcel } from "../actions/locations";
import React, { Component } from "react";
import { Download } from "../svg/icons";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import _ from "lodash";

export class MaintenanceReportContainer extends Component {

    constructor(props) {
        super(props);

        const date = new Date();

        this.state = {
            start: date.getFullYear(),
            end: date.getFullYear() + 4,
            tasks: [],
            mounting: true,
        };
    }

    componentDidMount() {
        this.props.getLocation(this.props.match.params.id);
        this.setState({ mounting: false, tasks: this.mapTasks() });
        this.change = this.change.bind(this);
    }

    shouldComponentUpdate(np, ns) {
        if (!ns.mounting) {
            return true;
        }
        if (this.state.mounting) {
            return false;
        }
        return true;
    }

    componentDidUpdate(np, ns) {
        if (ns.tasks === this.state.tasks) {
            const tasks = this.mapTasks();
            this.setState({ tasks });
        }
    }

    change = _.debounce((val, key) => {
        const value = parseInt(val);
        const tasks = this.mapTasks(key === 'start' ? value : null, key === 'end' ? value : null);
        this.setState({ [key]: value, tasks });
    }, 300);

    mapTasks(s = null, e = null) {
        const start = s || this.state.start;
        const end = e || this.state.end;
        let tasks = [];
        Array.from({ length: 1 + end - start }).forEach((v, i) => {
            if (this.props.currentLocation.id)
                this.props.fetchYear(start + i, this.props.currentLocation.id);
            tasks = [...tasks, ...Object.values(this.props.years[start + i] || {}).filter(id => !this.props.tasks.byId[id].parent_id)];
        });
        return tasks;
    }

    render() {
        return (
            <div className="w-100 h-100 align-self-center">
                <div className="d-flex flex-column mt-5 align-items-start col-md-6 mx-auto">
                    <div className="w-100 d-flex flex-wrap justify-content-between">
                        <h2>Kunnossapitotarveselvitys</h2>
                        <button className="btn btn-primary rounded-1 my-2" style={{ fontWeight: 'bold', padding: '0 0.6rem', height: '3rem' }} id="maintenance-report-download-excell-button" onClick={() => this.props.downloadExcel(this.state.tasks)}><Download className="icon-f-inv icon-cls mr-2"/>Lataa Excel-tiedosto</button>
                        <button className="btn btn-primary rounded-1 my-2" style={{ fontWeight: 'bold', padding: '0 0.6rem', height: '3rem' }} id="maintenance-report-download-excell-button" onClick={() => this.props.exportLongTermPlanExcel([this.props.currentLocation['id']])}><Download className="icon-f-inv icon-cls mr-2"/>Lataa PTS Excel-tiedosto</button>
                    </div>
                    <div className="d-flex">
                        <input className="form-control rounded-0" type="number" defaultValue={this.state.start} max={this.state.end} onChange={(e) => this.change(e.target.value, 'start')} style={{ width: '6rem', border: '1px solid #002b17', color: '#002b17', fontWeight: '500' }}/>
                        <b className="mx-3 mt-1" style={{ color: '#002b17' }}>-</b>
                        <input className="form-control rounded-0" type="number" defaultValue={this.state.end} min={this.state.start} onChange={(e) => this.change(e.target.value, 'end')} style={{ width: '6rem', border: '1px solid #002b17', color: '#002b17', fontWeight: '500' }}/>
                    </div>
                </div>
                <div className="col-12 col-md-6 mx-auto">
                    {this.state.tasks.length ?
                        <table className="table table-borderless mt-4">
                            <thead>
                                <tr>
                                    <th className="text-left text-primary" scope="col">Vuosi</th>
                                    <th className="text-left text-primary" scope="col">Toimenpide</th>
                                    <th className="text-left text-primary" scope="col">Budjetti</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.tasks.map(id => <tr key={id} className="border-bottom task">
                                    <th className="text-left" style={{ width: '25%', fontWeight: '400', color: 'grey' }} scope="row">{this.props.tasks.byId[id].year}</th>
                                    <td className="text-left" style={{ fontWeight: '400', color: 'grey' }}>{this.props.tasks.byId[id].name}</td>
                                    <td className="text-left" style={{ whiteSpace: 'nowrap', fontWeight: '400', color: 'grey' }}>{this.props.tasks.byId[id].total_price || this.props.tasks.byId[id].price || 0} €</td>
                                </tr>)}
                            </tbody>
                        </table>
                        : ''}
                </div>
            </div>
        );
    }
}

MaintenanceReportContainer.propTypes = {
    tasks: PropTypes.object.isRequired,
    years: PropTypes.object.isRequired,
    currentLocation: PropTypes.object.isRequired,
    getLocation: PropTypes.func.isRequired,
    fetchYear: PropTypes.func.isRequired,
    downloadExcel: PropTypes.func.isRequired,
};

const mapStateToProps = ({ currentLocation, tasks, router, loading, years }) => ({ currentLocation, tasks, router, loading, years });

const mapDispatchToProps = (dispatch) => ({
    getLocation: (id) => dispatch(getLocation(id)),
    fetchYear: (year, id) => dispatch(fetchYear(year, id)),
    downloadExcel: (tasks) => dispatch(downloadExcel(tasks)),
    exportLongTermPlanExcel: (locations) => dispatch(exportLongTermPlanExcel(locations))
});

export default connect(mapStateToProps, mapDispatchToProps)(MaintenanceReportContainer);
