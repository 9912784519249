import React, { Component } from "react";
import Dropzone from "react-dropzone";
import PropTypes from "prop-types";


export class DropzoneComponent extends Component {
    constructor() {
        super();
        this.state = {
            files: [],
            defaults: {
                inner: 'Tiputa tiedosto tähän, tai paina lähettääksesi tiedosto.',
                accept: { 'image/jpeg': [],'image/png': [],'image/jpg': [],'application/pdf': [],'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [],'application/vnd.ms-excel': [] },
                className: 'dropzone',
                style: {
                    position: 'relative',
                    width: '200px',
                    height: '200px',
                    borderWidth: '2px',
                    borderColor: 'rgb(102, 102, 102)',
                    borderStyle: 'dashed',
                    borderRadius: '5px',
                }
            }
        };
    }

    onDrop(files) {
        this.setState({
            files: [...this.state.files, ...files]
        });
    }

    removeFile(file) {
        this.setState({ files: [...this.state.files.filter(_file => _file !== file)] });
    }

    render() {
        return (
            <section className="h-100">
                <div style={this.props.outerStyle}>
                    <Dropzone
                        onDrop={this.onDrop.bind(this)}
                        accept={this.props.accept || this.state.defaults.accept}
                        onDropAccepted={files => this.props.onDropAccepted(files)}
                        onDropRejected={this.props.onDropRejected}
                    >
                        {({ getRootProps, getInputProps }) => (
                            <section>
                                <div {...getRootProps()} className={(this.props.className || this.state.defaults.className)} style={{ ...this.state.defaults.style, ...this.props.style }}>
                                    <input {...getInputProps()} />
                                    {this.props.inner || this.state.defaults.inner}
                                </div>
                            </section>
                        )}
                    </Dropzone>
                </div>
            </section>
        );
    }
}

DropzoneComponent.propTypes = {
    style: PropTypes.object,
    className: PropTypes.string,
    accept: PropTypes.string,
    inner: PropTypes.object,
    onDropAccepted: PropTypes.func,
    onDropRejected: PropTypes.func,
};
export default DropzoneComponent;
