import * as api from "../apis/users";

export const REQUEST_USER = 'REQUEST_USER';
export const REQUEST_USER_SUCCESS = 'REQUEST_USER_SUCCESS';
export const REQUEST_USER_ERROR = 'REQUEST_USER_ERROR';

export const REQUEST_UPDATE_USER = 'REQUEST_UPDATE_USER';
export const REQUEST_UPDATE_USER_SUCCESS = 'REQUEST_UPDATE_USER_SUCCESS';
export const REQUEST_UPDATE_USER_ERROR = 'REQUEST_UPDATE_USER_ERROR';

export const REQUEST_INVITE_USER = 'REQUEST_INVITE_USER';
export const REQUEST_INVITE_USER_SUCCESS = 'REQUEST_INVITE_USER_SUCCESS';
export const REQUEST_INVITE_USER_ERROR = 'REQUEST_INVITE_USER_ERROR';

export const REQUEST_REMOVE_USER = 'REQUEST_REMOVE_USER';
export const REQUEST_REMOVE_USER_SUCCESS = 'REQUEST_REMOVE_USER_SUCCESS';
export const REQUEST_REMOVE_USER_ERROR = 'REQUEST_REMOVE_USER_ERROR';

export const REQUEST_CHANGE_ROLE = 'REQUEST_CHANGE_ROLE';
export const REQUEST_CHANGE_ROLE_SUCCESS = 'REQUEST_CHANGE_ROLE_SUCCESS';
export const REQUEST_CHANGE_ROLE_ERROR = 'REQUEST_CHANGE_ROLE_ERROR';

export const REQUEST_JOIN_LOCATION = 'REQUEST_JOIN_LOCATION';
export const REQUEST_JOIN_LOCATION_SUCCESS = 'REQUEST_JOIN_LOCATION_SUCCESS';
export const REQUEST_JOIN_LOCATION_ERROR = 'REQUEST_JOIN_LOCATION_ERROR';

export const REQUEST_FAQ = 'REQUEST_FAQ';
export const REQUEST_FAQ_SUCCESS = 'REQUEST_FAQ_SUCCESS';
export const REQUEST_FAQ_ERROR = 'REQUEST_FAQ_ERROR';

export const fetchUserIfNeeded = () => {
    return {
        types: [REQUEST_USER, REQUEST_USER_SUCCESS, REQUEST_USER_ERROR],

        shouldCallAPI: (state) => {
            return state.auth.isLoggedIn && state.user.id === undefined;
        },

        callAPI: (state) => api.get(),

        payload: {},
    };
};

export const refreshUser = () => {
    return {
        types: [REQUEST_USER, REQUEST_USER_SUCCESS, REQUEST_USER_ERROR],

        shouldCallAPI: (state) => {
            return true;
        },

        callAPI: (state) => api.get(),

        payload: {},
    };
};

export const editUser = (data) => {
    return {
        types: [REQUEST_UPDATE_USER, REQUEST_UPDATE_USER_SUCCESS, REQUEST_UPDATE_USER_ERROR],

        shouldCallAPI: (state) => {
            return true;
        },

        callAPI: () => api.put(data),

        payload: {},
    };
};

export const inviteUser = (location_id, email, role_id) => {
    return {
        types: [REQUEST_INVITE_USER, REQUEST_INVITE_USER_SUCCESS, REQUEST_INVITE_USER_ERROR],

        shouldCallAPI: (state) => {
            return true;
        },

        callAPI: () => api.invite({ max: 1, location_id, email, role_id }),

        payload: {},
    };
};

export const joinLocation = (key) => {
    return {
        types: [REQUEST_JOIN_LOCATION, REQUEST_JOIN_LOCATION_SUCCESS, REQUEST_JOIN_LOCATION_ERROR],

        shouldCallAPI: (state) => {
            return true;
        },

        callAPI: () => api.join(key),

        payload: {},
    };
};

export const removeUser = (location, user) => {
    return {
        types: [REQUEST_REMOVE_USER, REQUEST_REMOVE_USER_SUCCESS, REQUEST_REMOVE_USER_ERROR],

        shouldCallAPI: (state) => {
            return true;
        },

        callAPI: () => api.del(location, user),

        payload: {},
    };
};

export const changeRole = (role, location, user) => {
    return {
        types: [REQUEST_CHANGE_ROLE, REQUEST_CHANGE_ROLE_SUCCESS, REQUEST_CHANGE_ROLE_ERROR],

        shouldCallAPI: (state) => {
            return true;
        },

        callAPI: () => api.role(role, location, user),

        payload: {},
    };
};

export const getFAQ = () => {
    return {
        types: [REQUEST_FAQ, REQUEST_FAQ_SUCCESS, REQUEST_FAQ_ERROR],

        shouldCallAPI: (state) => {
            return true;
        },

        callAPI: () => api.faq(),

        payload: {},
    };
};
