export const flattenDeep = (arr1) => {
    return arr1.reduce((acc, val) =>
        (Array.isArray(val) ?
            acc.concat(flattenDeep(val)) :
            acc.concat(val)),
    []);
};

export const catchEnter = (e, key) => {
    e.persist();
    if ((e.which === e.keyCode) && e.which === 13) {
        e.preventDefault();
        e.target.blur();
    }
};
