import { getLocation, editLocation } from "../actions/locations";
import { getOrganizations } from "../actions/organizations";
import IncomePage from "../components/IncomePage";
import Loading from "../components/Loading";
import React, { Component } from "react";
import { connect } from "react-redux";

export class IncomePageContainer extends Component {

    async componentDidMount() {
        await this.props.getOrganizations();
        this.props.getLocation(this.props.match.params.id);
    }

    save(attributes) {
        const temp = {};
        const fillable = [...this.props.currentLocation.get_fillable, 'id'];
        fillable.map(key => {
            temp[key] = this.props.currentLocation[key];
            return key;
        });
        this.props.editLocation({ ...temp, attributes });
    }

    render() {
        if (!this.props.currentLocation.id) {
            return <Loading isLoading={this.props.loading.currentLocation}/>;
        }
        return <IncomePage currentLocation={this.props.currentLocation} save={(data) => this.save(data)} match={this.props.match}/>;
    }
}

const mapStateToProps = ({ currentLocation, loading }) => ({ currentLocation, loading });

const mapDispatchToProps = (dispatch) => ({
    getLocation: (id) => dispatch(getLocation(id)),
    getOrganizations: () => dispatch(getOrganizations()),
    editLocation: (location) => dispatch(editLocation(location)),
});

export default connect(mapStateToProps, mapDispatchToProps)(IncomePageContainer);
