import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import KiintestokartturiLogoSmall from "../svg/kiinteistokartturi-logo-small-2024.svg";
import { ChevronDown, Chart, Plus, HomeZoom, Grid, Services } from "../svg/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LoginContainer from "../containers/LoginContainer";
import NewLocationModal from "./NewLocationModal";
import { Modal, ModalBody } from "reactstrap";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { flattenDeep } from "../utils";
import UserAvatar from "./UserAvatar";
import history from "../history";
import Circle from "./Circle";

export class Header extends Component {
    constructor(props) {
        super(props);

        this.getFilterTxt = this.getFilterTxt.bind(this);
        this.state = {
            locationsDropdown: false,
            newLocationModal: false,
            filtersDropdown: false,
            filters: {},
            toggleGroup: {},
        };
    }

    toggleDropdown(dropdown = 'filtersDropdown') {
        this.setState(prevState => ({ [dropdown]: !prevState[dropdown] }));
    }

    isTagChecked(parent, key, tag) {
        const state = { ...this.state.filters };

        if (state[parent] === undefined) return false;
        if (key !== undefined && state[parent][key] === undefined) return false;

        if (key === undefined) {
            return state[parent].find(t => (t !== undefined && t.name === tag.name)) !== undefined;
        }

        return state[parent][key].find(t => (t !== undefined && t.name === tag.name)) !== undefined;
    }

    toggleTagsSearchType() {
        const tagSearchType = this.props.filter.searchType === 'with' ? 'or' : 'with';
        this.props.setFilters(this.state.filters, tagSearchType);
    }

    toggleTags(parent, key, tag) {
        const state = { ...this.state.filters };
        if (state[parent] === undefined) state[parent] = key !== undefined ? {} : [];
        if (state[parent][key] === undefined || state[parent][key][0] === undefined) state[parent][key] = [];

        const index = (key !== undefined
            ? state[parent][key] : state[parent]
        ).findIndex(t => t.name === tag.name);

        if (key !== undefined) {
            state[parent][key][index]
                ? delete state[parent][key][index]
                : state[parent][key].push(tag);
            state[parent][key] = state[parent][key].filter(t => t !== undefined && t !== null);
        } else {
            state[parent][index]
                ? delete state[parent][index]
                : state[parent].push(tag);
            state[parent] = state[parent].filter(t => t !== undefined && t !== null);
        }

        this.setState({ filters: state });
        this.props.setFilters(state, this.props.filter.searchType);
    }

    getFilters(filters) {
        if (Array.isArray(filters)) {
            return filters;
        }
        return Object.values(filters).map(t => {
            return this.getFilters(t);
        });
    }

    getFilterTxt() {
        const text = flattenDeep(this.getFilters(this.state.filters)).map(t => t.name).join(', ');
        if (text === '') {
            return 'Näytä kaikki';
        }

        return text;
    }

    year() {
        const date = new Date();
        return date.getFullYear();
    }

    addLocation() {
        this.setState({ newLocationModal: true });
    }

    toggleGroup(group, key) {
        const state = { ...this.state.filters };
        const groupState = { ...this.state.toggleGroup };
        if (state[group] === undefined) {
            state[group] = {};
        }
        if (groupState[key] === undefined) {
            groupState[key] = false;
        }
        groupState[key] = groupState[key] ? false : true;

        if (groupState[key]) {
            state[group][key] = key !== 'custom'
                ? [...this.props.currentLocation.tags[group][key]]
                : [...this.props.currentLocation.tags[group]];
        } else {
            state[group][key] = [];
        }

        this.setState({ filters: state, toggleGroup: groupState });
        this.props.setFilters(state, this.props.filter.searchType);
    }

    isGroupChecked(group) {
        return this.state.toggleGroup[group] ? true : false;
    }

    render() {
        return <header
            className="Header d-flex flex-column w-100"
            style={{ backgroundColor: '#f7f7f7', position: 'fixed', zIndex: 5, top: 0, left: 0, borderTop: '4px solid #002b17' }}
        >
            <div className="border-bottom d-flex justify-content-between align-items-center px-3 w-100">
                <img src={KiintestokartturiLogoSmall} className="logo d-sm-block d-none" style={{ maxHeight: '3rem' }} alt=""/>

                <div className="d-flex align-items-center py-2">
                    {this.props.currentLocation.name !== undefined ? <Dropdown isOpen={this.state.locationsDropdown} toggle={() => this.toggleDropdown('locationsDropdown')} className="ml-3" direction="down">
                        <DropdownToggle color="link" id="header-locations-dropdown-trigger">
                            <div className="d-flex align-items-center py-2">
                                <h3 className="my-0 mr-2 c-pointer">{this.props.currentLocation.name}</h3>
                                <Circle type="outline" size="small" style={{ width: '1.38rem', height: '1.38rem' }} className="c-pointer">
                                    <ChevronDown className="icon-s nohover"/>
                                </Circle>
                            </div>
                        </DropdownToggle>
                        <DropdownMenu>
                            {this.props.locations && Object.values(this.props.locations.all).map(location => <DropdownItem key={'locations-dropdown' + this.props.locations.byId[location].id} tag="a" className="d-flex align-items-center c-pointer" onClick={() => this.props.push(`/${this.props.locations.byId[location].id}/${this.props.locations.byId[location].slug}`)}>
                                <img className="mr-3" style={{ maxWidth: '63px', maxHeight: '63px' }} src={(this.props.locations.byId[location].thumbnail || '')} alt=""/>
                                <span>{this.props.locations.byId[location].name}</span>
                            </DropdownItem>)}
                            {<DropdownItem key={'locations-dropdown-add'} tag="a" className="d-flex mx-auto c-pointer mt-1" style={{ width: 'min-content' }} onClick={() => this.addLocation()}>
                                <Plus className="icon-f nohover"/>
                                <span>Lisää kohde</span>
                            </DropdownItem>}
                        </DropdownMenu>
                    </Dropdown>
                        :
                        <div className="d-flex">
                            <div className="d-flex align-items-center c-pointer" style={{ backgroundColor: '#002b17', padding: '0.4em 0.6em' }} onClick={() => this.addLocation()}>
                                <Plus className="icon-f-inv nohover"/>
                                <span style={{ color: 'white', fontWeight: 'bold' }}>Lisää kohde</span>
                            </div>
                            <div className="d-flex ml-2 align-items-center c-pointer" style={{ backgroundColor: '#002b17', padding: '0.4em 0.6em' }} onClick={() => history.push({ pathname: `/start/rs15`, state: { origin: 'nav' } })}>
                                <span style={{ color: 'white', fontWeight: 'bold' }}>Liity koodilla</span>
                            </div>
                        </div>
                    }

                </div>

                <div className="d-flex align-items-center">
                    {this.props.currentLocation.name !== undefined ?
                        <div className="d-flex">
                            <div className="d-flex mr-auto bg-primary c-pointer pr-0 pr-md-2" onClick={() => this.props.toggleContactModal('Palaute ja ideat')} id="header-feedback-button">
                                <Circle type="solid" size="large" className="grey" style={{ fontSize: '1.25rem' }}><FontAwesomeIcon icon="envelope"/></Circle>
                                <p className="mb-0 d-none d-md-block" style={{ marginTop: '0.3rem', fontSize: '1rem', fontWeight: 500, color: 'white' }}>Anna palautetta</p>
                            </div>
                            <div className="mx-0 mx-md-2"></div>
                            <Circle style={{ cursor: 'pointer' }} className="bg-white" type="outline" size="small" onClick={() => this.props.push({ pathname: `/${this.props.currentLocation.id}/${this.props.currentLocation.slug}/invite`, state: { modal: true } })} id="header-invite-user-button">
                                <Plus className="icon-f nohover"/>
                            </Circle>
                            {this.props.users.map((user, index) => <Circle key={index} type="outline" size="small" style={{ fontSize: '1.75rem', overflow: 'hidden' }}>
                                {user.thumbnail ?
                                    <img alt="/" src={user.thumbnail}/>
                                    :
                                    <UserAvatar user={user}/>}
                            </Circle>
                            )}
                        </div>
                        :
                        ''
                    }
                    &nbsp;
                    &nbsp;
                    <LoginContainer/>
                </div>
            </div>
            {this.props.currentLocation.name !== undefined ?
                <div className="d-md-flex justify-content-between align-items-center pt-2 pb-3 px-3 w-100 bg-white">
                    <div className="d-flex justify-content-start">
                        <div className="d-flex w-100">
                            <div style={{ minWidth: '41px' }}>
                                <Link className="header-link btn btn-outline-primary d-flex align-items-center w-auto" to={`/`} id="header-home-link">
                                    Koti
                                </Link>
                            </div>
                            <span className="border-right h-100 mx-2" style={{ width: '1px' }}/>
                            <Link className="header-link mr-2 btn btn-outline-primary d-flex align-items-center" to={`/${this.props.currentLocation.id}/${this.props.currentLocation.slug}`} style={{ minWidth: '41px' }} id="header-tasks-link">
                                <Grid className="icon-f"/>
                                <span className="d-none d-lg-block ml-2">Toimenpiteet</span>
                            </Link>
                            <Link className="header-link mr-2 btn btn-outline-primary d-flex align-items-center" to={`/${this.props.currentLocation.id}/${this.props.currentLocation.slug}/chart`} style={{ minWidth: '41px' }} id="header-budjet-link">
                                <Chart className="icon-f"/>
                                <span className="d-none d-lg-block ml-2">Budjetti</span>
                            </Link>
                            <Link className="header-link btn btn-outline-primary d-flex align-items-center" to={`/${this.props.currentLocation.id}/${this.props.currentLocation.slug}/report`} style={{ minWidth: '41px' }} id="header-maintenance-report-link">
                                <HomeZoom className="icon-f" style={{ overflow: 'visible' }}/>
                                <span className="d-none d-lg-block ml-2">Kunnossapitotarveselvitys</span>
                            </Link>
                            <span className="border-right h-100 mx-2" style={{ width: '1px' }}/>
                            <a href={process.env.REACT_APP_SERVICES_URL} target="_blank" rel="noreferrer" className="header-link btn btn-outline-primary d-flex align-items-center" style={{ minWidth: '41px' }} id="header-services-link">
                                <Services className="icon-f" style={{ overflow: 'visible' }}/>
                                <span className="d-none d-lg-block ml-2">Palvelut</span>
                            </a>
                            <span className="border-right h-100 mx-2" style={{ width: '1px' }}/>
                            <button
                                className="header-link btn btn-primary d-flex align-items-center mx-auto"
                                onClick={() => this.props.push({ pathname: `/${this.props.currentLocation.id}/${this.props.currentLocation.slug}/tasks/new`, state: { modal: true, year: `${this.year()}` } })}
                                id="header-new-task-button"
                            >
                                <Plus className="icon-f-inv mr-sm-2"/>
                                <span className="d-none d-sm-block">Lisää tehtävä</span>
                            </button>
                        </div>
                    </div>
                    <hr className="d-block d-md-none"/>
                    <div className="d-flex">

                        <span className="d-flex flex-column w-100">
                            <span className="slider-years d-flex justify-content-between pb-0" style={{ minWidth: '13em', height: 24 }}>
                                <p>4v</p>
                                <p>8v</p>
                                <p>12v</p>
                            </span>
                            <input className="slider pt-0" type="range" min="4" max="12" defaultValue={this.props.filter.offset} onChange={(e) => this.props.setYearOffset(e.target.value)} id="header-years-slider"/>
                        </span>
                        <input type="button" onClick={() => this.toggleDropdown()} className="header-link btn btn-outline-primary w-100" style={{ maxWidth: '300px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }} value={this.getFilterTxt()} id="header-filter-dropdown-trigger"/>
                        <Modal isOpen={this.state.filtersDropdown} toggle={() => this.setState({ filtersDropdown: false })} style={{ width: '95%', maxWidth: '95%' }}>
                            <ModalBody>
                                <div className="col-md-9 col-12" style={{ position: 'relative', maxWidth: '65rem', margin: 'auto' }}>
                                    <Circle type="outline" size="small" className="mt-2 c-pointer bg-white" id="new-task-close-button" onClick={() => this.setState({ filtersDropdown: false })} style={{ position: 'absolute', zIndex: 2, right: '0', top: '-2rem', width: '1.5rem', height: '1.5rem', fontSize: '1rem' }}>X</Circle>
                                </div>
                                <div className="py-2 px-5 px-md-3 form-group mb-0" style={{ background: '#f7f7f7' }}>
                                    <div className="form-check">
                                        <input type="checkbox" className="form-check-input" id="searchType" checked={this.props.filter.searchType === 'with'} onChange={() => this.toggleTagsSearchType()}/>
                                        <label htmlFor="searchType" className="form-check-label">Kaikkien ehtojen tulee täyttyä</label>
                                    </div>
                                </div>
                                <div className="d-flex flex-md-row flex-column" style={{ maxHeight: '90vh', overflowY: 'auto' }}>
                                    <div className="col-12 col-md-6 mx-0 px-0 d-flex flex-wrap flex-md-nowrap" ref={(el) => {
                                        if (el) el.style.setProperty('margin-right', '0.4rem', 'important');
                                    }}>
                                        {this.props.currentLocation.tags !== undefined ? Object.keys(this.props.currentLocation.tags.rakenne).sort().map(key => <div key={key + '-filter'} className="p-5 px-md-3 pt-md-4 tag-container col-12 col-md-4">
                                            <div key={key + '-filter-title'}><span className="text-capitalize text-bold text-primary mb-3 d-inline-block d-flex"><input id={'groupTitleId-' + key} type="checkbox" value="" checked={this.isGroupChecked(key)} onChange={() => this.toggleGroup('rakenne', key)} className="mr-2 mt-1"/><label htmlFor={'groupTitleId-' + key}>{key.replace(/\d\s/, '')}</label></span>
                                            </div>
                                            {this.props.currentLocation.tags.rakenne[key].map(tag => <div className="form-group py-0 my-0" key={'tag-' + tag.name.replace(' ', '-') + (tag.id || '')}>
                                                <div className="form-check mb-1">
                                                    <input id={tag.id} type="checkbox" checked={this.isTagChecked('rakenne', key, tag)} onChange={() => this.toggleTags('rakenne', key, tag)} value="" className="tag form-check-input mt-1"/>
                                                    <label htmlFor={tag.id} className="form-check-label">{tag.name}</label>
                                                </div>
                                            </div>)}
                                        </div>) : ''}
                                    </div>
                                    <div className="col-12 col-md-6 mx-0 px-0 d-flex flex-wrap flex-md-nowrap">
                                        {this.props.currentLocation.tags !== undefined ? Object.keys(this.props.currentLocation.tags.extra).sort().map(key => <div key={key + '-filter'} className="p-5 px-md-3 pt-md-4 tag-container col-12 col-md-3" style={{ minWidth: '6rem' }}>
                                            <div key={key + '-filter-title'}><h6 className="text-capitalize d-flex" style={{ fontSize: '0.75rem' }}><input id={'groupTitleId-' + key} type="checkbox" value="" checked={this.isGroupChecked(key)} onChange={() => this.toggleGroup('extra', key)} className="mr-2"/><label htmlFor={'groupTitleId-' + key}>{key}</label></h6></div>
                                            {this.props.currentLocation.tags.extra[key].map(tag => <div className="form-group py-0 my-0" key={'tag-' + tag.name.replace(' ', '-') + (tag.id || '')}>
                                                <div className="form-check">
                                                    <input id={tag.id} type="checkbox" checked={this.isTagChecked('extra', key, tag)} onChange={() => this.toggleTags('extra', key, tag)} value="" className="tag form-check-input mt-1"/>
                                                    <label htmlFor={tag.id} className="form-check-label">{tag.name}</label>
                                                </div>
                                            </div>)}
                                        </div>) : ''}


                                        {this.props.currentLocation.tags !== undefined ? Object.keys({ custom: this.props.currentLocation.tags.custom }).map(key => <div key={key + '-filter'} className="p-5 px-md-3 pt-md-4 tag-container col-12 col-md-3">
                                            <div key={key + '-filter-title'}><h6 className="text-capitalize d-flex" style={{ fontSize: '0.75rem' }}><input id={'groupTitleId-' + key} type="checkbox" value="" checked={this.isGroupChecked(key)} onChange={() => this.toggleGroup('custom', key)} className="mr-2"/><label htmlFor={'groupTitleId-' + key}>Aihe</label></h6></div>
                                            {this.props.currentLocation.tags[key].map(tag => <div className="form-group py-0 my-0" key={'tag-' + tag.name.replace(' ', '-') + (tag.id || '')}>
                                                <div className="form-check">
                                                    <input id={tag.id} type="checkbox" checked={this.isTagChecked('custom', key, tag)} onChange={() => this.toggleTags('custom', key, tag)} value="" className="tag form-check-input mt-1"/>
                                                    <label htmlFor={tag.id} className="form-check-label">{tag.name}</label>
                                                </div>
                                            </div>)}
                                        </div>) : ''}
                                    </div>
                                </div>
                            </ModalBody>
                        </Modal>
                    </div>
                </div>
                :
                ''
            }
            {this.state.newLocationModal ?
                <NewLocationModal closeModal={() => this.setState({ newLocationModal: false })}/>
                : ''}
        </header>;
    }
}

export default Header;
