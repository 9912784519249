import KiintestokartturiLogoSmall from "../svg/kiinteistokartturi-small.svg";
import ContactInformation from "../components/Steps/ContactInformation";
import OrderBilling from "../components/Steps/OrderBilling";
import { getOrganization } from "../actions/organizations";
import { fetchUserIfNeeded } from "../actions/user";
import { getLocations } from "../actions/locations";
import Invite from "../components/Steps/Invite";
import { login } from "redux-implicit-oauth2";
import Users from "../components/Steps/Users";
import LoginBox from "../components/LoginBox";
import { refreshUser } from "../actions/user";
import * as api from "../apis/organizations";
import Loading from "../components/Loading";
import React, { Component } from "react";
import { connect } from "react-redux";
import "../index.scss";

const config = {
    url: process.env.REACT_APP_API_URL + "/oauth/azure-b2c/authorize",
    client: process.env.REACT_APP_OAUTH_CLIENT_ID,
    redirect: process.env.REACT_APP_REDIRECT_URL,
    scope: "",
};

const Steps = {
    ContactInformation,
    Invite,
    OrderBilling,
    Users,
};
const instructions = 'https://kiinteistokartturifiles.blob.core.windows.net/public-files/Kiinteistokartturi_kuinka_taloyhtio_ottaa_Kiinteistokartturin_kayttoon_09-2019_v1.pdf';

export class StartStepsRS15 extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: this.props.auth.isLoggedIn ? true : null,
            loaded: false,
            errors: [],
            currentStep: 1,
            steps: {
                1: { name: 'Aloituskoodi', component: 'Invite' },
                2: { name: 'Omat tiedot', component: 'ContactInformation' },
                3: { name: 'Sopimus ja laskutus', component: 'OrderBilling' },
                4: { name: 'Käyttäjät', component: 'Users' },
            },
            stepProps: {},
            values: {},
            invitation: {},
            location: {},
        };
    }

    async componentDidMount() {
        if (this.props.auth.isLoggedIn && !this.state.loaded) {
            await this.init();
        }
    }

    async componentDidUpdate(prevProps, prevState) {
        if (this.props.auth.isLoggedIn && !this.state.loading && !this.state.loaded && prevState.stepProps[1] === undefined) {
            await this.init();
        }
    }

    async init() {
        this.setState({ loading: true });
        await this.props.getLocations();
        const promises = await Promise.all([
            this.props.fetchUserIfNeeded(),
            api.plans('rs15'),
        ]);
        const location = this.props.invitation.location_id !== undefined ? this.props.locations.byId[this.props.invitation.location_id] : undefined;
        if (location !== undefined) {
            this.setState({ location });
            if (location.organization_id !== null) {
                await this.props.getOrganization(location.organization_id);
            }
        }

        let step = this.state.currentStep || 1;
        if (this.props.invitation.key !== undefined) {
            if (this.props.user.id !== undefined && this.props.user.firstname === '' && this.props.user.lastname === '') {
                step = 1;
            }

            if (location !== undefined) {
                if (location.organization_id === null) {
                    step = 2;
                } else {
                    step = 3;
                }
            }

        }
        this.setState({
            loading: false,
            loaded: true,
            stepProps: {
                1: { token: this.props.match.params.token || undefined, history: this.props.history, auth: this.props.auth },
                2: { user: this.props.user },
                3: { user: this.props.user, plans: promises[1].data.plans, organization: this.props.organization },
                4: { location: location, user: this.props.user },
            },
            currentStep: step,
        });
    }

    async setDone(values = {}) {
        const nextStep = this.state.currentStep === 4 ? true : this.state.currentStep + 1;
        if (nextStep === true) {
            await this.props.refreshUser();
            this.props.history.push({ pathname: `/`, state: { modal: false } });
        } else {
            let stepProps = this.state.stepProps;
            if (this.state.currentStep === 1) {
                await this.init();
                await this.props.refreshUser();
            }
            if (this.state.currentStep === 2) {
                await this.props.refreshUser();
                stepProps = { ...stepProps, ...{ 3: { ...stepProps[3], ...{ append: { location_id: this.state.location.id } } } } };
            }

            if (this.state.currentStep === 3) {
                if (this.state.location !== undefined) {
                    stepProps = { ...stepProps, ...{ 4: { location: this.state.location } } };
                }
            }

            if (this.state.currentStep === 4) {
                stepProps = { ...stepProps, ...{ 5: { location: this.state.location } } };
            }

            this.setState({
                values: { ...this.state.values, ...values },
                steps: {
                    ...this.state.steps,
                    ...{
                        [this.state.currentStep]: {
                            ...this.state.steps[this.state.currentStep],
                            ...{ done: true }
                        }
                    }
                },
                stepProps,
                currentStep: parseInt(this.state.currentStep) + 1
            });
        }
    }

    render() {
        const StepContent = Steps[this.state.steps[this.state.currentStep].component];
        return <div className="App" style={{ marginTop: '-4.5rem' }}>
            <nav className="navbar navbar-expand-lg navbar-light fixed-top d-flex justify-content-center" style={{ backgroundColor: '#f7f7f7', borderBottom: '1px solid #d8d8d8' }}>
                <img src={KiintestokartturiLogoSmall} className="navbar-brand" style={{ maxHeight: '3rem' }} alt=""/>
            </nav>
            {!this.props.auth.isLoggedIn ? <div className="mt-5"><LoginBox instructions={instructions} instructions_txt="Ohje (pdf)" login={this.props.login}/></div> : this.state.loading ? <Loading isLoading={true}/> :
                <div className="start-steps d-flex justify-content-start flex-column align-items-center">
                    <div className="d-flex justify-content-between col-12 col-md-6 p-4 align-items-start">
                        {Object.keys(this.state.steps).map((step) => <div className={'step-header d-flex align-items-center justify-content-center flex-column' + (step === this.state.currentStep ? ' current' : '')} key={'step-' + step}>
                            <span className="circle d-flex align-items-center justify-content-center border"><h2 className="m-0 p-0">{step}</h2></span>
                            <span>{this.state.steps[step].name}</span>
                        </div>)}
                    </div>
                    <div className="step-container col-12 col-md-6 text-left px-5 py-4">
                        {this.state.loading !== false ? '' : <StepContent {...this.state.stepProps[this.state.currentStep]} done={(values) => this.setDone(values)} error={(errors) => this.setState({ errors })}/>}
                    </div>
                </div>}

        </div>;
    }
}

const mapStateToProps = ({ auth, user, organization, locations, invitation }) => ({ auth, user, organization, locations, invitation });

const mapDispatchToProps = (dispatch) => ({
    fetchUserIfNeeded: () => dispatch(fetchUserIfNeeded()),
    getOrganization: () => dispatch(getOrganization()),
    getLocations: () => dispatch(getLocations()),
    login: () => dispatch(login(config)),
    refreshUser: () => dispatch(refreshUser()),
});

export default connect(mapStateToProps, mapDispatchToProps)(StartStepsRS15);
