import CurrentTaskContainer from "../containers/CurrentTaskContainer";
import { Modal, ModalBody } from "reactstrap";
import React, { Component } from "react";
import Circle from "./Circle";

export class TaskModal extends Component {
    goBack() {
        this.props.history.push({ pathname: `/${this.props.match.params.id}/${this.props.match.params.slug || ''}`, state: { modal: false } });
    }

    render() {
        return <Modal isOpen={true} toggle={() => this.goBack()} style={{ width: '95%', maxWidth: '95%' }}>
            <ModalBody style={{ minWidth: '60%', maxWidth: '1400px', left: 0, right: 0, top: 0, bottom: 0, margin: 'auto' }}>
                <div className="col-md-9" style={{ position: 'relative' }}>
                    <Circle type="outline" size="small" className="mt-2 c-pointer bg-white" id="current-task-close-button" onClick={() => this.goBack()} style={{ position: 'absolute', zIndex: 2, right: '-1rem', top: '-2rem', width: '1.5rem', height: '1.5rem', fontSize: '1rem' }}>X</Circle>
                </div>
                <CurrentTaskContainer match={this.props.match}/>
            </ModalBody>
        </Modal>;
    }
}

export default TaskModal;
