import React from "react";
import { connect } from "react-redux";
import { Route, Redirect } from "react-router-dom";

export const PrivateRoute = ({ component: Component, ...rest }) => {
    if (!rest.isLoggedIn) {
        return <Redirect to='/login' />;
    }

    return <Route
        {...rest}
        render={props => <Component {...props} />}
    />;
};

const mapStateToProps = ({ auth }) => ({
    isLoggedIn: auth.isLoggedIn
});

export default connect(mapStateToProps)(PrivateRoute);
