import { searchZipcodes, createLocation } from "../../actions/locations";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import React, { Component } from "react";
import { connect } from "react-redux";
import { catchEnter } from "../../utils";
import PropTypes from "prop-types";

export class LocationInformation extends Component {

    constructor(props) {
        super(props);

        this.state = {
            errors: {},
            translations: {
                name: 'yhtiön nimi',
                address: 'postiosoite',
                zipcode_id: 'postinumero',
                city: 'postitoimipaikka',
            },
            query_zip: '',
            zipcode_id: 0,
            zip_name_options: [],
            zip_options: [],
            selected_zip: [],

            city_options: {},
        };

        this.validate = this.validate.bind(this);
        this.onSearch = this.onSearch.bind(this);
        this.blurZip = this.blurZip.bind(this);
    }

    validate(values) {
        let valid = true;
        const errors = { ...this.state.errors };
        const keys = Object.keys(values);
        for (let i = keys.length - 1; i >= 0; i--) {
            if (values[keys[i]] === '') {
                errors[keys[i]] = 'Ole hyvä ja tarkista ' + this.state.translations[keys[i]];
                valid = false;
            } else {
                if (errors[keys[i]]) {
                    delete errors[keys[i]];
                }
            }
        }

        if (!valid) {
            this.setState({ errors });
            this.props.error(Object.values(errors));
            return false;
        }
        for (let l = keys.length - 1; l >= 0; l--) {
            delete errors[keys[l]];
        }
        this.setState({ errors });
        this.props.error(Object.values(errors));

        return true;
    }

    done() {
        const values = {
            name: this.refs.name.value,
            address: this.refs.address.value,
            zipcode_id: this.refs.zip.value > 0 ? this.refs.zip.value : '',
            city: this.refs.city.value,
        };
        if (this.validate(values)) {
            this.props.createLocation({ ...values, organization_id: this.props.organization.id }).then(r => {
                r().then(r => this.props.done());
            });
        }
    }

    onSearch(query) {
        this.setState({ query_zip: query });
        return this.props.searchZipcodes({ name: '%', zip: this.state.query_zip || '%' })
            .then(response => {
                if (!this.state.query_zip) {
                    return Promise.resolve(Object.values(response.data.results));
                }
                this.setState({
                    city_options: { ...response.data.results },
                    zip_name_options: [...new Set(Object.values(response.data.results).map(zip => zip.name))],
                    zip_options: Object.values(response.data.results).map(zip => zip.zip),
                    zipcode_id: Object.keys(response.data.results)[0],
                });
                return Promise.resolve(Object.values(response.data.results));
            });
    }

    blurZip(e) {
        if (!this.state.zip_options[0])
            return;
        this.setState({ selected_zip: [this.state.zip_options[0]], query_zip: this.state.zip_options[0] });
    }

    render() {
        return (
            <div className="">
                <h1>Yhtiön tiedot</h1>
                <p>Ole hyvä ja täytä yhtiön perustiedot. Tiedot ovat pakollisia, sillä niitä käytetään Kiinteistökartturissa esimerkiksi budjettilaskennan pohjana.</p>

                <div>
                    <div className="d-flex flex-column flex-md-row">
                        <input className={'form-control rounded-0 border-primary mt-2 mt-md-0' + (this.state.errors.name !== undefined ? ' error' : '')} type="text" onBlur={(e) => this.validate({ name: e.target.value })} ref="name" placeholder={this.state.translations.name}
                            defaultValue={this.props.organization !== undefined && this.props.organization.id !== undefined ? this.props.organization.name : ''}/>
                        <input className={'form-control rounded-0 border-primary ml-md-2 mt-2 mt-md-0' + (this.state.errors.address !== undefined ? ' error' : '')} type="text" onBlur={(e) => this.validate({ address: e.target.value })} ref="address" placeholder={this.state.translations.address}/>
                    </div>
                    <div className="d-flex flex-column flex-md-row mt-md-2">
                        <input className={'form-control rounded-0 border-primary mt-2 mt-md-0'} defaultValue={this.state.zipcode_id} type="hidden" ref="zip"/>
                        <AsyncTypeahead
                            id="zipcode-typehead"
                            className={'zipSearch form-control rounded-0 border-primary mt-2 mt-md-0' + (this.state.errors.zipcode_id !== undefined ? ' error' : '')}
                            isLoading={false}
                            onChange={selected_zip => this.setState({ selected_zip, query_zip: selected_zip[0] })}
                            selected={this.state.selected_zip || null}
                            onSearch={(query) => this.onSearch(query)}
                            options={this.state.zip_options}
                            useCache={false}
                            promptText="Postinumero"
                            placeholder="Postinumero"
                            onKeyDown={(e) => catchEnter(e)}
                            onBlur={(e) => this.blurZip(e)}
                        />
                        <input className={'form-control rounded-0 border-primary ml-md-2 mt-2 mt-md-0' + (this.state.errors.city !== undefined ? ' error' : '')} onBlur={(e) => this.validate({ city: e.target.value })} type="text" ref="city" readOnly defaultValue={this.state.city_options[this.state.zipcode_id] !== undefined ? this.state.city_options[this.state.zipcode_id].name : ''}
                            placeholder={this.state.translations.city}/>
                    </div>
                </div>

                <div className="d-flex mt-5">
                    <div className="col-6"></div>
                    <button className="col-6 btn btn-primary ml-2" id={"steps-" + window.location.pathname.split('/')[2] + "-location-information-next-button"} onClick={() => this.done()}>Seuraava</button>
                </div>
            </div>
        );
    }
}

LocationInformation.propTypes = {
    done: PropTypes.func.isRequired,
};

const mapStateToProps = ({ organization }) => ({ organization });

const mapDispatchToProps = (dispatch) => ({
    searchZipcodes: (query) => dispatch(searchZipcodes(query)),
    createLocation: (data) => dispatch(createLocation(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LocationInformation);
