import KiintestokartturiLogoSmall from "./svg/kiinteistokartturi-logo-small-2024.svg";
import KiintestokartturiLogo from "./svg/kiinteistokartturi-logo-full-2024.svg";
import frontPageActions from "./images/toimenpiteet_2024.png";
import frontPageIpad from "./images/toimenpide_auki_2024.png";
import frontPageBudget from "./images/etusivu_budjetti_2024.png";
import LoginContainer from "./containers/LoginContainer";
import { Envelope, Phone, World } from "./svg/icons";
import { Redirect } from "react-router-dom";
import React, { Component } from "react";
import { connect } from "react-redux";
import RsOrIndividual from "./components/Steps/RsOrIndividual";

export class Landing extends Component {
    render() {
        if (this.props.auth.isLoggedIn) {
            return <Redirect to="/" />;
        }

        return (
            <div className="App LandingPage" style={{ marginTop: '-0rem' }}>
                <nav className="navbar navbar-expand-lg navbar-light bg-white d-flex justify-content-center justify-content-md-between align-items-center px-lg-3 container">
                    <img src={KiintestokartturiLogo} className="navbar-brand" style={{ maxHeight: '7rem' }} alt="" />
                    <div className="w-100 w-md-auto d-flex justify-content-between justify-content-md-end">
                        <LoginContainer className="ml-auto" />
                        <a href="/start" className="ml-3 ml-md-0 btn btn-secondary">Kokeile ilmaiseksi</a>
                    </div>
                </nav>
                <div className="bg-white d-block d-flex flex-column-reverse flex-md-row text-left px-2 px-md-4">
                    <div className="container row mx-auto">
                        <div className="col-12 col-md-4 pt-4">
                            <h1 className="text-primary" style={{ fontSize: '3.5rem' }}>Helpotusta taloyhtiön arkeen</h1>
                            <p className="text-primary">Kiinteistökartturi on taloyhtiön hallituksen ja isännöitsijän työkalu kiinteistön ennakoivaan ylläpitoon. Palvelu on yhteensopiva Susteran Kuntoarvion sekä kuntotutkimusten kanssa. Kiinteistökartturi havainnollistaa taloyhtiöiden korjaushankkeiden hallinnan niin isoille kuin pienillekin taloyhtiöille.</p>
                        </div>
                        <div className="col-12 col-md-8 d-flex position-relative pb-3 pb-md-5 pt-5 pt-md-0 leader-group">
                            <img src={frontPageBudget} alt="" className="behind align-self-end mb-5" />
                            <img src={frontPageActions} alt="" className="front position-absolute align-self-end" />
                        </div>
                    </div>
                </div>
                <div className="bg-gray">
                    <RsOrIndividual />
                    <div className="bg-white d-flex  py-3 py-md-5">
                        <div className="container row mx-auto">
                            <div className="col-12 col-md-6 d-flex text-left flex-column flex-md-row">
                                <img src={KiintestokartturiLogoSmall} className="w-25 align-self-start mb-3 mr-3" alt="" />
                                <div>
                                    <h3>Ennennäkemättömän helppo tapa pitää taloyhtiö kunnossa</h3>
                                    <p>Kiinteistökartturi on sähköinen palvelu taloyhtiön korjaushankkeiden hallintaan. Palvelun avulla hallituksen on helppo suunnitella ja toteuttaa tulevat remontit, kun taloyhtiön tiedot pysyvät tallessa myös taloyhtiön avainhenkilöiden vaihtuessa ja niihin voi palata milloin tahansa.</p>
                                    <h5>Sujuvaa  suunnittelua ja tilaamista</h5>
                                    <p>Huoltotoimenpiteiden ja korjaushankkeiden aikataulutus, suunnittelu ja budjetointi on vaivatonta ja ne on esitetty havainnollisesti.</p>
                                </div>
                            </div>
                            <div className="col-12 col-md-6 d-none d-md-block">
                                <img src={frontPageIpad} className="w-75" alt="" />
                            </div>
                        </div>
                    </div>
                    <div className=" bg-primary pt-5 pb-5">
                        {/* <div className="d-block d-md-flex px-1 px-md-5 container">*/}
                        {/*    <div className="col-12 col-md-6">*/}
                        {/*        <iframe width="560" height="315" src="https://www.youtube.com/embed/qtbql6NOeAo?rel=0" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen title="Kiinteistökartturi" style={{ maxWidth: '100%' }}></iframe>*/}
                        {/*    </div>*/}
                        {/*    <div className="col-12 col-md-6 text-white text-left">*/}
                        {/*        <h3 className="text-uppercase font-weight-bold text-white">Katso videolta kuinka Kiinteistökartturi toimii ja lataa esite</h3>*/}
                        {/*        <p>Video esittelee miten Kiinteistökartturi helpottaa yhtiön pitkän tähtäimen suunnittelua ja auttaa varmistamaan, että kaikki tärkeät dokumentit ovat aina tallessa yhdessä paikassa. Lisäksi Kiinteistökartturin avulla on yksinkertaista havainnollistaa miten korjaushankkeet vaikuttavat yhtiön budjettiin ja osakkaiden vastikkeisiin.</p>*/}
                        {/*        <p>Jos haluat keskustella Kiinteistökartturin käytöstä yhtiön muiden henkilöiden kanssa, voit ladata tulostettavan esitteen.</p>*/}

                        {/*        <a href="https://kiinteistokartturifiles.blob.core.windows.net/public-files/Kiinteistokartturi_web.pdf" target="_blank" rel="noopener noreferrer" className="btn btn-secondary">Lataa esite</a>*/}
                        {/*    </div>*/}
                        {/* </div>*/}

                        <div className="mt-4 border-top border-secondary">
                            <h3 className="mt-4 text-white d-flex flex-column flex-md-row justify-content-md-center text-left">
                                <div className="ml-3 ml-md-0">
                                    <Phone className="icon-f-inv icon-cls mr-2" />
                                    030 670 5500*
                                </div>

                                <div className="ml-3">
                                    <Envelope className="icon-f-inv icon-cls mr-2" />
                                    asiakaspalvelu@sustera.com
                                </div>

                                <div className="ml-3">
                                    <World className="icon-f-inv icon-cls mr-2" />
                                    <a href="https://www.sustera.fi" rel="noopener noreferrer" target="_blank" className="text-white">www.sustera.fi</a>
                                </div>
                            </h3>
                            <p className="text-white text-left text-md-center mt-3 ml-3">*Puhelut 030/010-alkuisiin numeroihin hinnoitellaan soittavan operaattorin mukaan</p>
                            <p><a href="https://www.sustera.fi/yrityspolitiikka-ja-dokumentit/tietosuojaseloste-kiinteistokartturi/" rel="noopener noreferrer" target="_blank" className="text-white">Tietosuojaseloste</a></p>
                        </div>

                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({ auth }) => ({ auth });

export default connect(mapStateToProps) (Landing);
