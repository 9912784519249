import * as api from "../apis/users";

export const WEBSOCKET_CURRENT = 'WEBSOCKET_CURRENT';
export const WEBSOCKET_JOINING = 'WEBSOCKET_JOINING';
export const WEBSOCKET_LEAVING = 'WEBSOCKET_LEAVING';

export const REQUEST_ROLES = 'REQUEST_ROLES';
export const REQUEST_ROLES_SUCCESS = 'REQUEST_ROLES_SUCCESS';
export const REQUEST_ROLES_ERROR = 'REQUEST_ROLES_ERROR';

export const REQUEST_INVITE_USERS = 'REQUEST_INVITE_USERS';
export const REQUEST_INVITE_USERS_SUCCESS = 'REQUEST_INVITE_USERS_SUCCESS';
export const REQUEST_INVITE_USERS_ERROR = 'REQUEST_INVITE_USERS_ERROR';

export const currentUsers = (users) => ({
    type: WEBSOCKET_CURRENT,
    users
});

export const joiningUser = (user) => ({
    type: WEBSOCKET_JOINING,
    user
});

export const leavingUser = (user) => ({
    type: WEBSOCKET_LEAVING,
    user
});

export const getRoles = () => api.roles();

export const inviteUsers = (location_id, emails, role_id) => {
    return {
        types: [REQUEST_INVITE_USERS, REQUEST_INVITE_USERS_SUCCESS, REQUEST_INVITE_USERS_ERROR],

        shouldCallAPI: (state) => {
            return true;
        },

        callAPI: () => api.invite({ max: 1, location_id, emails, role_id }),

        payload: {},
    };
};
