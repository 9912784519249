import React, { Component } from "react";
import PropTypes from "prop-types";

export class Stacked extends Component {

    button() {
        if (this.props.currentPage === this.props.pages - 1)
            return <button className="btn btn-primary" style={{ height: '3rem', width: '8rem' }} id="notification-done" onClick={() => this.props.close()}>Valmis</button>;
        return <button className="btn btn-primary" style={{ height: '3rem', width: '8rem' }} id="notification-next" onClick={() => this.props.changePage(1)}>Seuraava</button>;
    }

    render() {
        return (
            <div className="stacked d-flex flex-column text-center h-100">
                <div className="d-flex flex-column w-100 h-100">
                    {/* <div className="border border-rounded mx-auto mt-3 d-none d-sm-block" style={{ width: '100%', height: '100%', maxWidth: '39rem', maxHeight: '22rem', backgroundSize: 'cover', backgroundImage: `url(${(this.props.page.attachments[0].protected_paths.medium || '')})` }}>*/}
                    {/* </div>*/}
                    <div className="mx-auto d-flex mt-3" style={{ maxWidth: '27rem', width: '100%' }}>
                        <h1 style={{ fontSize: '32px' }}>{this.props.page.header}</h1>
                    </div>
                    <p className="p-4 mx-auto w-75 mb-2" style={{ overflow: 'auto', wordBreak: 'break-word' }}>
                        {this.props.page.content}
                    </p>
                    <div className="d-flex justify-content-between mt-auto mb-1">
                        <div/>
                        {this.button()}
                    </div>
                </div>
            </div>
        );
    }
}

Stacked.propTypes = {
    page: PropTypes.object.isRequired
};

export default Stacked;
