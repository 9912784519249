import { REQUEST_UPDATE_TASK_SUCCESS } from "./tasks";
import * as api from "../apis/tasks";

export const REQUEST_SORT_TASKS_SUCCESS = 'REQUEST_SORT_TASKS_SUCCESS';
export const REQUEST_SORT_TASKS_ERROR = 'REQUEST_SORT_TASKS_ERROR';
export const REQUEST_SORT_TASKS = 'REQUEST_SORT_TASKS';
export const REVERT_MOVE = 'REVERT_MOVE';
export const MOVE = 'MOVE';

const saveMove = (id, data, revert) => {
    return {
        types: [REQUEST_SORT_TASKS, REQUEST_SORT_TASKS_SUCCESS, REQUEST_SORT_TASKS_ERROR],

        shouldCallAPI: (state) => {
            return JSON.stringify(data) !== JSON.stringify(revert);
        },

        callAPI: () => api.sort(id, data),

        payload: { revert },
    };
};

export const moveAndSave = (state, params) => {
    return dispatch => {
        const res = move(MOVE, state, params);
        const data = res(dispatch);

        return dispatch(saveMove(data.task, data.data, data.revert));
    };
};

export const move = (TYPE, state, params) => {
    return dispatch => {
        const source = [...Object.values(state[params.year])];
        const [removed] = source.splice(params.startIndex, 1);

        const revert = { year: params.year, startIndex: params.index, index: params.startIndex, toYear: null };

        // Within a year
        if (params.toYear === null) {
            source.splice(params.index, 0, removed);

            dispatch({ type: TYPE, data: { [params.year]: source } });

            return { task: removed, data: params, revert };
        }

        // To another year
        const dest = [...Object.values(state[params.toYear])];
        dest.splice(params.index, 0, removed);

        revert.toYear = params.year;
        revert.year = params.toYear;

        dispatch({ type: REQUEST_UPDATE_TASK_SUCCESS, id: removed, data: { year: params.toYear } });
        dispatch({ type: TYPE, data: { [params.year]: source, [params.toYear]: dest } });

        return { task: removed, data: params, revert };
    };
};
