import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ContentEditable from "react-contenteditable";
import { Trash, Check } from "../svg/icons";
import React, { Component } from "react";
import { toast } from "react-toastify";
import { catchEnter } from "../utils";
import PropTypes from "prop-types";
import Sidebar from "./Sidebar";
import Circle from "./Circle";


const date = new Date();

export class IncomePage extends Component {

    constructor(props) {
        super(props);

        this.state = {
            row: Array.from({ length: this.attributes().length }, () => ({ show: false, name: '', value: 0, increment: 0 }))
        };
    }

    valueChanged(e, data, index) {
        e.persist();
        const attributes = Object.assign([], this.props.currentLocation.attributes);
        const attribute = attributes[data.index].fields[data.fieldIndex][index];
        const value = parseFloat(e.target.innerText.replace(',', '.')) || 0;
        if (attribute !== value) {
            attributes[data.index].fields[data.fieldIndex][index] = value;
            attributes[data.index].fields[data.fieldIndex].year = date.getFullYear();
            attributes[data.index].fields[data.fieldIndex].default = 0;
            this.props.save(attributes);
        }
        this.setState({ refresh: true });
    }

    toggleInput(i, data = {}) {
        this.setState({ row: { ...this.state.row, [i]: { ...this.state.row[i], show: !this.state.row[i].show } } });
    }

    show(i, type = null) {
        if (type === 'display') {
            return this.state.row[i].show ? 'block' : 'none';
        }
        return this.state.row[i].show;
    }

    editRow(e, index, fieldIndex) {
        e.persist();
        this.setState({ row: { ...this.state.row, [index]: { ...this.state.row[index], [fieldIndex]: e.target.value } } });
    }

    saveRow(index) {
        const row = Object.assign({}, this.state.row[index]);
        if (row.name !== '') {
            row.value = parseFloat(row.value);
            row.increment = parseFloat(row.increment);
            row.year = date.getFullYear();
            delete row.show;
            const attributes = Object.assign([], { ...this.props.currentLocation.attributes, [index]: { ...this.props.currentLocation.attributes[index], fields: [...this.props.currentLocation.attributes[index].fields, row] } });
            this.props.save(attributes);
            this.toggleInput(index);
        } else {
            toast.error('Kentän nimi on pakollinen');
        }
    }

    attributes() {
        return this.props.currentLocation.attributes || [];
    }

    removeRow(index, fieldIndex) {
        const attributes = Object.assign([], { ...this.props.currentLocation.attributes, [index]: { ...this.props.currentLocation.attributes[index], fields: [...this.props.currentLocation.attributes[index].fields.filter((field, index) => index !== fieldIndex)] } });
        this.props.save(attributes);
    }

    render() {
        return (
            <div className="Page w-100 h-100 d-flex flex-md-row flex-column align-self-center">
                <Sidebar location={this.props.currentLocation}/>
                <div className="d-flex flex-column mt-5 align-items-start right col-md-8 col-12">
                    <h2 className="w-100" style={{ textAlign: this.attributes().length ? 'left' : 'center' }}>{this.attributes().length ? 'Menot ja tulot' : 'Täytä ensin kohteen huoneistoala'}</h2>
                    {this.attributes().map((attribute, i) => {
                        return <span key={i} className="income-field w-100">
                            <div className="row w-100 pb-0 mt-2">
                                <h5 className="py-3 mb-0 text-left col-md-7 col-5">{attribute.header}</h5>
                                <h5 className="py-3 mb-0 text-left col-md-2 col-3 border-left" style={{ wordBreak: 'break-all' }}>Budjetti</h5>
                                <h5 className="py-3 mb-0 text-left col-md-3 col-4 border-left">Kasvu</h5>
                            </div>
                            {attribute.fields.map((field, fi) => {
                                return <div key={fi} className="income-row row w-100 py-0">
                                    <p className="text-left col-md-7 col-5">{field.name} {field.default ? <small>(oletusarvo)</small> : ''}</p>
                                    <p className="text-left col-md-2 col-3 border-left my-0 py-3">
                                        <ContentEditable
                                            tagName="a"
                                            html={field.value + ''}
                                            onKeyDown={(e) => catchEnter(e)}
                                            onBlur={(e) => this.valueChanged(e, { index: i, fieldIndex: fi }, 'value')}
                                            className="contenteditable text-primary" disabled={false}
                                        />
                                        €
                                    </p>
                                    <p className="text-left col-md-3 col-4 border-left my-0 py-3">
                                        <ContentEditable
                                            tagName="a"
                                            html={field.increment ? field.increment + '' : '0'}
                                            onKeyDown={(e) => catchEnter(e)}
                                            onBlur={(e) => this.valueChanged(e, { index: i, fieldIndex: fi }, 'increment')}
                                            className="contenteditable text-primary"
                                            disabled={false}
                                        /> % vuodessa
                                    </p>
                                    <Trash className="icon-f icon-cls trash c-pointer" style={{ width: '2.2rem' }} onClick={() => this.removeRow(i, fi)}/>
                                </div>;
                            })}
                            <div className="w-100 pt-2 justify-content-between border-0 income-field" style={{ display: this.show(i, 'display') }}>
                                <div className="row w-100">
                                    <p className="text-left col-md-7 col-5"><input onBlur={(e) => this.editRow(e, i, 'name')} style={{ height: '2.4em' }} className="rounded-0"/></p>
                                    <p className="col-md-2 col-3"><input onBlur={(e) => this.editRow(e, i, 'value')} style={{ height: '2.4em', width: '5em' }} type="number" className="mr-2 rounded-0"/></p>
                                    <p className="col-md-3 col-4"><input onBlur={(e) => this.editRow(e, i, 'increment')} style={{ height: '2.4em', width: '3em' }} type="number" className="rounded-0"/> % vuodessa</p>
                                    <p className="box c-pointer" onClick={() => this.saveRow(i)}><Check className="icon-f"/></p>
                                </div>
                            </div>
                            <Circle onClick={() => this.toggleInput(i)} id={"income-page-toggle-input-" + this.attributes()[i].header.toLowerCase()} className="mt-4 c-pointer" type="solid"><FontAwesomeIcon icon={this.show(i, 'bool') ? 'minus' : 'plus'}/></Circle>
                        </span>;
                    })}
                </div>
            </div>
        );
    }
}

IncomePage.propTypes = {
    currentLocation: PropTypes.object.isRequired,
    save: PropTypes.func.isRequired
};

export default IncomePage;
