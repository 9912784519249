import { createdOrganization } from "../../actions/organizations";
import React, { Component } from "react";
import { Check } from "../../svg/icons";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import moment from "moment";

const trans = {
    month: 'kk',
    day: 'pv',
    hour: 'tunti',
    week: 'viikko',
    name: 'yhtiön nimi',
};

export class OrderBilling extends Component {

    constructor(props) {
        super(props);

        this.state = {
            errors: {},
            plans: this.props.plans || [],
            plan_id: this.props.plans.length > 0 ? this.props.plans[0].id : null,
            loadPlans: null,
            terms: false,
        };

        this.validate = this.validate.bind(this);
    }

    validate(values) {
        let valid = true;
        const errors = { ...this.state.errors };
        const keys = Object.keys(values);
        for (let i = keys.length - 1; i >= 0; i--) {
            if (values[keys[i]] === '') {
                errors[keys[i]] = 'Ole hyvä ja tarkista ' + trans[keys[i]];
                valid = false;
            }
        }

        if (!valid) {
            this.setState({ errors });
            this.props.error(Object.values(errors));
            return false;
        }

        for (let l = keys.length - 1; l >= 0; l--) {
            delete errors[keys[l]];
        }
        this.setState({ errors });

        return true;
    }

    async done() {
        const values = {
            name: this.refs.name.value,
        };

        if (this.validate(values) && this.state.terms) {
            let data = {
                user_id: parseInt(this.props.user.id, 10),
                plan_id: parseInt(this.refs.plan_id.value, 10),
                name: values.name,
                conditions_accepted: moment().format('YYYY-MM-DD HH:mm:ss'),
                terms_accepted: moment().format('YYYY-MM-DD HH:mm:ss'),
            };
            if (this.props.append !== undefined) {
                data = { ...data, ...this.props.append };
            }
            delete values.name;
            data.billing = values;

            if (this.props.invitation && this.props.invitation.location_id !== undefined) {
                data = { ...data, ...{ location_id: this.props.invitation.location_id } };
            }
            await this.props.createdOrganization(data);
            this.props.done();
        }
    }

    render() {
        return (
            <div className="">
                <h1>Sopimus</h1>
                <p>Tästä voit valita haluamasi sopimuksen.</p>

                <p>Pyydämme myös lukemaan ja hyväksymään Susteran ja asunto-osakeyhtiön / kiinteistöyhtiön väliset <a href="https://www.sustera.fi/yrityspolitiikka-ja-dokumentit/sopimusehdot-kiinteistokartturi/" id={"steps-" + window.location.pathname.split('/')[2] + "-conditions-link"} rel="noopener noreferrer" target="_blank">sopimusehdot</a> sekä käyttäjiä
                    koskevat <a href="https://www.sustera.fi/yrityspolitiikka-ja-dokumentit/tietosuojaseloste-kiinteistokartturi/" id={"steps-" + window.location.pathname.split('/')[2] + "-terms-link"} rel="noopener noreferrer" target="_blank">käyttöehdot</a>.</p>

                {this.state.plans.map((plan, index) => <div key={'plan-' + index} className="plan p-3 d-flex align-items-center justify-content-between bg-white mb-3 c-pointer" onClick={(e) => this.setState({ plan_id: plan.id })}>
                    <div>
                        <span className="font-weight-bold">{plan.name}</span><br/>
                        {plan.features.map(feature => {
                            return <p className="mb-0" key={'plan-' + plan.id + '-' + feature.id}><span className="font-weight-bold">{feature.name}</span>: {feature.value}</p>;
                        })}
                        <span className="font-weight-bold">
                            {plan.price} &euro; / {(plan.invoice_period > 1 ? plan.invoice_period : '')} {trans[plan.invoice_interval]}
                        </span>
                    </div>
                    <div className="border border-primary" style={{ width: '2.5rem', height: '2.5rem' }}>
                        {this.state.plan_id === plan.id ? <span>
                            <Check className="icon-check"/>
                            <input ref="plan_id" type="hidden" value={plan.id}/>
                        </span> : ''}
                    </div>
                </div>)}

                <div className="d-flex flex-column mt-2">
                    <input className={'form-control rounded-0 border-primary mt-2' + (this.state.errors.name !== undefined ? ' error' : '')} type="text" onBlur={(e) => this.validate({ name: e.target.value })} ref="name" placeholder={trans.name}/>
                </div>

                <div className="mt-2">
                    <input type="checkbox" onClick={(e) => this.setState({ terms: e.nativeEvent.target.checked })} value="1"/> Hyväksyn palvelun <a href="https://www.sustera.fi/yrityspolitiikka-ja-dokumentit/sopimusehdot-kiinteistokartturi/" id={"steps-" + window.location.pathname.split('/')[2] + "-conditions-link-2"} rel="noopener noreferrer"
                        target="_blank">sopimus-</a> ja <a href="https://www.sustera.fi/yrityspolitiikka-ja-dokumentit/kayttoehdot-kiinteistokartturi/" id={"steps-" + window.location.pathname.split('/')[2] + "-terms-link-2"}
                        rel="noopener noreferrer" target="_blank">käyttöehdot</a>
                </div>

                <div className="d-flex mt-5">
                    <div className="col-6"></div>
                    <button className="col-6 btn btn-primary ml-2" disabled={!this.state.terms} id={"steps-" + window.location.pathname.split('/')[2] + "-plan-next-button"} onClick={() => this.done()}>Seuraava</button>
                </div>
            </div>
        );
    }
}

OrderBilling.propTypes = {
    done: PropTypes.func.isRequired,
};

const mapStateToProps = ({ invitation }) => ({ invitation });

const mapDispatchToProps = (dispatch) => ({
    createdOrganization: (data) => dispatch(createdOrganization(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(OrderBilling);
