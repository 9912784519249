import React from "react";

export const RsOrIndividual = props => <div className="bg-gray">
    <div className="py-2 py-md-5 d-block d-md-flex justify-content-center align-items-start container">
        <div className="col-md-4 col-12 mt-5">
            <h3 className="text-primary">Sustera on tehnyt meille Kuntoarvion</h3>
            <a href="/start/rs15" className="btn btn-primary my-3">Ota käyttöön</a><br />
        </div>
        <div className="col-md-4 col-12 mt-5">
            <h3>Haluan helpotusta korjausten suunnitteluun ja budjetointiin</h3>
            <a href="/start/individual" className="btn btn-primary my-3">Kokeile ilmaiseksi</a><br />
            <small className="text-primary text-sm">Tarjoamme Kiinteistökartturin toistaiseksi maksutta käyttöösi.</small>
        </div>
    </div>
</div>;

export default RsOrIndividual;
