import { WEBSOCKET_CURRENT, WEBSOCKET_JOINING, WEBSOCKET_LEAVING } from "../actions/users";

export default (state = [], action) => {
    switch (action.type) {
    case WEBSOCKET_CURRENT:
        return [...action.users];
    case WEBSOCKET_JOINING:
        return [...state, action.user];
    case WEBSOCKET_LEAVING:
        return [...state.filter(user => user.id !== action.user.id)];
    case 'LOGOUT':
    case 'MAINTENANCE_MODE':
        if (action.type === 'MAINTENANCE_MODE' && !action.data.mode) {
            return [...state];
        }
        return [];
    default:
        return [...state];
    }
};
