import { SET_CURRENT_TASK, WEBSOCKET_CREATE_TASK_ATTACHMENT, WEBSOCKET_DELETE_TASK_ATTACHMENT,
    REQUEST_UPDATE_TASK_SUCCESS, REQUEST_DELETE_TASK_SUCCESS, redirect
} from "../actions/tasks";
import { REQUEST_CREATE_ATTACHMENT_SUCCESS, REQUEST_DELETE_ATTACHMENT_SUCCESS } from "../actions/attachments";
import { REQUEST_DELETE_TAG_SUCCESS, TAG_CREATED, TAG_DELETED } from "../actions/locations";

export default (state = {}, action) => {
    switch (action.type) {
    case WEBSOCKET_DELETE_TASK_ATTACHMENT:
        return  state.id === action.data.task_id ? { ...state, ...{
            attachments: [...state.attachments.filter(attachment => attachment.id !== action.data.attachment_id)],
            thumbnail: action.data.thumbnail
        } } : { ...state };
    case WEBSOCKET_CREATE_TASK_ATTACHMENT:
        return state.id === action.task.id ? { ...state, attachments: [...state.attachments, ...action.task.attachments] } : { ...state };
    case REQUEST_CREATE_ATTACHMENT_SUCCESS:
    case REQUEST_DELETE_ATTACHMENT_SUCCESS:
        return action.payload.destination === 'tasks' ? state.id === action.data.model.id ? { ...action.data.model } : { ...state } : { ...state };
    case SET_CURRENT_TASK:
        return { ...action.data.task };
    case REQUEST_UPDATE_TASK_SUCCESS:
        const id = action.id || action.data.task.id;
        const data = action.data.task || action.data;
        if (id === state.id) {
            return { ...state, ...data };
        }
        return { ...state };
    case REQUEST_DELETE_TASK_SUCCESS:
        const task = action.data.id ? action.data : action.payload;
        if (task.id === state.id) {
            action.asyncDispatch(redirect());
            return {};
        }
        return { ...state };
    case REQUEST_DELETE_TAG_SUCCESS:
        if (action.data.location.tasks[state.year] !== undefined) {
            const task = action.data.location.tasks[state.year].find(t => t.id === state.id);
            if (task) {
                return { ...state, ...task };
            }
        }
        return { ...state };
    case TAG_DELETED:
        if (action.data.model === 'Task' && action.data.modelId === state.id) {
            const tags = { ...state.tags };
            const parts = action.data.tag.group.split('.');
            if (parts[1] === undefined) {
                tags[parts[0]] = tags[parts[0]].filter(t => t.id !== action.data.tag.id);
            }
            else {
                tags[parts[0]][parts[1]] = tags[parts[0]][parts[1]].filter(t => t.id !== action.data.tag.id);
            }

            return { ...state, ...{ tags } };
        }
        return { ...state };
    case TAG_CREATED:
        if (action.data.model === 'Task' && action.data.modelId === state.id) {
            const tags = { ...state.tags };
            const parts = action.data.tag.group.split('.');
            if (parts[1] === undefined) {
                tags[parts[0]] = [ ...tags[parts[0]], action.data.tag ];
            }
            else {
                tags[parts[0]][parts[1]] = [ ...tags[parts[0]][parts[1]], action.data.tag ];
            }

            return { ...state, ...{ tags } };
        }
        return { ...state };
    case 'LOGOUT':
    case 'MAINTENANCE_MODE':
        if (action.type === 'MAINTENANCE_MODE' && !action.data.mode) {
            return { ...state };
        }
        return {};
    default:
        return { ...state };
    }
};
