import {
    REQUEST_TASK, REQUEST_TASK_SUCCESS, REQUEST_TASK_ERROR,
    REQUEST_UPDATE_TASK, REQUEST_UPDATE_TASK_SUCCESS, REQUEST_UPDATE_TASK_ERROR
} from "../actions/tasks";
import { REQUEST_COMMENTS, REQUEST_COMMENTS_SUCCESS, REQUEST_COMMENTS_ERROR } from "../actions/comments";
import {
    REQUEST_LOCATION, REQUEST_LOCATION_SUCCESS, REQUEST_LOCATION_ERROR,
    REQUEST_UPDATE_LOCATION, REQUEST_UPDATE_LOCATION_SUCCESS, REQUEST_UPDATE_LOCATION_ERROR,
    REQUEST_LOCATIONS, REQUEST_LOCATIONS_SUCCESS, REQUEST_LOCATIONS_ERROR, SET_LOCATION
} from "../actions/locations";
import { REQUEST_CREATE_ATTACHMENT, REQUEST_CREATE_ATTACHMENT_SUCCESS, REQUEST_CREATE_ATTACHMENT_ERROR } from "../actions/attachments";
import { REQUEST_JOIN_LOCATION, REQUEST_JOIN_LOCATION_SUCCESS, REQUEST_JOIN_LOCATION_ERROR, REQUEST_FAQ, REQUEST_FAQ_SUCCESS, REQUEST_FAQ_ERROR } from "../actions/user";
import { REQUEST_ORGANIZATIONS, REQUEST_ORGANIZATIONS_SUCCESS, REQUEST_ORGANIZATIONS_ERROR } from "../actions/organizations";
import { REQUEST_SERVICES, REQUEST_SERVICES_SUCCESS, REQUEST_SERVICES_ERROR } from "../actions/services";

export default (state = { currentTask: false, comments: false, currentLocation: false, locations: false, attachment: false, organization: false, faqs: false }, action) => {
    switch (action.type) {
    case REQUEST_TASK:
    case REQUEST_UPDATE_TASK:
        return { ...state, ...{ currentTask: true } };
    case REQUEST_UPDATE_TASK_SUCCESS:
    case REQUEST_UPDATE_TASK_ERROR:
    case REQUEST_TASK_SUCCESS:
    case REQUEST_TASK_ERROR:
        return { ...state, ...{ currentTask: false } };
    case REQUEST_COMMENTS:
        return { ...state, ...{ comments: true } };
    case REQUEST_COMMENTS_SUCCESS:
    case REQUEST_COMMENTS_ERROR:
        return { ...state, ...{ comments: false } };
    case REQUEST_LOCATION:
    case REQUEST_UPDATE_LOCATION:
        return { ...state, ...{ currentLocation: true } };
    case REQUEST_UPDATE_LOCATION_SUCCESS:
    case REQUEST_UPDATE_LOCATION_ERROR:
    case REQUEST_LOCATION_SUCCESS:
    case REQUEST_LOCATION_ERROR:
    case SET_LOCATION:
        return { ...state, ...{ currentLocation: false } };
    case REQUEST_LOCATIONS:
        return { ...state, ...{ locations: true } };
    case REQUEST_LOCATIONS_SUCCESS:
    case REQUEST_LOCATIONS_ERROR:
        return { ...state, ...{ locations: false } };
    case REQUEST_CREATE_ATTACHMENT:
        return { ...state, ...{ attachment: true } };
    case REQUEST_CREATE_ATTACHMENT_SUCCESS:
    case REQUEST_CREATE_ATTACHMENT_ERROR:
        return { ...state, ...{ attachment: false } };
    case REQUEST_ORGANIZATIONS:
        return { ...state, ...{ organization: true } };
    case REQUEST_ORGANIZATIONS_SUCCESS:
    case REQUEST_ORGANIZATIONS_ERROR:
        return { ...state, ...{ organization: false } };
    case REQUEST_JOIN_LOCATION:
        return { ...state, ...{ joiningLocation: true } };
    case REQUEST_JOIN_LOCATION_SUCCESS:
    case REQUEST_JOIN_LOCATION_ERROR:
        return { ...state, ...{ joiningLocation: false } };
    case REQUEST_FAQ:
        return { ...state, ...{ faqs: true } };
    case REQUEST_FAQ_SUCCESS:
    case REQUEST_FAQ_ERROR:
        return { ...state, ...{ faqs: false } };
    case REQUEST_SERVICES:
        return { ...state, ...{ services: true } };
    case REQUEST_SERVICES_SUCCESS:
    case REQUEST_SERVICES_ERROR:
        return { ...state, ...{ services: false } };
    case 'LOGOUT':
    case 'MAINTENANCE_MODE':
        return {};
    default:
        return { ...state };
    }
};
