import SusteraLogo from "../svg/Sustera_Logo_S_Green_RGB.svg";
import { ChevronLeft, ChevronRight } from "../svg/icons";
import StackedLeft from "./pages/stacked-left";
import React, { Component } from "react";
import Stacked from "./pages/stacked";
import PropTypes from "prop-types";

export class NotificationsModal extends Component {

    render() {
        return (
            <div className="p-3 rounded col-md-6 col-12 mx-auto" style={{ position: 'relative', background: 'white', maxWidth: '50rem' }}>
                <div className="d-flex flex-column w-100 px-3 text-center" style={{ height: (window.innerHeight * 0.9), maxHeight: '53rem' }}>
                    <img src={SusteraLogo} className="navbar-brand" style={{ maxHeight: '3rem' }} alt=""/>
                    {((dir) => {
                        switch (dir) {
                        case 1:
                            return <StackedLeft {...this.props} />;
                        case 2:
                            return <Stacked {...this.props} />;
                        default:
                            return '';
                        }
                    })(this.props.page.style)}
                    <div className="d-flex align-items-center mx-auto">
                        {this.props.currentPage - 1 >= 0 ?
                            <span onClick={() => this.props.changePage(-1)} id="notifications-prev-page-chevron" style={{ width: '1.75rem', height: '1.75rem', marginLeft: '-2rem' }} className="d-block border rounded-circle border-primary position-relative mr-1"><ChevronLeft className="icon-f nohover"/></span>
                            : ''}
                        {Array.from({ length: this.props.pages }).map((v, i) =>
                            <span key={i} onClick={() => this.props.changePage(i - this.props.currentPage)} style={{ width: '8px', height: '8px' }} className={"d-block border rounded-circle border-primary mr-1 " + (this.props.currentPage === i ? "bg-primary" : "")}/>
                        )}
                        {this.props.currentPage < this.props.pages - 1 ?
                            <span onClick={() => this.props.changePage(1)} id="notifications-next-page-chevron" style={{ width: '1.75rem', height: '1.75rem', marginRight: '-1.75rem' }} className="d-block border rounded-circle border-primary position-relative"><ChevronRight className="icon-f nohover"/></span>
                            : ''}
                    </div>
                </div>
            </div>
        );
    }
}

NotificationsModal.propTypes = {
    page: PropTypes.object.isRequired,
    close: PropTypes.func.isRequired,
    changePage: PropTypes.func.isRequired
};

export default NotificationsModal;
