import Notification from "../components/Notification";
import { logout } from "redux-implicit-oauth2";
import { redirect } from "../actions/tasks";
import { toast } from "react-toastify";
import history from "../history";
import axios from "axios";
import React from "react";

const CallApiMiddleware = store => next => action => {
    const {
        types,
        callAPI,
        shouldCallAPI = () => true,
        ...props
    } = action;

    if (!types) return next(action);

    if (!Array.isArray(types) ||
        types.length !== 3 ||
        !types.every(type => typeof type === 'string')) {

        throw new Error('Expected an array of three string types.');
    }

    if (typeof callAPI !== 'function') {
        throw new Error('Expected callAPI to be a function.');
    }

    axios.defaults.headers.common['Authorization'] = 'Bearer ' + store.getState().auth.token;
    axios.defaults.headers.common['Accept'] = 'application/json';

    if (!shouldCallAPI(store.getState())) return undefined;

    const [requestType, successType, failureType] = types;

    store.dispatch({ ...props, type: requestType });

    return callAPI(store.getState())
        .then(response => {
            if (response.data.message !== undefined) {
                toast.success(<Notification msg={response.data.message}/>);
            }
            store.dispatch({ ...props, type: successType, data: response.data });
            return Promise.resolve(() => Promise.resolve());
        })
        .catch(error => {
            if (error.response) {
                if (error.response.status === 503) {
                    store.dispatch({ type: 'MAINTENANCE_MODE', data: { mode: true } });
                    return;
                }
                if (error.response.status === 401) {
                    const toastId = '401-unauthenticated';
                    if (!toast.isActive(toastId)) {
                        toast.error(<Notification type="error" msg={error.response.data.message}/>, { toastId });
                    }

                    store.dispatch(logout());
                    history.push('/login');
                    return;
                }
                if (error.response.status === 403) {
                    const toastId = '403-unauthorized';
                    if (!toast.isActive(toastId)) {
                        toast.error(<Notification type="error" msg={'Sinulla ei ole oikeuksia tähän toimintoon'}/>, { toastId });
                    }
                    store.dispatch({ ...props, type: failureType, error });
                    return;
                }
                if (error.response.status === 460) {
                    store.dispatch({
                        type: 'REQUEST_ERROR',
                        failureType,
                        status: error.response.status,
                        data: error.response.data,
                    });
                    store.dispatch({ type: 'SET_LOCATION', data: { location: error.response.data.location } });
                    return;
                }
            }
            if (failureType === 'REQUEST_TASK_ERROR' && error.response.status === 404) {
                store.dispatch(redirect());
            }
            if (error.response !== undefined && error.response.data !== undefined) {
                if (error.response.data.errors !== undefined) {
                    toast.error(<Notification type="error" msg={Object.values(error.response.data.errors).join("\n")}/>);
                } else {
                    toast.error(<Notification type="error" msg={error.response.data.message}/>);
                }
            } else if (error.message !== undefined) {
                toast.error(<Notification type="error" msg={error.message}/>);
            }
            store.dispatch({ ...props, type: failureType, error });
            return Promise.resolve(() => Promise.reject());
        });
};

export default CallApiMiddleware;
