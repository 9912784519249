import NotificationsModalContainer from "./containers/NotificationsModalContainer";
import MaintenanceReportContainer from "./containers/MaintenanceReportContainer";
import LocationUsersContainer from "./containers/LocationUsersContainer";
import LocationPageContainer from "./containers/LocationPageContainer";
import ContactModalContainer from "./containers/ContactModalContainer";
import IncomePageContainer from "./containers/IncomePageContainer";
import LocationsContainer from "./containers/LocationsContainer";
import LocationContainer from "./containers/LocationContainer";
import UserPageContainer from "./containers/UserPageContainer";
import CurrentTaskModal from "./components/CurrentTaskModal";
import HeaderContainer from "./containers/HeaderContainer";
import ErrorsContainer from "./containers/ErrorsContainer";
import InviteContainer from "./containers/InviteContainer";
import MaintenanceMode from "./components/MaintenanceMode";
import ChartContainer from "./containers/ChartContainer";
import NewTaskModal from "./components/NewTaskModal";
import FaqContainer from "./containers/FaqContainer";
import UserTerms from "./components/UserTerms";
import { editUser } from "./actions/user";
import { Switch } from "react-router-dom";
import PrivateRoute from "./PrivateRoute";
import React, { Component } from "react";
import { connect } from "react-redux";

export class App extends Component {

    render() {
        if (this.props.maintenance.mode) {
            return <div className="App d-flex flex-column" style={{ marginTop: '-0rem' }}>
                <HeaderContainer />
                <MaintenanceMode />
            </div>;
        }
        return <div className="App d-flex flex-column">
            <HeaderContainer />
            <ErrorsContainer />
            {this.props.user.terms_accepted === null ? <UserTerms editUser={this.props.editUser} user={this.props.user} /> :
                <Switch>
                    <PrivateRoute path={`${this.props.match.path}User`} component={UserPageContainer} />
                    <PrivateRoute path={`${this.props.match.path}faq`} component={FaqContainer} />

                    <PrivateRoute path={`${this.props.match.path}:id/:slug/income`} component={IncomePageContainer} />
                    <PrivateRoute path={`${this.props.match.path}:id/:slug/chart`} component={ChartContainer} />
                    <PrivateRoute path={`${this.props.match.path}:id/:slug/edit`} component={LocationPageContainer} />
                    <PrivateRoute path={`${this.props.match.path}:id/:slug/report`} component={MaintenanceReportContainer} />
                    <PrivateRoute path={`${this.props.match.path}:id/:slug/users`} component={LocationUsersContainer} />
                    <PrivateRoute path={`${this.props.match.path}:id/:slug?`} component={LocationContainer} />
                    <PrivateRoute exact path={this.props.match.path} component={LocationsContainer} />
                </Switch>
            }
            <Switch>
                <PrivateRoute path={`${this.props.match.path}:id/:slug/invite`} component={InviteContainer} />
                <PrivateRoute path={`${this.props.match.path}:id/:slug?/tasks/new`} component={NewTaskModal} />
                <PrivateRoute path={`${this.props.match.path}:id/:slug?/tasks/:task_id/:task_slug?`} component={CurrentTaskModal} />
            </Switch>

            {this.props.contactModal ? <ContactModalContainer  /> : '' }
            {this.props.notifications ? <NotificationsModalContainer  /> : '' }
        </div>;
    }
}

const mapStateToProps = ({ contactModal, notifications, user, maintenance }) => ({ notifications: notifications.show, contactModal: contactModal.show, user, maintenance });

const mapDispatchToProps = (dispatch, ) => ({
    editUser: (data) => dispatch(editUser(data)),
});

export default connect(mapStateToProps, mapDispatchToProps) (App);
