import { toggleNotificationsModal, notificationSeen } from "../actions/notifications";
import NotificationsModal from "../components/NotificationsModal";
import { Modal, ModalBody } from "reactstrap";
import Circle from "../components/Circle";
import React, { Component } from "react";
import { connect } from "react-redux";

export class NotificationsModalContainer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            page: 0
        };
    }

    changePage(dir) {
        this.setState({ page: this.state.page + dir });
    }

    notificationSeen() {
        this.props.toggleNotificationsModal();
        this.props.notificationSeen(this.props.notifications[0].id);
    }

    render() {
        return <Modal isOpen={true} toggle={() => this.props.toggleNotificationsModal()} style={{ width: '95vw', maxWidth: '95%' }} className="Notifications">
            <ModalBody style={{ width: 'auto', margin: 'auto' }}>
                <div className="col-md-6 col-12" style={{ position: 'relative', margin: 'auto', maxWidth: '50rem' }}>
                    <Circle type="outline" size="small" className="mt-2 c-pointer bg-white" onClick={() => this.props.toggleNotificationsModal()} style={{ position: 'absolute', zIndex: 2, right: '-1.5rem', top: '-2rem', width: '1.5rem', height: '1.5rem', fontSize: '1rem' }}>X</Circle>
                </div>
                <NotificationsModal close={() => this.notificationSeen()} page={this.props.notifications[0].pages[this.state.page]} pages={this.props.notifications[0].pages.length} changePage={(dir) => this.changePage(dir)} currentPage={this.state.page}/>
            </ModalBody>
        </Modal>;
    }
}

const mapStateToProps = ({ notifications }) => ({ notifications: notifications.notifications });

const mapDispatchToProps = (dispatch) => ({
    toggleNotificationsModal: () => dispatch(toggleNotificationsModal()),
    notificationSeen: (id) => dispatch(notificationSeen(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(NotificationsModalContainer);
