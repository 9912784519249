import UserAvatar from "../components/UserAvatar";
import Sidebar from "../components/Sidebar";
import Circle from "../components/Circle";
import React, { Component } from "react";
import PropTypes from "prop-types";

export class LocationUsersPage extends Component {

    isAuthorized(user) {
        if (user !== undefined && user.pivot.role_id === 1) {
            return false;
        }
        const role = this.props.user.locationRoles[this.props.currentLocation.id];
        return ['owner', 'su', 'raks'].indexOf(role.slug) >= 0;
    }

    changeRole(id) {
        this.props.changeRole(this.refs['role' + id].value, this.props.currentLocation.id, id);
    }

    invite() {
        this.props.invite(this.refs.email.value, this.refs.roles.value);
    }

    roleName(id) {
        if (id === 1) {
            return 'Ylläpitäjä';
        }
        return Object.values(this.props.currentLocation.allRoles).filter(role => role.id === id).shift().name;
    }

    render() {
        return <div className="Page w-100 h-100 d-flex flex-md-row flex-column align-self-center">
            <Sidebar location={this.props.currentLocation}/>
            <div className="d-flex flex-column mt-5 align-items-start right col-md-6 col-12">
                <h2 className="align-self-center align-self-md-start">Henkilöt</h2>
                <div className="d-flex flex-column w-100">
                    {this.props.currentLocation.users.map(user => {
                        return <div key={user.id} className="d-flex mt-4 mb-4 mt-md-2 mb-md-2 flex-column flex-md-row" style={{ minHeight: '7.3rem' }}>
                            <Circle type="outline" size="large" className="d-flex" style={{ overflow: 'hidden' }}>
                                {user.avatar ?
                                    <img alt="/kuva" src={user.avatar.small}/>
                                    :
                                    <UserAvatar width={120} height={120} user={user}/>
                                }
                            </Circle>
                            <div className="d-flex justify-content-between flex-grow-1 flex-column flex-md-row">
                                <span className="d-flex flex-column pl-0 pl-md-4 align-self-center">
                                    <a href={`mailto:${user.email}`} target="_top">{user.firstname} {user.lastname}</a>
                                    {this.isAuthorized(user) ? <select ref={"role" + user.id} className="form-control" onChange={() => this.changeRole(user.id)} style={{ width: '9em' }} defaultValue={user.pivot.role_id}>
                                        {Object.values(this.props.currentLocation.allRoles).map(role => {
                                            return <option key={role.id} value={role.id}>{role.name}</option>;
                                        })}
                                    </select> : <p className="text-left">{this.roleName(user.pivot.role_id)}</p>}
                                </span>
                                {
                                    this.isAuthorized() ?
                                        <button onClick={() => this.props.remove(this.props.currentLocation.id, user.id)} className="btn btn-outline-primary align-self-center" style={{ height: '2.2em', width: '9em' }}>Poista</button>
                                        :
                                        ''
                                }
                            </div>
                        </div>;
                    })}
                    <div className="w-100 mt-3 p-2" style={{ background: '#f6f6f6' }}>
                        <h3 className="text-left">Lähetä kutsu</h3>
                        <div className="d-flex flex-column">
                            <div className="d-flex align-items-center justify-content-start">
                                <input style={{ height: '2em', flex: 1 }} ref="email" type="email" name="email" placeholder="esimerkki@email.fi"/>
                                <select ref="roles" className="form-control ml-3" defaultValue="" style={{ width: '9rem', height: '2em' }}>
                                    <option>Rooli</option>
                                    {Object.values(this.props.currentLocation.allRoles).map(role => <option value={role.id} key={`roles-${role.id}`}>{role.name}</option>)}
                                </select>
                            </div>
                            <button onClick={() => this.invite()} id="location-users-invite-button" className="align-self-end mt-3 rounded-0 btn btn-primary" style={{ height: '2.7em', width: '14em' }}>Lähetä</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>;
    }
}

LocationUsersPage.propTypes = {
    match: PropTypes.object.isRequired,
    currentLocation: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
    remove: PropTypes.func.isRequired,
    changeRole: PropTypes.func.isRequired,
    invite: PropTypes.func.isRequired,
};

export default LocationUsersPage;
