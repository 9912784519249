import { setYearOffset, setFilters } from "../actions/filter";
import { toggleContactModal } from "../actions/contact";
import { push } from "connected-react-router";
import Header from "../components/Header";
import React, { Component } from "react";
import { connect } from "react-redux";

export class HeaderContainer extends Component {
    render() {
        return <Header
            currentLocation={this.props.currentLocation}
            locations={this.props.locations}
            users={this.props.users}
            push={this.props.push}
            filter={this.props.filter}
            toggleContactModal={(header) => this.props.toggleContactModal(header)}
            setYearOffset={(offset) => this.props.setYearOffset(offset)}
            setFilters={(filters, searchType) => this.props.setFilters(filters, searchType)}/>;
    }
}

const mapStateToProps = ({ currentLocation, users, filter, locations, organization }) => ({ currentLocation, users, filter, locations, organization });

const mapDispatchToProps = (dispatch) => ({
    push: (to) => dispatch(push(to)),
    setYearOffset: (offset) => dispatch(setYearOffset(offset)),
    setFilters: (filters, searchType) => dispatch(setFilters(filters, searchType)),
    toggleContactModal: (header) => dispatch(toggleContactModal(header)),
});

export default connect(mapStateToProps, mapDispatchToProps)(HeaderContainer);
