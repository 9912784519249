import KiintestokartturiLogoSmall from "../svg/kiinteistokartturi-logo-small-2024.svg";
import LocationInformation from "../components/Steps/LocationInformation";
import ContactInformation from "../components/Steps/ContactInformation";
import OrderBilling from "../components/Steps/OrderBilling";
import { getOrganizations } from "../actions/organizations";
import { fetchUserIfNeeded } from "../actions/user";
import { getLocations } from "../actions/locations";
import { login } from "redux-implicit-oauth2";
import Users from "../components/Steps/Users";
import LoginBox from "../components/LoginBox";
import { refreshUser } from "../actions/user";
import * as api from "../apis/organizations";
import Loading from "../components/Loading";
import React, { Component } from "react";
import { connect } from "react-redux";
import "../index.scss";

const config = {
    url: process.env.REACT_APP_API_URL + "/oauth/azure-b2c/authorize",
    client: process.env.REACT_APP_OAUTH_CLIENT_ID,
    redirect: process.env.REACT_APP_REDIRECT_URL,
    scope: "",
};

const Steps = {
    ContactInformation,
    OrderBilling,
    LocationInformation,
    Users,
};

export class StartStepsIndivitual extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: this.props.auth.isLoggedIn ? true : null,
            loaded: false,
            errors: [],
            currentStep: 1,
            steps: {
                1: { name: 'Omat tiedot', component: 'ContactInformation' },
                2: { name: 'Sopimus ja laskutus', component: 'OrderBilling' },
                3: { name: 'Yhtiön tiedot', component: 'LocationInformation' },
                4: { name: 'Käyttäjät', component: 'Users' },
            },
            stepProps: {},
            values: {},
        };
    }

    async componentDidMount() {
        if (this.props.auth.isLoggedIn && !this.state.loaded) {
            await this.init();
        }
    }

    async componentDidUpdate(prevProps, prevState) {
        if (this.props.auth.isLoggedIn && !this.state.loading && !this.state.loaded) {
            await this.init();
        }
    }

    async init() {
        this.setState({ loading: true });
        await this.props.getOrganizations();
        const promises = await Promise.all([
            this.props.fetchUserIfNeeded(),
            api.plans('public'),
        ]);
        await this.props.getLocations();

        let step = this.currentStep || 1;
        if (this.props.user.id !== undefined && this.props.user.firstname !== '' && this.props.user.lastname !== '') {
            step = 2;
        }
        if (this.props.organization.id !== undefined) {
            step = 3;
        }
        if (this.props.organization.active && this.props.locations.all.length > 0) {
            step = 4;
        }

        let location = {};
        if (this.props.locations.all.length > 0) {
            location = this.props.locations.byId[this.props.locations.all[0]];
        }
        this.setState({
            loading: false,
            loaded: true,
            stepProps: {
                1: { user: this.props.user },
                2: { user: this.props.user, plans: promises[1].data.plans, organization: this.props.organization },
                3: { location: location, organization: this.props.organization },
                4: { location: location, user: this.props.user },
            },
            currentStep: step,
        });
    }

    async setDone(values = {}) {
        const nextStep = this.state.currentStep === 4 ? true : this.state.currentStep + 1 + '';
        if (nextStep === true) {
            await this.props.refreshUser();
            this.props.history.push({ pathname: `/`, state: { modal: false } });
        } else {
            let stepProps = this.state.stepProps;
            if (this.state.currentStep === 2) {
                await this.props.refreshUser();
            }
            if (this.state.currentStep === 2 && this.props.organization.id === undefined) {
                await this.props.getOrganizations();
                stepProps = { ...stepProps, ...{ 3: { organization: this.props.organization } } };
            }
            if (this.state.currentStep === 3) {
                if (this.props.locations.all.length <= 0) {
                    await this.props.getLocations();
                }
                let location = {};
                if (this.props.locations.all.length > 0) {
                    location = this.props.locations.byId[this.props.locations.all[0]];
                }
                stepProps = { ...stepProps, ...{ 4: { organization: this.props.organization, location } } };
            }
            this.setState({
                values: { ...this.state.values, ...values },
                steps: {
                    ...this.state.steps,
                    ...{
                        [this.state.currentStep]: {
                            ...this.state.steps[this.state.currentStep],
                            ...{ done: true }
                        }
                    }
                },
                stepProps,
                currentStep: parseInt(this.state.currentStep) + 1
            });
        }
    }

    render() {
        const StepContent = Steps[this.state.steps[this.state.currentStep].component];
        return <div className="App" style={{ marginTop: '-4.5rem' }}>
            <nav className="navbar navbar-expand-lg navbar-light fixed-top d-flex justify-content-center" style={{ backgroundColor: '#f7f7f7', borderBottom: '1px solid #d8d8d8' }}>
                <img src={KiintestokartturiLogoSmall} className="navbar-brand" style={{ maxHeight: '3rem' }} alt=""/>
            </nav>
            {!this.props.auth.isLoggedIn ? <div className="mt-5"><LoginBox className login={this.props.login}/></div> : this.state.loading ? <Loading isLoading={true}/> :
                <div className="start-steps d-flex justify-content-start flex-column align-items-center">
                    <div className="d-flex justify-content-between col-12 col-md-6 p-4 align-items-start">
                        {Object.keys(this.state.steps).map((step) => <div className={'step-header d-flex align-items-center justify-content-center flex-column' + (step === this.state.currentStep ? ' current' : '')} key={'step-' + step}>
                            <span className="circle d-flex align-items-center justify-content-center border"><h2 className="m-0 p-0">{step}</h2></span>
                            <span>{this.state.steps[step].name}</span>
                        </div>)}
                    </div>
                    <div className="step-container col-12 col-md-6 text-left px-5 py-4">
                        {this.state.loading !== false ? '' : <StepContent {...this.state.stepProps[this.state.currentStep]} done={(values) => this.setDone(values)} error={(errors) => this.setState({ errors })}/>}
                    </div>
                </div>}

        </div>;
    }
}

const mapStateToProps = ({ auth, user, organization, locations }) => ({ auth, user, organization, locations });

const mapDispatchToProps = (dispatch) => ({
    fetchUserIfNeeded: () => dispatch(fetchUserIfNeeded()),
    getOrganizations: () => dispatch(getOrganizations()),
    getLocations: () => dispatch(getLocations()),
    login: () => dispatch(login(config)),
    refreshUser: () => dispatch(refreshUser()),
});

export default connect(mapStateToProps, mapDispatchToProps)(StartStepsIndivitual);
