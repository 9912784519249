import React  from "react";
import { useState, useEffect } from "react";
import { Editor } from "@tinymce/tinymce-react";

export const TextEditor = props => {
    const [ editorState, setEditorState ] = useState(props.defaultValue);

    const change = value => {
        setEditorState(value);
    };

    const saveChange = () => {
        props.onBlur(editorState);
    };

    useEffect(() => {
        setEditorState(props.defaultValue);
    }, [props.defaultValue]);

    return <div className='mt-1 w-100'><Editor
        tinymceScriptSrc={process.env.REACT_APP_API_URL + '/asset/tinymce.min.js'}
        value={editorState}
        onEditorChange={change}
        onBlur={saveChange}
        init={{
            contextmenu: ['link', 'copy', 'paste'],
            height: 500,
            menubar: false,
            paste_as_text: true,
            plugins: [
                'advlist autolink lists link image charmap print preview anchor',
                'searchreplace visualblocks code fullscreen',
                'insertdatetime media table paste help wordcount autoresize'
            ],
            toolbar: 'undo redo | formatselect | ' +
                'bold italic backcolor | alignleft aligncenter ' +
                'alignright alignjustify | bullist numlist outdent indent | ' +
                'removeformat | copy paste | help',
            content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
        }}
    /></div>;
};

export default TextEditor;
