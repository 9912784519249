import { SET_OFFSET, SET_FILTERS } from "../actions/filter";

export default (state = { offset: 10, tags: {}, searchType: 'or' }, action) => {
    switch (action.type) {
    case SET_OFFSET:
        return { ...state, ...{ offset: parseInt(action.offset) } };
    case SET_FILTERS:
        return { ...state, ...{ tags: { ...action.tags }, searchType: action.searchType } };
    case 'LOGOUT':
        return { offset: 10, tags: {}, searchType: 'or' };
    default:
        return { ...state };
    }
};
