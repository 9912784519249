import ContentEditable from "react-contenteditable";
import React, { Component } from "react";
import TextEditor from "./TextEditor";

export class NewTask extends Component {
    constructor(props) {
        super(props);
        const date = new Date();
        this.year = date.getFullYear();

        this.state = {
            name: '',
            description: '',
            price: '0',
            year: this.props.year,
            type: 'plan',
            tags: {},
        };
    }

    validateInt(event, key) {
        const integer = event.target.value.split('').filter(char => !isNaN(Number.parseInt(char))).join('');
        this.setState({ [key]: integer });
    }

    save() {
        const data = {
            name: this.state.name,
            type: this.state.type,
            year: this.state.year,
            status: 'new',
            location_id: this.props.match.params.id,
            description: this.state.description,
            price: this.state.price,
            tags: this.state.tags,
        };
        this.props.save(data);
    }

    render() {
        return (
            <div className="h-100 m-auto d-flex flex-column col-md-9 col-12" style={{ maxWidth: '65rem' }}>
                <div className="row w-100">
                    <div className="CurrentTask p-5" style={{ width: '100%', flex: 1 }}>
                        <h1>Luo uusi toimenpide</h1>
                        <h5 className="mt-5" style={{ color: 'black' }}>Toimenpiteen nimi</h5>
                        <ContentEditable tagName="h5" html={this.state.name} onChange={(e) => this.setState({ name: e.target.value.replace(/(<([^>]+)>)/ig, "") })} placeholder="esim. Vesi- ja viemärikalusteiden uusimista" className="border p-3 mt-3 w-100" style={{ minHeight: '4rem', borderRadius: '2px' }}/>
                        <h5 className="mt-5" style={{ color: 'black' }}>Vuosi</h5>
                        <ContentEditable tagName="h6" html={this.state.year} onChange={(e) => this.validateInt(e, 'year')} className="border p-3 mt-3" style={{ width: '16rem', height: '3rem', borderRadius: '2px' }}/>
                        <h5 className="mt-5" style={{ color: 'black' }}>Toimenpiteen kuvaus</h5>
                        <div className="border" style={{ fontSize: '1.25rem' }}>
                            <TextEditor onBlur={(editor) => this.setState({ description: editor })} />
                        </div>
                        <button className="btn btn-primary d-block ml-auto mt-5" id="new-task-create-button" style={{ width: '17rem', height: '3rem', fontWeight: 500, fontSize: '1.25rem' }} onClick={() => this.save()}>Seuraava</button>
                    </div>
                </div>
            </div>
        );
    }
}

export default NewTask;
