import { REQUEST_LOCATIONS_SUCCESS, REQUEST_UPDATE_LOCATION_SUCCESS, WEBSOCKET_UPDATE_LOCATION,
    REQUEST_LOCATION_SUCCESS, WEBSOCKET_CREATE_LOCATION_ATTACHMENT, REMOVE_USER,
    REQUEST_DELETE_TAG_SUCCESS, REQUEST_CREATE_LOCATIONS_SUCCESS } from "../actions/locations";
import { REQUEST_CREATE_ATTACHMENT_SUCCESS } from "../actions/attachments";
import { REQUEST_JOIN_LOCATION_SUCCESS } from "../actions/user";
import _ from "lodash";

export default (state = { all: [], byId: {}, meta: {}, links: {} }, action) => {
    switch (action.type) {
    case REMOVE_USER:
        return action.data.removeSelf ? {
            ...{
                ...state,
                all: [...state.all.filter(id => id !== action.data.location)],
                byId: _.pick(state.byId, [...state.all.filter(id => id !== action.data.location)])
            }
        } : { ...state };
    case REQUEST_LOCATIONS_SUCCESS:
        return {
            ...{
                all: action.data.data.map(row => row.id),
                byId: { ..._.keyBy([...action.data.data.map(l => ({ ...state.byId[l.id] || {}, ...l }))], loc => loc.id) },
                meta: action.data.meta,
                links: action.data.links,
            }
        };
    case REQUEST_CREATE_ATTACHMENT_SUCCESS:
        return action.payload.destination === 'locations' ? {
            ...{
                ...state,
                byId: {
                    ...state.byId,
                    ...{ [action.data.model.id]: { ...state.byId[action.data.model.id], ...action.data.model } }
                }
            }
        } : { ...state };
    case WEBSOCKET_UPDATE_LOCATION:
    case REQUEST_UPDATE_LOCATION_SUCCESS:
    case WEBSOCKET_CREATE_LOCATION_ATTACHMENT:
        return {
            ...{
                ...state,
                byId: {
                    ...state.byId,
                    ...{ [action.data.location.id]: { ...state.byId[action.data.location.id], ...action.data.location } }
                }
            }
        };
    case REQUEST_LOCATION_SUCCESS:
    case REQUEST_DELETE_TAG_SUCCESS:
    case REQUEST_JOIN_LOCATION_SUCCESS:
    case REQUEST_CREATE_LOCATIONS_SUCCESS:
        return {
            ...{
                ...state,
                all: [...state.all, action.data.location.id].filter((v, i, s) => s.indexOf(v) === i),
                byId: {
                    ...state.byId,
                    ...{ [action.data.location.id]: { ...state.byId[action.data.location.id], ...action.data.location } }
                }
            }
        };
    case 'LOGOUT':
    case 'MAINTENANCE_MODE':
        if (action.type === 'MAINTENANCE_MODE' && !action.data.mode) {
            return { ...state };
        }
        return { all: [], byId: {}, meta: {}, links: {} };
    default:
        return { ...state };
    }
};
