import { fetchUserIfNeeded, editUser } from "../actions/user";
import { upload } from "../actions/attachments";
import UserPage from "../components/UserPage";
import React, { Component } from "react";
import { connect } from "react-redux";

export class UserPageContainer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            firstname: '',
            lastname: '',
            email: ''
        };
    }

    componentDidMount() {
        if (this.props.user.id === undefined) {
            this.props.fetchUserIfNeeded().then(response => {
                this.setState({
                    id: this.props.user.id,
                    firstname: this.props.user.firstname,
                    lastname: this.props.user.lastname,
                    email: this.props.user.email,
                });
            }).catch(() => {
            });
        } else {
            this.setState({
                id: this.props.user.id,
                firstname: this.props.user.firstname,
                lastname: this.props.user.lastname,
                email: this.props.user.email,
            });
        }
    }

    save(value) {
        const data = {
            ...{
                firstname: this.state.firstname,
                lastname: this.state.lastname,
                email: this.state.email
            }, ...value
        };
        this.props.editUser(data).then(() => {
            this.setState(value);
            window.Echo = undefined;
        });
    }

    saveAttachment(attachments) {
        this.props.saveAttachment(this.props.user.id, attachments, this.props.auth);
    }

    render() {
        return (
            <UserPage
                user={this.props.user}
                saveAttachment={(attachments) => this.saveAttachment(attachments)}
                state={this.state}
                match={this.props.match}
                currentLocation={this.props.currentLocation}
                save={(value) => this.save(value)}
            />
        );
    }
}

const mapStateToProps = ({ currentLocation, user, auth }) => ({ currentLocation, user, auth });

const mapDispatchToProps = (dispatch) => ({
    fetchUserIfNeeded: (id) => dispatch(fetchUserIfNeeded(id)),
    editUser: (data) => dispatch(editUser(data)),
    saveAttachment: (id, files, auth) => upload(auth, id, files, 'users', dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserPageContainer);
