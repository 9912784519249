import { deleteAttachment, upload } from "../actions/attachments";
import { getTask, updateTask, split, deleteTask } from "../actions/tasks";
import { toggleContactModal } from "../actions/contact";
import { editLocation, deleteTag } from "../actions/locations";
import CurrentTask from "../components/CurrentTask";
import { moveAndSave } from "../actions/sorting";
import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

export class CurrentTaskContainer extends Component {
    constructor(props) {
        super(props);

        this.props.getTask(this.props.match.params.task_id);
    }

    async saveAttachment(attachments) {
        this.props.saveAttachment(this.props.currentTask.id, attachments, this.props.auth);
    }

    render() {
        if (this.props.currentTask.id) {
            return <CurrentTask
                task={this.props.currentTask}
                isLoading={this.props.loading.currentTask}
                loadingAttachment={this.props.loading.attachment}
                updateTask={this.props.updateTask}
                saveAttachment={(attachments) => this.saveAttachment(attachments)}
                deleteAttachment={(id) => this.props.deleteAttachment(id,this.props.currentTask.id)}
                split={this.props.split}
                move={(params) => this.props.move(this.props.years, params)}
                delete={this.props.delete}
                currentLocation={this.props.currentLocation}
                editLocation={this.props.editLocation}
                toggleContactModal={(header) => this.props.toggleContactModal(header)}
                deleteTag={this.props.deleteTag}
            />;
        }
        return null;
    }
}

CurrentTaskContainer.propTypes = {
    currentTask: PropTypes.object,
    task_id: PropTypes.number,
};

const mapStateToProps = ({ currentTask, currentLocation, router, loading, years, auth }) => ({ currentTask, currentLocation, router, loading, years, auth });

const mapDispatchToProps = (dispatch) => ({
    getTask: (id) => dispatch(getTask(id)),
    updateTask: (id, data) => dispatch(updateTask(id, data)),
    saveAttachment: (id, files, auth) => upload(auth, id,files,'tasks', dispatch),
    deleteAttachment: (id, parent) => dispatch(deleteAttachment(id,parent,'tasks')),
    split: (id, years) => dispatch(split(id,years)),
    editLocation: (location) => dispatch(editLocation(location)),
    move: (years, params) => dispatch(moveAndSave(years, params)),
    toggleContactModal: (header) => dispatch(toggleContactModal(header)),
    delete: (task) => dispatch(deleteTask(task)),
    deleteTag: (location, tag) => dispatch(deleteTag(location, tag)),
});

export default connect(mapStateToProps, mapDispatchToProps) (CurrentTaskContainer);
