import defaultKortti from "../svg/figures/default_kortti.svg";
import React, { Component } from "react";

export class UserTerms extends Component {
    constructor(props) {
        super(props);

        this.state = { terms_accepted: false };
    }

    render() {
        return <div className="bg-light border-top px-5 py-3 col-md-6 mx-auto text-left mt-5">
            <h1>Huoltotila</h1>
            <img src={defaultKortti} className="w-75" alt=""/>
        </div>;
    }
}

export default UserTerms;
