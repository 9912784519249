import { Modal, ModalBody } from "reactstrap";
import { inviteUser } from "../actions/user";
import React, { Component } from "react";
import Circle from "../components/Circle";
import Invite from "../components/Invite";
import { connect } from "react-redux";

export class InviteContainer extends Component {

    render() {
        if (this.props.currentLocation.allRoles === undefined) {
            return '';
        }
        return <Modal isOpen={true} toggle={() => this.props.history.goBack()} style={{ width: '40%' }}>
            <ModalBody>
                <div style={{ position: 'relative' }}>
                    <Circle type="outline" size="small" className="mt-2 c-pointer bg-white" id="invite-close-button" onClick={() => this.props.history.goBack()} style={{ position: 'absolute', zIndex: 2, right: '-2.3rem', top: '-2.3rem', width: '1.5rem', height: '1.5rem', fontSize: '1rem' }}>X</Circle>
                </div>
                <Invite invite={(email, role_id) => {
                    this.props.invite(this.props.currentLocation.id, email, role_id);
                }} roles={Object.values(this.props.currentLocation.allRoles)}/>
            </ModalBody>
        </Modal>;
    }
}

const mapStateToProps = ({ currentLocation }) => ({ currentLocation });

const mapDispatchToProps = (dispatch) => ({
    invite: (location, email, role_id) => dispatch(inviteUser(location, email, role_id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(InviteContainer);
