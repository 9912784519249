import { getRoles, inviteUsers } from "../../actions/users";
import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

export class Users extends Component {

    constructor(props) {
        super(props);

        this.state = {
            errors: {},
            roles: [],
            emails: [
                { email: '', role_id: '' },
            ],
            translations: {
                email: 'sähköpostiosoite',
                role_id: 'rooli',
            },
        };

        this.validate = this.validate.bind(this);
    }

    componentDidMount() {
        getRoles().then(resp => this.setState({ roles: resp.data.roles }));
    }

    validate(values, index) {
        let valid = true;
        let errors = { ...this.state.errors };
        let keys = [];
        for (let z = values.length - 1; z >= 0; z--) {
            const row = values[z];
            keys = Object.keys(row);
            for (let i = keys.length - 1; i >= 0; i--) {
                const e_index = index || z;
                if (keys[i] === 'email' && !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(row[keys[i]])) {
                    errors = {
                        ...errors,
                        ...{
                            [keys[i]]: {
                                ...errors[keys[i]],
                                ...{ [e_index]: 'Ole hyvä ja tarkista sähköpostiosoite' }
                            }
                        }
                    };
                    valid = false;
                } else if (row[keys[i]] === '' && row[keys[i]] !== 0) {
                    errors = {
                        ...errors,
                        ...{
                            [keys[i]]: {
                                ...errors[keys[i]],
                                ...{ [e_index]: 'Ole hyvä ja tarkista ' + this.state.translations[keys[i]] }
                            }
                        }
                    };
                    valid = false;
                }
            }
        }

        if (!valid) {
            this.setState({ errors });
            this.props.error(Object.values(errors));
            return false;
        }

        for (let l = keys.length - 1; l >= 0; l--) {
            delete errors[keys[l]];
        }
        this.setState({ errors });
        this.props.error(Object.values(errors));

        return true;
    }

    async done() {
        const values = [];
        this.state.emails.map((e, i) => {
            if (this.refs['email-' + i].value !== '') {
                values.push({ email: this.refs['email-' + i].value, role_id: this.refs['role_id-' + i].value });
            }
            return '';
        });

        if (values.length > 0) {
            if (this.validate(values) && this.props.location.id !== undefined) {
                this.props.inviteUsers(this.props.location.id, values);
                this.props.done();
            }
        } else {
            this.props.done();
        }
    }

    addEmail() {
        if (this.state.emails.length <= 20) {
            this.setState({ emails: [...this.state.emails, { email: '', role_id: 0 }] });
        }
    }

    render() {
        return (
            <div className="">
                <h1>Käyttäjät</h1>
                <p>Yhteistyössä on voimaa, joten voit heti kutsua mukaan henkilöt, joiden kanssa haluat Kiinteistökartturia käyttää. Kutsuja voi toki lähettää myös myöhemmin.</p>

                <div>
                    {this.state.emails.map((user, index) => <div className="d-flex flex-column flex-md-row mt-2" key={'mail-' + index}>
                        <input className={'form-control rounded-0 border-primary mt-2 mt-md-0' + (this.state.errors.email !== undefined && this.state.errors.email[index] ? ' error' : '')} type="text" ref={'email-' + index} placeholder={this.state.translations.email}/>
                        <select ref={'role_id-' + index} className={'custom-select mt-2 mt-md-0 ml-md-2' + (this.state.errors.role_id !== undefined && this.state.errors.role_id[index] ? ' error' : '')}>
                            <option value="">{this.state.translations.role_id}</option>
                            {this.state.roles.map(role => <option key={'role-' + role.id} value={role.id}>{role.name}</option>)}
                        </select>
                    </div>)}

                    <span className="d-flex align-items-center justify-content-center text-primary mt-4">
                        <span onClick={() => this.addEmail()} className="bg-primary text-center mr-3 c-pointer" style={{ width: '2.5rem', height: '2.5rem' }}><h1 className="m-0 p-0 text-white">+</h1></span>
                        <span onClick={() => this.addEmail()} className="c-pointer">Lisää jäseniä</span>
                    </span>
                </div>

                <div className="d-flex mt-5">
                    <div className="col-6"></div>
                    <button className="col-6 btn btn-primary ml-2" id={"steps-" + window.location.pathname.split('/')[2] + "-users-next-button"} onClick={() => this.done()}>Seuraava</button>
                </div>
            </div>
        );
    }
}

Users.propTypes = {
    done: PropTypes.func.isRequired,
};

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => ({
    inviteUsers: (location_id, emails) => dispatch(inviteUsers(location_id, emails)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Users);
