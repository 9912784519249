import React, { Component } from "react";
import PropTypes from "prop-types";

class Circle extends Component {
    outline(size) {
        let width = '4.5rem';
        if (size === 'large') width = '8.125rem';
        if (size === 'small') width = '2.125rem';
        const height = width;

        const fontSize = '0.875rem';

        return {
            width, height, fontSize,
            color: '#002b17',
            fontWeight: 'bold',
            border: '1px solid #002b17',
        };
    }

    solid(size) {
        let width = '2.125rem';
        if (size === 'small') width = '0.875rem';
        const height = width;

        let fontSize = '0.875rem';
        if (size === 'large') {
            fontSize = '1.125rem';
        }

        return {
            width, height, fontSize,
            color: '#fff',
            backgroundColor: '#002b17',
        };
    }

    styles(size = null) {
        if (this.props.type === 'solid') {
            return this.solid(size);
        }

        return this.outline(size);
    }

    render() {
        return <span
            style={{ ...this.styles(this.props.size || null), ...this.props.style }}
            className={`mx-auto d-flex justify-content-center align-items-center ${this.props.className}`}
            onClick={this.props.onClick || null}
            id={this.props.id || null}
        >
            {this.props.children}
        </span>;
    }
}

Circle.propTypes = {
    size: PropTypes.string,
    type: PropTypes.string.isRequired,
};

export default Circle;
