import { searchZipcodes, createLocation } from "../actions/locations";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import { Modal, ModalBody } from "reactstrap";
import React, { Component } from "react";
import { catchEnter } from "../utils";
import { connect } from "react-redux";
import Circle from "./Circle";

export class NewLocationModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            errors: {},
            translations: {
                name: 'kohteen nimi',
                address: 'postiosoite',
                zipcode_id: 'postinumero',
                city: 'postitoimipaikka',
            },
            query_zip: '',
            zipcode_id: 0,
            zip_name_options: [],
            zip_options: [],
            selected_zip: [],

            city_options: {},
        };

        this.validate = this.validate.bind(this);
        this.onSearch = this.onSearch.bind(this);
        this.blurZip = this.blurZip.bind(this);
    }

    validate(values) {
        let valid = true;
        const errors = { ...this.state.errors };
        const keys = Object.keys(values);
        for (let i = keys.length - 1; i >= 0; i--) {
            if (values[keys[i]] === '') {
                errors[keys[i]] = 'Ole hyvä ja tarkista ' + this.state.translations[keys[i]];
                valid = false;
            } else {
                if (errors[keys[i]]) {
                    delete errors[keys[i]];
                }
            }
        }

        if (! valid) {
            this.setState({ errors });
            return false;
        }
        for (let l = keys.length - 1; l >= 0; l--) {
            delete errors[keys[l]];
        }
        this.setState({ errors });

        return true;
    }

    done() {
        const values = {
            name: this.refs.name.value,
            address: this.refs.address.value,
            zipcode_id: this.refs.zip.value > 0 ? this.refs.zip.value : '',
            city: this.refs.city.value,
        };
        if (this.validate(values)) {
            this.props.createLocation({ ...values, organization_id: this.props.organization.id }).then(r => {
                r().then(r => this.props.closeModal());
            });
        }
    }

    onSearch(query) {
        this.setState({ query_zip: query });
        return this.props.searchZipcodes({ name: '%', zip: this.state.query_zip || '%' })
            .then(response => {
                if(!this.state.query_zip){
                    return Promise.resolve(Object.values(response.data.results));
                }
                this.setState({
                    city_options: { ...response.data.results },
                    zip_name_options: [...new Set(Object.values(response.data.results).map(zip => zip.name))],
                    zip_options: Object.values(response.data.results).map(zip => zip.zip),
                    zipcode_id: Object.keys(response.data.results)[0],
                });
                return Promise.resolve(Object.values(response.data.results));
            });
    }
    blurZip(e) {
        if (!this.state.zip_options[0])
            return;
        this.setState({ selected_zip: [this.state.zip_options[0]], query_zip: this.state.zip_options[0] });
    }
    render() {
        return <Modal isOpen={true} toggle={() => this.props.closeModal()} style={{ width: '95%', maxWidth: '95%' }}>
            <ModalBody style={{ maxWidth: '1400px', left: 0, right: 0, top: 0, bottom: 0, margin: 'auto' }} >
                <div className="col-md-12" style={{ position: 'relative' }}>
                    <Circle type="outline" size="small" className="mt-2 c-pointer bg-white" id="current-task-close-button" onClick={() => this.props.closeModal()} style={{ position: 'absolute', zIndex: 2, right: '-1rem', top: '-2rem', width: '1.5rem', height: '1.5rem', fontSize: '1rem' }}>X</Circle>
                </div>
                <div className="d-flex flex-column start-steps" style={{ backgroundColor: 'white', padding: '2em', width: '100%' }}>
                    <h1>Lisää kohde</h1>
                    <div>
                        <div>
                            <h5>Kohteen nimi</h5>
                            <input className={'form-control rounded-0 border-primary mt-2 mt-md-0' + (this.state.errors.name !== undefined ? ' error' : '')} type="text" onBlur={(e) => this.validate({ name: e.target.value })} ref="name" placeholder={this.state.translations.name} />
                        </div>
                        <div>
                            <h5>Osoite</h5>
                            <input className={'form-control rounded-0 border-primary mt-2 mt-md-0' + (this.state.errors.address !== undefined ? ' error' : '')} type="text" onBlur={(e) => this.validate({ address: e.target.value })} ref="address" placeholder={this.state.translations.address} />
                        </div>
                        <div>
                            <h5>Postinumero</h5>
                            <div className="d-flex flex-md-row flex-column">
                                <input className={'form-control rounded-0 border-primary mt-2 mt-md-0'} defaultValue={this.state.zipcode_id} type="hidden" ref="zip"  />
                                <AsyncTypeahead
                                    id="zipcode-typehead"
                                    className={'zipSearch form-control rounded-0 border-primary mt-2 mt-md-0 rbt-unset-width p-0' + (this.state.errors.zipcode_id !== undefined ? ' error' : '')}
                                    isLoading={false}
                                    onChange={selected_zip => this.setState({ selected_zip, query_zip: selected_zip[0] })}
                                    selected={this.state.selected_zip || null}
                                    onSearch={(query) => this.onSearch(query)}
                                    options={this.state.zip_options}
                                    useCache={false}
                                    promptText="postinumero"
                                    placeholder="postinumero"
                                    onKeyDown={(e) => catchEnter(e)}
                                    onBlur={(e) => this.blurZip(e)}
                                />
                                <input className={'form-control rounded-0 border-primary ml-md-2 mt-2 mt-md-0' + (this.state.errors.city !== undefined ? ' error' : '')} onBlur={(e) => this.validate({ city: e.target.value })} type="text" ref="city" readOnly defaultValue={this.state.city_options[this.state.zipcode_id] !== undefined ? this.state.city_options[this.state.zipcode_id].name : ''} placeholder={this.state.translations.city} />
                            </div>
                        </div>
                    </div>
                    <div className="d-flex align-items-end mt-3">
                        <button className="btn btn-primary" style={{ width: '12em', height: '2.6em' }} onClick={() => this.done()}>Lisää</button>
                    </div>
                </div>
            </ModalBody>
        </Modal>;
    }
}

const mapStateToProps = ({ organization }) => ({ organization });

const mapDispatchToProps = (dispatch) => ({
    searchZipcodes: (query) => dispatch(searchZipcodes(query)),
    createLocation: (data) => dispatch(createLocation(data)),
});

export default connect(mapStateToProps, mapDispatchToProps) (NewLocationModal);
