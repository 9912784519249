import * as api from "../apis/users";

export const SET_INVITATION = 'SET_INVITATION';
export const REMOVE_INVITATION = 'REMOVE_INVITATION';

export const REQUEST_INVITATION = 'REQUEST_INVITATION';
export const REQUEST_INVITATION_SUCCESS = 'REQUEST_INVITATION_SUCCESS';
export const REQUEST_INVITATION_ERROR = 'REQUEST_INVITATION_ERROR';

export const REQUEST_VALIDATE_INVITATION = 'REQUEST_VALIDATE_INVITATION';
export const REQUEST_VALIDATE_INVITATION_SUCCESS = 'REQUEST_VALIDATE_INVITATION_SUCCESS';
export const REQUEST_VALIDATE_INVITATION_ERROR = 'REQUEST_VALIDATE_INVITATION_ERROR';

export const setInvitation = (invitation) => ({
    type: SET_INVITATION,
    invitation
});

export const removeInvitation = () => ({
    type: REMOVE_INVITATION
});

export const validateInvitation = (key) => {
    return {
        types: [REQUEST_VALIDATE_INVITATION, REQUEST_VALIDATE_INVITATION_SUCCESS, REQUEST_VALIDATE_INVITATION_ERROR],

        shouldCallAPI: (state) => {
            return true;
        },

        callAPI: () => api.validate(key),

        payload: {},
    };
};

export const getInvitation = (key) => {
    return {
        types: [REQUEST_INVITATION, REQUEST_INVITATION_SUCCESS, REQUEST_INVITATION_ERROR],

        shouldCallAPI: (state) => {
            return true;
        },

        callAPI: () => api.invitation(key),

        payload: {},
    };
};
