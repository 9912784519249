import { inviteUser, removeUser, changeRole } from "../actions/user";
import LocationUsersPage from "../components/LocationUsersPage";
import { getOrganizations } from "../actions/organizations";
import { getLocation } from "../actions/locations";
import React, { Component } from "react";
import { connect } from "react-redux";

export class LocationUsersContainer extends Component {

    constructor(props) {
        super(props);

        this.state = {
            random: Math.floor(Math.random() * 100)
        };
    }

    async componentDidMount() {
        await this.props.getOrganizations();
        this.props.getLocation(this.props.match.params.id);
    }

    static getDerivedStateFromProps(props, state) {
        return { random: Math.floor(Math.random() * 100) };
    }

    invite(email, role_id) {
        this.props.invite(this.props.currentLocation.id, email, role_id).then(() => {
            // Force remount by changing key
            this.setState({ random: Math.floor(Math.random() * 100) });
        });
    }

    render() {
        if (!this.props.currentLocation.id || !this.props.user.id) {
            return null;
        }
        return <LocationUsersPage key={this.state.random} changeRole={this.props.changeRole} invite={(email, role_id) => this.invite(email, role_id)} match={this.props.match} currentLocation={this.props.currentLocation} user={this.props.user} remove={this.props.remove}/>;
    }
}

const mapStateToProps = ({ currentLocation, loading, user }) => ({ currentLocation, loading, user });

const mapDispatchToProps = (dispatch) => ({
    getLocation: (id) => dispatch(getLocation(id)),
    invite: (location, email, role_id) => dispatch(inviteUser(location, email, role_id)),
    remove: (location, user) => dispatch(removeUser(location, user)),
    getOrganizations: () => dispatch(getOrganizations()),
    changeRole: (role, location, user) => dispatch(changeRole(role, location, user)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LocationUsersContainer);
