import Sign from "../svg/s_sign_Green_RGB.svg";
import React, { Component } from "react";
import UserAvatar from "./UserAvatar";
import PropTypes from "prop-types";
import Circle from "./Circle";
import { decode } from "he";
import moment from "moment";

const fieldTranslations = {
    name: 'otsikko',
    description: 'kuvaus',
    long_description: 'pitkä kuvaus',
    year: 'vuosi',
    price: 'hinta',
    status: 'tila',
    type: 'tyyppi',
    order_number: 'järjestysnumero'
};
const valueTranslations = {
    new: 'uusi',
    estimate: 'arvio',
    final: 'lopullinen',
    offered: 'tarjottu',
    null: 'tyhjä'
};

export class Comment extends Component {
    valueTranslations(value) {
        return valueTranslations[value] || value;
    }

    formatActionMessage(log) {
        if (log.type === 'created') {
            return `Uusi tehtävä luotu <br>Nimi: <b>${log.changed.name}</b><br>Vuosi: <span class="text-muted font-italic">${log.changed.year}</span><br>Hinta-arvio: <span class="text-muted font-italic">${log.changed.price !== undefined ? log.changed.price + '€' : ''}</span>`;
        }

        if (log.type === 'updated') {
            if (log.changed.year !== undefined) {
                return `Tehtävä siirretty <span class="text-muted font-italic">${log.changed.year.old} -> ${log.changed.year.new}</span>`;
            }

            if (log.changed.children_count !== undefined) {
                if (log.changed.children_count.new !== 0) {
                    return `Tehtävä jyvitetty <b>${log.changed.children_count.new + 1}</b> vuodelle`;
                }
                return `Tehtävä jyvitetty <b>${log.changed.children_count.new}</b> vuodelle`;
            }

            if (log.changed.order_number !== undefined) {
                return `Tehtävä järjestystä muutettu <span class="text-muted font-italic">${log.changed.order_number.old} -> ${log.changed.order_number.new}</span>`;
            }

            if (log.changed.completed !== undefined) {
                return log.changed.completed.new ? `Tehtävä valmis`
                    : `Tehtävä ei valmis`;
            }

            return Object.keys(log.changed)
                .filter(key => key !== 'updated_at')
                .map(key => `${fieldTranslations[key] || key}: <span class="text-muted font-italic">${this.valueTranslations(decode(String(log.changed[key].old)))} -> ${this.valueTranslations(decode(String(log.changed[key].new)))}</span>`)
                .join(', ');
        }

        if (log.type === 'comment') {
            return log.changed.text;
        }

        return '';
    }

    render() {
        return (
            <div className="media px-3 py-2 mb-1 rounded mt-3" style={{ backgroundColor: '#f7f7f7' }}>
                <Circle className="align-self-center" type="outline" size="small"
                    style={{ fontSize: '1.75rem', overflow: 'hidden' }}>
                    {this.props.comment.user.avatar !== null && this.props.comment.user.avatar !== undefined
                        ? <img src={this.props.comment.user.avatar.tiny}
                            alt={this.props.comment.user.firstname + ' ' + this.props.comment.user.lastname}/>
                        : this.props.comment.user.avatar === null
                            ? <UserAvatar user={this.props.comment.user}/>
                            : <img src={Sign} style={{ maxWidth: '75%' }} alt=""/>
                    }
                </Circle>
                <div className="media-body ml-3">
                    <h6 style={{
                        fontSize: '0.875rem',
                        lineHeight: '1.125rem',
                        color: '#002b17',
                        fontWeight: 'bold'
                    }}>{this.props.comment.user.firstname} {this.props.comment.user.lastname}</h6>

                    <p style={{ fontSize: '0.875rem', lineHeight: '1.125rem' }} className="p-0 m-0"
                        dangerouslySetInnerHTML={{ __html: this.formatActionMessage(this.props.comment) }}></p>
                    <small style={{ fontSize: '0.75rem' }}
                        className="text-muted">{moment(this.props.comment.created_at).format('D.M.YYYY')}</small>
                </div>
            </div>
        );
    }
}

Comment.propTypes = {
    comment: PropTypes.object.isRequired,
};

export default Comment;
