import { TOGGLE_NOTIFICATION_MODAL, REQUEST_NOTIFICATION_SEEN_SUCCESS } from "../actions/notifications";
import { REQUEST_USER_SUCCESS } from "../actions/user";

export default (state = { show: false }, action) => {
    switch (action.type) {
    case TOGGLE_NOTIFICATION_MODAL:
        return { ...state, show: !state.show };
    case REQUEST_NOTIFICATION_SEEN_SUCCESS:
        return { show: action.data.notifications.length > 0 ? true : false, notifications: action.data.notifications };
    case REQUEST_USER_SUCCESS:
        return { show: action.data.user.notifications.length > 0 ? true : false, notifications: action.data.user.notifications };
    default:
        return state;
    }
};
