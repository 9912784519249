import KiinteistokartturiLogoSmall from "../../svg/kiinteistokartturi-logo-small-2024.svg";
import React, { Component } from "react";
import "../../index.scss";
import RsOrIndividual from "./RsOrIndividual";

export class Start extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loaded: false,
        };
    }

    render() {
        return <div className="App" style={{ marginTop: '-4.5rem' }}>
            <nav className="navbar navbar-expand-lg navbar-light fixed-top d-flex justify-content-center" style={{ backgroundColor: '#f7f7f7', borderBottom: '1px solid #d8d8d8' }}>
                <img src={KiinteistokartturiLogoSmall} className="navbar-brand" style={{ maxHeight: '3rem' }} alt="" />
            </nav>
            <div className="mt-md-5 w-75 mx-auto">
                <h2>Valitse vielä onko Sustera tehnyt taloyhtiöön Kuntoarvion vai täytätkö tiedot itse</h2>
                <RsOrIndividual />
            </div>

        </div>;
    }
}


export default Start;
