import { authReducer as auth } from "redux-implicit-oauth2";
import { connectRouter } from "connected-react-router";
import currentLocation from "./currentLocation";
import notifications from "./notifications";
import contactModal from "./contactModal";
import organization from "./organization";
import { combineReducers } from "redux";
import currentTask from "./currentTask";
import maintenance from "./maintenance";
import invitation from "./invitation";
import locations from "./locations";
import comments from "./comments";
import services from "./services";
import loading from "./loading";
import filter from "./filter";
import errors from "./errors";
import tasks from "./tasks";
import years from "./years";
import users from "./users";
import user from "./user";
import faqs from "./faqs";

export default (history) => combineReducers({
    router: connectRouter(history),
    currentLocation,
    contactModal,
    notifications,
    organization,
    currentTask,
    maintenance,
    invitation,
    locations,
    comments,
    services,
    loading,
    filter,
    errors,
    tasks,
    years,
    users,
    auth,
    user,
    faqs,
});
