import React, { Component, createRef } from "react";
import { Edit } from "../svg/icons";
import PropTypes from "prop-types";
import Comment from "./Comment";
import Loading from "./Loading";

export class Comments extends Component {
    constructor(props) {
        super(props);

        this.state = { open: false };
        this.commentRef = createRef();
    }

    save(task_id) {
        this.props.createComment(this.props.task_id, this.commentRef.current.value);
        this.commentRef.current.value = '';
    }

    toggleOpen() {
        if (this.state.open) {
            this.setState({ open: false });
        } else {
            this.setState({ open: true });
            setTimeout(function () {
                this.commentRef.current.focus();
            }.bind(this), 100);
        }
    }

    render() {
        return (
            <div style={{ position: 'relative' }}>
                <Loading isLoading={this.props.isLoading}/>
                <div className="bg-white rounded d-flex flex-column"
                    style={{ marginTop: this.state.open ? 0 : '7rem' }}>
                    {this.state.open ?
                        <div className="d-flex flex-column">
                            <textarea rows="3" className="form-control border-0" style={{ resize: 'none' }}
                                ref={this.commentRef}></textarea>
                            {this.props.task_id !== undefined ? <button type="button" onClick={() => {
                                this.save();
                                this.setState({ open: false });
                            }} className="btn btn-outline-primary btn-sm ml-auto mx-3">Tallenna</button> : ''}
                        </div>
                        : ''}
                    <small className="ml-2 mt-2 px-2 pb-2 c-pointer" onClick={() => this.toggleOpen()}
                        style={{ color: '#002b17', fontSize: '0.875rem', verticalAlign: 'center' }}>
                        <Edit className="icon-f icon-cls nohover mr-2"/>Kirjoita muistiinpano</small>
                </div>
                <div style={{ maxHeight: '71vh', overflowY: 'auto' }} className="rounded mt-1">
                    {this.props.comments.map(comment => <Comment comment={comment}
                        key={'comment-' + (comment.type !== undefined ? 'log-' : '') + comment.id}/>)}
                </div>
            </div>
        );
    }
}

Comments.propTypes = {
    comments: PropTypes.array.isRequired,
    createComment: PropTypes.func.isRequired,
    task_id: PropTypes.number,
    isLoading: PropTypes.bool.isRequired,
};

export default Comments;
