import React, { Component } from "react";
import PropTypes from "prop-types";
import moment from "moment";

export class UserTerms extends Component {
    constructor(props) {
        super(props);

        this.state = { terms_accepted: false };
    }

    render() {
        return <div className="bg-light border-top px-5 py-3 col-md-6 mx-auto text-left mt-5">
            <h1>Vielä yksi juttu ja olet valmis</h1>
            <p>Kiinteistökartturia käytetään taloyhtiöiden ja kiinteistöyhtiöiden kannalta tärkeiden tietojen säilyttämiseen. Haluamme siksi varmistua, että kaikilla Kiinteistökartturia käyttävillä on tiedossaan palvelun käyttöä koskevat yhteiset säännöt.</p>
            <p>Tämän takia pyydämme ystävällisesti lukemaan ja hyväksymään palvelun <a href="https://raksv2.wp.meom.fi/yrityspolitiikka-ja-dokumentit/kayttoehdot-kiinteistokartturi/" id="terms-link" rel="noopener noreferrer" target="_blank">käyttöehdot</a>. Hyväksynnän jälkeen pääset heti käyttämään Kiinteistökartturia.</p>
            <p className="d-flex justify-content-between">
                <span className="d-flex align-items-center">
                    <input type="checkbox" ref="terms_accepted" value="1" className="mr-2" onClick={(e) => this.setState({ terms_accepted: e.target.checked })}/>
                    Hyväksyn palvelun <a className="ml-1" href="https://raksv2.wp.meom.fi/yrityspolitiikka-ja-dokumentit/kayttoehdot-kiinteistokartturi/" id="terms-link-2" rel="noopener noreferrer" target="_blank">käyttöehdot</a>
                </span>

                <button id="user-terms-continue" onClick={() => this.props.editUser({ ...this.props.user, ...{ terms_accepted: moment().format('YYYY-MM-DD HH:mm:ss') } })} disabled={!this.state.terms_accepted} className="btn btn-primary">Jatka</button>
            </p>
        </div>;
    }
}

Comment.propTypes = {
    user: PropTypes.object.isRequired,
    editUser: PropTypes.func.isRequired,
};

export default UserTerms;
