import axios from "axios";

const MaintenanceMiddleware = (_interval) => {
    let interval = _interval.instance;
    let intervals = _interval.loops;
    return store => next => action => {
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + store.getState().auth.token;
        axios.defaults.headers.common['Accept'] = 'application/json';
        if (store.getState().maintenance.mode || (action.type === 'MAINTENANCE_MODE' && action.data.mode)) {

            if (action.type === 'MAINTENANCE_MODE' && !store.getState().maintenance.mode) {
                next(action);
            }

            if (interval !== null) {
                return;
            }

            interval = setInterval(function () {
                // Clear interval after 60 intervals (10 minutes)
                if (intervals >= 60) {
                    clearInterval(interval);
                    return true;
                }

                intervals++;
                axios.get(process.env.REACT_APP_API_URL + '/api/user')
                    .then(response => {
                        window.location.reload();
                        clearInterval(interval);
                    }).catch(error => {
                    });
            }, 10000);

            return;
        }

        return next(action);
    };
};

export default MaintenanceMiddleware;
