import Draggable from "../containers/Sorting/Draggable";
import Droppable from "../containers/Sorting/Droppable";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Plus } from "../svg/icons";
import PropTypes from "prop-types";
import Circle from "./Circle";
import Task from "./Task";

class Year extends Component {
    render() {
        return <div className="Year d-flex flex-column h-100" style={{ minWidth: '22rem', marginLeft: '0.75rem', marginRight: '0.75rem' }}>
            <h3 className="m-0" style={{ fontSize: '22px', fontWeight: 500 }}>{this.props.year}</h3>
            <small className="align-self-center mt-1 mb-1 text-secondary" style={{ opacity: '0.75', fontWeight: 600, fontSize: '16px' }}>{this.props.tasks.length > 0 ? this.props.tasks.reduce((prev, next) => prev + Number(next.price_text.match(/\d{1,}/)), 0).toLocaleString('fi') : 0} &euro;</small>
            <div style={{ margin: 'auto', backgroundColor: '#e6e6e6', width: '8px', height: '8px', borderRadius: '50%' }}></div>
            <Droppable idString={"droppable-" + this.props.year} style={{ overflowY: 'auto', flex: 1, marginBottom: '1rem', marginTop: '1rem' }}>
                <div className="year-content">
                    {this.props.tasks.map((task, index) => <Draggable idString={task.id} index={index} key={'draggable-key-' + task.id} disabled={task.parent_id > 0 ? true : false}>
                        <Task key={'task-' + task.id} showImages={false} task={task} open={this.props.openTask}/>
                    </Draggable>)}
                </div>
            </Droppable>
            <div className="mx-2">
                <Link to={{ pathname: `${this.props.router.location.pathname}/tasks/new`, state: { modal: true, year: this.props.year } }} id={'year-' + this.props.year + '-new-task-link'}><Circle type="solid"><Plus style={{ width: '1.2rem' }} className="icon-f-inv"/></Circle></Link>
            </div>
        </div>;
    }
}

Year.propTypes = {
    year: PropTypes.string.isRequired,
    openTask: PropTypes.func.isRequired,
    tasks: PropTypes.array.isRequired,
    router: PropTypes.object.isRequired,
};

export default Year;
