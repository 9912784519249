import { REQUEST_ORGANIZATIONS_SUCCESS, REQUEST_CREATE_ORGANIZATIONS_SUCCESS, REQUEST_CREATE_ORGANIZATIONS_ERROR, REQUEST_ORGANIZATION_SUCCESS } from "../actions/organizations";

export default (state = {}, action) => {
    switch (action.type) {
    case REQUEST_ORGANIZATIONS_SUCCESS:
        return { ...action.data.organizations };
    case REQUEST_CREATE_ORGANIZATIONS_SUCCESS:
    case REQUEST_ORGANIZATION_SUCCESS:
        return { ...action.data.organization };
    case REQUEST_CREATE_ORGANIZATIONS_ERROR:
    case 'LOGOUT':
    case 'MAINTENANCE_MODE':
        if (action.type === 'MAINTENANCE_MODE' && !action.data.mode) {
            return { ...state };
        }
        return {};
    default:
        return { ...state };
    }
};
